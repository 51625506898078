import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

import QUERY from "../../query";
import { defaultColor } from "../../constants";
import { TypeIcon } from "../../images";

import "./companyNav.css";

const CutScroll = styled.div`
    overflow: hidden;
    width: 100%;
    @media (max-width: 760px) {
      position: fixed;
      overflow: hidden;
      z-index: 2;
      top: 48px;
      width: 100%;
      height: 60px;
      background-color: ${({ opacity }) =>
        opacity ? "rgba(0, 0, 0, 0)" : " #eee"};
      -webkit-overflow-scrolling: touch;
    }
  `,
  CompanyNavStyle = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 150px);
    @media (max-width: 760px) {
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      white-space: nowrap;
      height: 80px;
      width: 100%;
      -webkit-overflow-scrolling: touch;
      display: flex;
      align-items: center;
      &:active {
        cursor: grabbing;
      }
    }
    @media screen and (max-device-width: 760px) {
      padding-bottom: 17px;
    }
  `,
  CompanyNavLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media (max-width: 760px) {
      &:active {
        cursor: grabbing;
      }
    }
  `,
  CompanyNavBtn = styled.div`
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    background-color: #fff;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 120px;
    max-width: 150px;
    height: 54px;
    border: 1px solid #eee;
    cursor: pointer;
    border-right: none;
    transition: 0.2s ease all;
    &:hover {
      background-color: ${defaultColor};
    }
    &:hover ${CompanyNavLink} {
      color: #fff;
    }
    &:last-of-type {
      border-right: 1px solid #eee;
      border-radius: 0 5px 5px 0;
    }
    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    @media (max-width: 760px) {
      min-width: 75px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 10px;
      margin-right: 0px;
      padding-bottom: 2px;
      margin-left: 10px;
      &:active {
        cursor: grabbing;
      }
      &:last-of-type {
        border-right: none;
        border-radius: 10px;
      }
      &:first-of-type {
        border-radius: 10px;
      }
    }
  `,
  ImgStyle = styled.div`
    margin-right: 7px;
    margin-top: ${({ klub }) => (klub ? "3px" : "9px")};
    @media (max-width: 760px) {
      display: none;
    }
  `,
  AllText = styled.p`
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    @media (max-width: 760px) {
      text-transform: none;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 12px;
      letter-spacing: 0.05em;
      padding-top: 2px;
    }
  `,
  CompNavText = styled.p`
    margin-top: 3px;
    font-size: 13px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
    @media (max-width: 760px) {
      text-transform: none;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 12px;
      letter-spacing: 0.05em;
      &:active {
        cursor: grabbing;
      }
    }
  `;

const CompanyNav = ({
  style,
  clickedType,
  currentPage,
  toSlideFixedNav,
  opacity,
}) => {
  const [uniqueCompanyType, setUniqueCompanyType] = useState(
      sessionStorage.getItem("uniqueCompanyType")
        ? JSON.parse(sessionStorage.getItem("uniqueCompanyType"))
        : []
    ),
    [isDown, setIsDown] = useState(false),
    [startX, setStartX] = useState(),
    [scrollLeft, setScrollLeft] = useState(),
    [clickedTypeLocal, setClickedTypeLocal] = useState(),
    [hoveredBtn, setHoveredBtn] = useState();

  const slideBtnMenu = useRef(null);

  const supportsTouch = "ontouchstart" in document.documentElement;
  smoothscroll.polyfill();

  useEffect(() => {
    if (
      uniqueCompanyType &&
      uniqueCompanyType.length &&
      !sessionStorage.getItem("uniqueCompanyType")
    ) {
      sessionStorage.setItem(
        "uniqueCompanyType",
        JSON.stringify(uniqueCompanyType)
      );
    }
  }, [uniqueCompanyType]);

  const scrollBtnToCenter = (e) => {
      e && e.preventDefault();
      const btnPositionToCenter =
        slideBtnMenu.current.offsetWidth / 2 -
        (e.currentTarget.offsetLeft -
          slideBtnMenu.current.scrollLeft +
          e.currentTarget.offsetWidth / 2);

      slideBtnMenu.current.scrollTo({
        left: slideBtnMenu.current.scrollLeft - btnPositionToCenter,
        behavior: "smooth",
      });
    },
    scrollAllBtnToCenter = () => {
      slideBtnMenu.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    },
    firstScrollBtnToCenter = () => {
      if (sessionStorage.getItem("uniqueCompanyType")) {
        document.querySelectorAll(".companyNavBtn").forEach((el, i) => {
          if (isClickedTypeBtn(el.getAttribute("data-name"))) {
            const btnPositionToCenter =
              slideBtnMenu.current.offsetWidth / 2 -
              (el.offsetLeft -
                slideBtnMenu.current.scrollLeft +
                el.offsetWidth / 2);

            slideBtnMenu.current.scrollTo({
              left: slideBtnMenu.current.scrollLeft - btnPositionToCenter,
            });
          }
        });
      }
    },
    isClickedAllBtn = () =>
      !clickedTypeLocal && !sessionStorage.getItem("filter_type"),
    isClickedTypeBtn = (name) => {
      if (sessionStorage.getItem("filter_type") === name) return true;
      return false;
    };

  useEffect(() => {
    QUERY({
      query: `query {categories {id name slug}}`,
    })
      .then((res) => res.json())
      .then((data) => {
        setUniqueCompanyType(
          sessionStorage.getItem("uniqueCompanyType")
            ? JSON.parse(sessionStorage.getItem("uniqueCompanyType"))
            : data.data.categories
        );
      })
      .catch((err) => console.log(err, "  ERR"));

    firstScrollBtnToCenter();
  }, []);

  const SwipeFixedElSpring = useSpring({
    left: toSlideFixedNav ? -200 : 0,
    config: {
      duration: 200,
    },
  });

  const mouseDownEvent = (e) => {
      setIsDown(true);
      setStartX(e.pageX - slideBtnMenu.current.offsetLeft);
      setScrollLeft(slideBtnMenu.current.scrollLeft);
    },
    mouseMoveEvent = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slideBtnMenu.current.offsetLeft;
      const walk = (x - startX) * 2;
      slideBtnMenu.current.scrollLeft = scrollLeft - walk;
    },
    btnClick = (e, el) => {
      if (e && el) {
        if (clickedTypeLocal !== el.name) {
          sessionStorage.setItem("filter_type", el.name);
          sessionStorage.setItem("filter_id", el.id);
          clickedType(el.id);
          setClickedTypeLocal(el.name);
          scrollBtnToCenter(e);
        }
      } else {
        if (clickedTypeLocal || sessionStorage.getItem("filter_type")) {
          sessionStorage.setItem("filter_type", "");
          sessionStorage.setItem("filter_id", "");
          clickedType();
          setClickedTypeLocal();
          scrollAllBtnToCenter();
        }
      }
    };

  return (
    <CutScroll
      opacity={opacity}
      as={animated.div}
      style={{
        ...style,
        ...SwipeFixedElSpring,
      }}
    >
      <CompanyNavStyle
        ref={slideBtnMenu}
        onMouseDown={(e) => !supportsTouch && mouseDownEvent(e)}
        onMouseLeave={() => !supportsTouch && setIsDown(false)}
        onMouseUp={() => !supportsTouch && setIsDown(false)}
        onMouseMove={(e) => !supportsTouch && mouseMoveEvent(e)}
      >
        <CompanyNavBtn
          className="companyNavBtn"
          style={isClickedAllBtn() ? { backgroundColor: defaultColor } : {}}
          onClick={() => btnClick()}
        >
          <CompanyNavLink
            style={isClickedAllBtn() ? { color: "#fff" } : {}}
            to={currentPage}
          >
            <AllText>Все</AllText>
          </CompanyNavLink>
        </CompanyNavBtn>
        {uniqueCompanyType.map((el, i) => {
          const slideBtn = React.createRef();
          return (
            <CompanyNavBtn
              className="companyNavBtn"
              data-name={el.name}
              key={i}
              ref={slideBtn}
              style={
                isClickedTypeBtn(el.name) ? { background: defaultColor } : {}
              }
              onClick={(e) => btnClick(e, el)}
              onMouseOver={() => setHoveredBtn(el.name)}
              onMouseOut={() => setHoveredBtn("")}
            >
              <CompanyNavLink
                style={
                  isClickedTypeBtn(el.name)
                    ? {
                        color: "#fff",
                        borderRadius: "10px",
                      }
                    : {}
                }
                to={currentPage}
              >
                {isClickedTypeBtn(el.name) ? (
                  <ImgStyle klub={el.slug === "klub"}>
                    {TypeIcon(el.slug, "#fff")}
                  </ImgStyle>
                ) : hoveredBtn === el.name ? (
                  <ImgStyle klub={el.slug === "klub"}>
                    {TypeIcon(el.slug, "#fff")}
                  </ImgStyle>
                ) : (
                  <ImgStyle klub={el.slug === "klub"}>
                    {TypeIcon(el.slug)}
                  </ImgStyle>
                )}
                <CompNavText>{el.name}</CompNavText>
              </CompanyNavLink>
            </CompanyNavBtn>
          );
        })}
      </CompanyNavStyle>
    </CutScroll>
  );
};

export default CompanyNav;
