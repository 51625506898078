import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

import Header from "../../components/Header";
import SlideSideMenu from "../../components/SlideSideMenu";
import QUERY from "../../query";
import { GoBackBtn, GoBackBtnArrow, AuthBlock, AuthBlockWrap } from "./Login";

const HeadText = styled.p`
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    letter-spacing: 0.05em;
    margin-bottom: 5px;
    text-align: center;
    width: 189px;
    @media (max-width: 760px) {
      margin-bottom: 10px;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
    }
  `,
  BottomText = styled(HeadText)`
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    @media (max-width: 760px) {
      font-size: 16px;
      line-height: 19px;
    }
  `;

const Registration = () => {
  const [showSlideSideMenu, setShowSlideSideMenu] = useState(false),
    [isShowMenu, setIsShowMenu] = useState(false);

  const hideSideMenu = () => {
      setShowSlideSideMenu(false);
      document.body.style.overflow = "visible";
      setIsShowMenu(false);
    },
    showSideMenu = () => {
      setShowSlideSideMenu(true);
      document.body.style.overflow = "hidden";
      setIsShowMenu(true);
    };

  useEffect(() => {
    if (sessionStorage.getItem("uniqueCompanyType"))
      sessionStorage.setItem("uniqueCompanyType", "");

    sessionStorage.setItem("prevZoom", "");
    sessionStorage.setItem("prevCenter", "");
  }, []);

  window.onresize = function (e) {
    hideSideMenu();
  };

  const animateProps = useSpring({
    right: isShowMenu ? 200 : 0,
    config: { duration: 200 },
  });

  const hide = (e) => {
    if (e.target.className !== "SlideSideMenu" && showSlideSideMenu)
      hideSideMenu();
  };

  if (!!localStorage.getItem("userId")) {
    return <Redirect to="/" />;
  } else {
    return (
      <div>
        <Header
          arrow
          isShowMenu={isShowMenu}
          logo
          burger
          showSlideSideMenu={showSlideSideMenu}
          showSideMenu={showSideMenu}
        />
        <AuthBlockWrap
          as={animated.div}
          onClick={(e) => hide(e)}
          style={animateProps}
        >
          <GoBackBtn to="/">
            <GoBackBtnArrow>&#8592;</GoBackBtnArrow>
            На главную
          </GoBackBtn>
          <AuthBlock>
            <BottomText>
              Для добавления заведение в каталог PartyLive звоните по номеру
              +375291111331
            </BottomText>
          </AuthBlock>
          <SlideSideMenu isShowMenu={isShowMenu} />
        </AuthBlockWrap>
      </div>
    );
  }
};

export default Registration;
