import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import ym, { YMInitializer } from "react-yandex-metrika";

import Map from "../../components/Map/Map";
import Popup from "../../components/Popup";
import SlideSideMenu from "../../components/SlideSideMenu";
import Header from "../../components/Header";
import Loader from "../../components/loader/Loader";
import VideoPlayer from "../../components/VideoPlayer";
import QUERY from "../../query";
import { defaultColor, PLACE_EXT_DATA_QUERY_FOR_ALIAS } from "../../constants";
import { TypeIcon, Location, Clock } from "../../images";
import {
  EN_SHORT_DAY_OF_WEEK,
  EN_SHORT_TO_RU_SHORT,
  SHORT_DAY_OF_WEEK_EN,
  EN_SHORT_TO_NUMBER,
} from "../../constants";
import { queryPath } from "../../settings";
import "./company.css";

const GoBackBtnD = styled.div`
    cursor: pointer;
    display: block;
    height: 100px;
    width: 150px;
    line-height: 100px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      color: ${defaultColor};
    }
    @media (max-width: 760px) {
      display: none;
    }
  `,
  CompanyWrap = styled.div`
    @media (max-width: 760px) {
      min-height: 100vh;
    }
  `,
  GoBackBtnArrowD = styled.span`
    font-size: 18px;
    padding-right: 5px;
  `,
  CompanyD = styled.div`
    width: 1000px;
    margin: 0 auto;
    @media (max-width: 760px) {
      display: none;
    }
  `,
  FlexD = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  `,
  ShadowBlockD = styled.div`
    display: flex;
    flex: 1;
    box-shadow: none;
  `,
  VideoBlockD = styled.div`
    -webkit-box-flex: 5;
    -ms-flex: 5;
    flex: 1;
    padding-bottom: 20px;
    padding-right: 20px;
  `,
  YesVideoD = styled.div`
    height: 300px;
    margin-bottom: 5px;
    background-color: #000;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  `,
  NoVideoD = styled.div`
    position: relative;
    border-radius: 10px;
    display: flex;
    background: #000;
    height: 300px;
    margin-bottom: 10px;
    background-image: url(${({ bg }) => bg});
    background-size: cover;
    background-position: center;
    overflow: hidden;
  `,
  VideoDescrD = styled.p`
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #4f4f4f;
  `,
  VideoDescrNameD = styled.span`
    font-weight: 700;
  `,
  DescD = styled.span`
    flex: 1;
    padding: 0 5px 0 20px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  TopDescriptionD = styled.div`
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
  `,
  DescNameD = styled.h3`
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    margin-left: -1px;
  `,
  RowWithImageD = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #4f4f4f;
  `,
  RowWithImageTextD = styled.p`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  `,
  RowWithImageLeftTextD = styled.span`
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 2;
    font-size: 14px;
    color: #000;
    font-weight: bold;
  `,
  RowWithImageRightTextD = styled.span`
    margin-left: 10px;
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #878787;
  `,
  SmallMapLinkD = styled(Link)`
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  `,
  CompanyM = styled.div`
    display: none;
    @media (max-width: 760px) {
      display: block;
      padding: 0;
      position: relative;
    }
  `,
  FlexM = styled.div`
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 10px;
    padding-top: 55px;
  `,
  ShadowBlockM = styled.div`
    flex-direction: column;
    background: #ffffff;
    border-radius: 5px;
    padding-bottom: 30px;
  `,
  VideoBlockM = styled.div`
    padding-right: 0px;
    padding-bottom: 0;
    border-radius: 10px;
  `,
  YesVideoM = styled.div`
    margin-bottom: 5px;
    background-color: #000;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.3s ease all;
    position: relative;
    @media (max-width: 460px) {
    }
  `,
  NoVideoM = styled.div`
    position: relative;
    height: 350px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    background: #000;
    margin-bottom: 10px;
    transition: 0.3s ease all;
    background-image: url(${({ bg }) => bg});
    background-size: cover;
    background-position: center;
    overflow: hidden;
    @media (max-width: 460px) {
      height: 200px;
    }
  `,
  DescM = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  `,
  NameRowM = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  WithIconBlockM = styled.div`
    display: flex;
    flex-direction: row;
  `,
  DescIconsColumnM = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  `,
  DescNoIconsColumnM = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  NameM = styled.span`
    font-weight: 700;
    font-size: 22px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #000000;
  `,
  CompanyTypeM = styled.div`
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 8px 0 3px 0;
    display: flex;
    align-items: center;
  `,
  OpenedToM = styled.p`
    display: block;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 25px;
  `,
  CircleM = styled.span`
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 7px;
    margin-top: -8px;
    background-color: ${({ isWork }) => (isWork ? "#04B000" : "#6D6D6D")};
    margin-right: 6px;
  `,
  SmallMapWrapM = styled.div`
    position: relative;
    height: 270px;
    display: block;
    opacity: 1;
    background-color: #fff;
    @media (max-width: 760px) {
      height: 190px;
    }
  `,
  SmallMapM = styled.div`
    display: block;
    border: none;
    margin-top: 0px;
    overflow: hidden;
    height: 255px;
    border-radius: 5px;
    @media (max-width: 760px) {
      height: 175px;
    }
  `,
  SmallMapLocationM = styled.p`
    display: flex;
    color: #000000;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 8px;
    height: 30px;
  `,
  SmallMapTransparentBg = styled.div`
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: calc(100% + 10px);
    background: transparent;
    cursor: pointer;
  `,
  IconWrap = styled.div`
    margin-right: 16px;
    margin-top: -2px;
  `,
  TopOnlineText = styled.p`
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    padding: 2px 10px;
    height: 25px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.2);
  `,
  Filter = styled.div`
    position: absolute;
    right: 0;
    padding: 0 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    top: 0px;
    right: 0px;
  `;

const Company = (props) => {
  const [showPopup, setShowPopap] = useState(false),
    [DATA, setDATA] = useState(null),
    [showSlideSideMenu, setShowSlideSideMenu] = useState(false),
    [isShowMenu, setIsShowMenu] = useState(false),
    [isLoading, setIsLoading] = useState(true),
    [mouseMapCoordinates, setMouseMapCoordinates] = useState({}),
    [isDisabled, setIsDisabled] = useState(false);

  const getPlace = () => {
    QUERY({
      query: `query {
          placeExtByAlias (alias: "${props.match.params.id}") {
            ${PLACE_EXT_DATA_QUERY_FOR_ALIAS}
          }
        }`,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setDATA(data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "  ONE PLACE");
      });
  };

  useEffect(() => {
    if (sessionStorage.getItem("uniqueCompanyType"))
      sessionStorage.setItem("uniqueCompanyType", "");

    getPlace();

    setInterval(() => getPlace(), 5 * 60 * 1000);
    ym(209469445, "reachGoal", window.location.href);
  }, []);

  useEffect(() => {
    window.onresize = function (e) {
      hideSideMenu();
    };
  });

  const togglePopup = () => setShowPopap(!showPopup),
    hideSideMenu = () => {
      setShowSlideSideMenu(false);
      document.body.style.overflow = "visible";
      setIsShowMenu(false);
    },
    showSideMenu = () => {
      setShowSlideSideMenu(true);
      document.body.style.overflow = "hidden";
      setIsShowMenu(true);
    },
    mouseDownMapHandler = (e) => {
      setMouseMapCoordinates({
        clientX: e.clientX,
        clientY: e.clientY,
      });
    },
    mouseUpMapHandler = (e) => {
      if (
        +mouseMapCoordinates.clientX === +e.clientX &&
        +mouseMapCoordinates.clientY === +e.clientY
      )
        togglePopup();
    },
    hide = (e) => {
      if (e.target.className !== "SlideSideMenu" && showSlideSideMenu)
        hideSideMenu();
    },
    desctopWorkTime = () => {
      if (DATA.placeExtByAlias.is_work) {
        const { start_time, end_time } =
          DATA.placeExtByAlias.currentScheduleInterval;
        if (start_time.split(" ")[1] === end_time.split(" ")[1]) {
          return "Круглосуточно";
        } else {
          return (
            start_time.split(" ")[1].slice(0, 5) +
            " - " +
            end_time.split(" ")[1].slice(0, 5)
          );
        }
      } else {
        return "Выходной";
      }
    },
    mobileWorkTime = () => {
      const { start_time, end_time } =
        DATA.placeExtByAlias.currentScheduleInterval;

      if (start_time.split(" ")[1] === end_time.split(" ")[1]) {
        return "Круглосут.";
      } else {
        return "Открыто: до " + end_time.split(" ")[1].slice(0, 5);
      }
    },
    getStreamTime = () => {
      const numberDayNow =
          new Date().getDay() === 0 ? 6 : new Date().getDay() - 1,
        currentDay = EN_SHORT_DAY_OF_WEEK[numberDayNow].day,
        currentTimeMs =
          new Date().getHours() * 3600 + new Date().getMinutes() * 60;
      let currentDayNum = EN_SHORT_TO_NUMBER[currentDay];

      const sortFunc = (a, b) =>
        SHORT_DAY_OF_WEEK_EN.indexOf(a.day) -
        SHORT_DAY_OF_WEEK_EN.indexOf(b.day);

      if (DATA.placeExtByAlias.streams[0].schedules.length) {
        const SCH = DATA.placeExtByAlias.streams[0].schedules;
        let string = "";

        SCH.sort(sortFunc);
        let today = SCH.find((el) => el.day === currentDay);

        if (
          today &&
          currentTimeMs <
            today.start_time.split(":")[0] * 3600 +
              +today.start_time.split(":")[1] * 60
        ) {
          string = "Сегодня " + today.start_time;
        } else {
          currentDayNum++;
          for (let i = 0; i < 7; i++) {
            const day = SCH.find(
              (el) => el.day === SHORT_DAY_OF_WEEK_EN[currentDayNum]
            );
            if (currentDayNum < 6) {
              if (day) {
                string = EN_SHORT_TO_RU_SHORT[day.day] + " " + day.start_time;
                break;
              } else {
                currentDayNum++;
              }
            } else {
              if (day) {
                string = EN_SHORT_TO_RU_SHORT[day.day] + " " + day.start_time;
                break;
              } else {
                currentDayNum = 0;
              }
            }
          }
        }

        return string;
      }
    },
    whenStartStream = () => {
      if (
        DATA.placeExtByAlias.streams &&
        DATA.placeExtByAlias.streams.length &&
        !DATA.placeExtByAlias.is_online &&
        DATA.placeExtByAlias.streams[0].schedules &&
        DATA.placeExtByAlias.streams[0].schedules.length &&
        !isDisabled &&
        DATA.placeExtByAlias.streams[0].online_on
      ) {
        const online_on_ms = new Date(
            DATA.placeExtByAlias.streams[0].online_on.replaceAll("-", "/")
          ).getTime(),
          cur_time_ms = new Date().getTime();

        if (cur_time_ms - online_on_ms < 90000)
          return <TopOnlineText>{getStreamTime()}</TopOnlineText>;
      }
    },
    noTranslation = () => {
      if (
        !DATA.placeExtByAlias.streams ||
        (DATA.placeExtByAlias.streams && !DATA.placeExtByAlias.streams[0]) ||
        (DATA.placeExtByAlias.streams &&
          DATA.placeExtByAlias.streams[0] &&
          !DATA.placeExtByAlias.streams[0].schedules.length) ||
        isDisabled
      ) {
        return <TopOnlineText> Нет трансляции</TopOnlineText>;
      }
      if (
        DATA.placeExtByAlias.streams &&
        DATA.placeExtByAlias.streams[0] &&
        DATA.placeExtByAlias.streams[0].online_on
      ) {
        const curTime = new Date().getTime(),
          online_on_time = new Date(
            DATA.placeExtByAlias.streams[0].online_on
          ).getTime();

        if (curTime - online_on_time > 90000)
          return <TopOnlineText> Нет трансляции</TopOnlineText>;
      }
    },
    setPrivateFilter = () => {
      if (DATA.placeExtByAlias.streams[0].default_sound_volume)
        return (
          <Filter>
            Private filter:{" "}
            {DATA.placeExtByAlias.streams[0].default_sound_volume}%
          </Filter>
        );
    };

  useEffect(() => {
    if (
      DATA &&
      DATA.placeExtByAlias.streams &&
      DATA.placeExtByAlias.streams[0] &&
      DATA.placeExtByAlias.streams[0].see_you_tomorrow
    ) {
      const year = new Date().getFullYear(),
        month =
          new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1,
        dayNow =
          new Date().getDate() < 10
            ? "0" + new Date().getDate()
            : new Date().getDate(),
        dayNext =
          new Date(Date.now() + 8.64e7).getDate() < 10
            ? "0" + new Date(Date.now() + 8.64e7).getDate()
            : new Date(Date.now() + 8.64e7).getDate();

      const today = `${year}-${month}-${dayNow}`,
        tomorrow = `${year}-${month}-${dayNext}`;

      const currentTime =
          new Date().getHours() * 3600 + new Date().getMinutes() * 60,
        seeYouTime = DATA.placeExtByAlias.streams[0].see_you_tomorrow
          .split(" ")[1]
          .slice(0, 5),
        disabseeYouTimeMs =
          seeYouTime.split(":")[0] * 3600 + seeYouTime.split(":")[1] * 60;

      if (
        tomorrow ===
        DATA.placeExtByAlias.streams[0].see_you_tomorrow.split(" ")[0]
      )
        setIsDisabled(true);
      if (
        today === DATA.placeExtByAlias.streams[0].see_you_tomorrow.split(" ")[0]
      ) {
        if (currentTime < disabseeYouTimeMs) setIsDisabled(true);
      }
    }
  }, [DATA]);

  const history = useHistory();

  const SwipePageSpring = useSpring({
    right: isShowMenu ? 200 : 0,
    config: { duration: 200 },
  });

  return (
    <CompanyWrap onClick={(e) => hide(e)}>
      <YMInitializer accounts={[83984023]} />
      <Header
        logo
        burger
        arrow
        isShowMenu={isShowMenu}
        showSlideSideMenu={showSlideSideMenu}
        showSideMenu={showSideMenu}
      />

      {/* _______________________________________ DESCTOP */}
      <CompanyD
        as={animated.div}
        style={SwipePageSpring}
        onClick={(e) => hide(e)}
      >
        <GoBackBtnD onClick={() => history.goBack()}>
          <GoBackBtnArrowD>&#8592;</GoBackBtnArrowD>
          Назад
        </GoBackBtnD>

        {DATA && (
          <FlexD>
            <ShadowBlockD>
              <VideoBlockD>
                {DATA.placeExtByAlias.is_online && (
                  <YesVideoD>
                    <VideoPlayer
                      preview={DATA.placeExtByAlias.streams[0].preview}
                      src={DATA.placeExtByAlias.streams[0].url}
                      volume={
                        DATA.placeExtByAlias.streams[0].default_sound_volume /
                        100
                      }
                    />
                    {/* {setPrivateFilter()} */}
                  </YesVideoD>
                )}
                {!DATA.placeExtByAlias.is_online && (
                  <NoVideoD
                    bg={
                      DATA.placeExtByAlias.profile_image
                        ? `${queryPath}/imgcache/477/300/storage/` +
                          DATA.placeExtByAlias.profile_image
                        : ""
                    }
                  >
                    {whenStartStream()}
                    {noTranslation()}
                  </NoVideoD>
                )}
                <VideoDescrD>
                  <VideoDescrNameD>
                    {DATA.placeExtByAlias.name}{" "}
                  </VideoDescrNameD>
                  {DATA.placeExtByAlias.description}
                </VideoDescrD>
              </VideoBlockD>

              <DescD>
                <TopDescriptionD>
                  <DescNameD>{DATA.placeExtByAlias.name}</DescNameD>
                </TopDescriptionD>
                <div>
                  {DATA.placeExtByAlias.categories[0] && (
                    <RowWithImageD>
                      <IconWrap>
                        {TypeIcon(
                          DATA.placeExtByAlias.categories[0].slug,
                          false,
                          20,
                          20
                        )}
                      </IconWrap>
                      <RowWithImageLeftTextD>
                        Тип заведения:
                      </RowWithImageLeftTextD>
                      <RowWithImageRightTextD>
                        {DATA.placeExtByAlias.categories[0].name}
                      </RowWithImageRightTextD>
                    </RowWithImageD>
                  )}

                  <RowWithImageD>
                    <IconWrap>{Clock(false, 20, 20)}</IconWrap>

                    <RowWithImageTextD>
                      <RowWithImageLeftTextD>
                        Время работы:
                      </RowWithImageLeftTextD>
                      <RowWithImageRightTextD>
                        {desctopWorkTime()}
                      </RowWithImageRightTextD>
                    </RowWithImageTextD>
                  </RowWithImageD>
                  <RowWithImageD>
                    <IconWrap>{Location(false, 20, 20)}</IconWrap>
                    <RowWithImageTextD>
                      <RowWithImageLeftTextD>Адрес: </RowWithImageLeftTextD>
                      <RowWithImageRightTextD>
                        {DATA.placeExtByAlias.address &&
                          DATA.placeExtByAlias.address
                            .split(",")[0]
                            .replace("улица", "ул.")
                            .replace("проспект", "пр-т.")}
                      </RowWithImageRightTextD>
                    </RowWithImageTextD>
                  </RowWithImageD>
                </div>
                <SmallMapLinkD
                  onMouseDown={mouseDownMapHandler}
                  onMouseUp={mouseUpMapHandler}
                >
                  <Map
                    togglePopupGoogleMap={togglePopup}
                    zoom={10}
                    DATA={DATA}
                    initialCenterMap={
                      DATA.placeExtByAlias.lat
                        ? {
                            lat: Number(DATA.placeExtByAlias.lat),
                            lng: Number(DATA.placeExtByAlias.lon),
                          }
                        : null
                    }
                    height={"100px"}
                  />
                </SmallMapLinkD>
              </DescD>
            </ShadowBlockD>
          </FlexD>
        )}
      </CompanyD>

      {/* ________________________________ MOBILE */}
      <CompanyM
        as={animated.div}
        style={SwipePageSpring}
        onClick={(e) => hide(e)}
      >
        {DATA && (
          <FlexM>
            <ShadowBlockM>
              <VideoBlockM>
                {DATA.placeExtByAlias.is_online && (
                  <YesVideoM>
                    <VideoPlayer
                      preview={DATA.placeExtByAlias.streams[0].preview}
                      src={DATA.placeExtByAlias.streams[0].url}
                      volume={
                        DATA.placeExtByAlias.streams[0].default_sound_volume /
                        100
                      }
                    />
                    {/* {setPrivateFilter()} */}
                  </YesVideoM>
                )}
                {!DATA.placeExtByAlias.is_online && (
                  <NoVideoM
                    bg={
                      DATA.placeExtByAlias.profile_image
                        ? `${queryPath}/imgcache/705/350/storage/` +
                          DATA.placeExtByAlias.profile_image
                        : ""
                    }
                  >
                    {whenStartStream()}
                    {noTranslation()}
                  </NoVideoM>
                )}
              </VideoBlockM>
              <DescM>
                <NameRowM>
                  <NameM>{DATA.placeExtByAlias.name}</NameM>
                </NameRowM>
                <WithIconBlockM>
                  <DescIconsColumnM>
                    <div style={{ marginRight: "5px", marginTop: "5px" }}>
                      {TypeIcon(
                        DATA.placeExtByAlias.categories[0].slug,
                        false,
                        20,
                        20
                      )}
                    </div>
                    <CircleM isWork={DATA.placeExtByAlias.is_work} />
                  </DescIconsColumnM>
                  <DescNoIconsColumnM>
                    <CompanyTypeM>
                      {DATA.placeExtByAlias.categories[0] &&
                        DATA.placeExtByAlias.categories[0].name}
                    </CompanyTypeM>
                    <OpenedToM>
                      {DATA.placeExtByAlias.is_work && (
                        <span style={{ fontSize: "16px" }}>
                          {mobileWorkTime()}
                        </span>
                      )}
                      {!DATA.placeExtByAlias.is_work && "закрыто"}
                    </OpenedToM>
                  </DescNoIconsColumnM>
                </WithIconBlockM>

                <SmallMapWrapM>
                  <SmallMapLocationM>
                    <div style={{ marginRight: "5px" }}>
                      {Location(false, 20, 20)}
                    </div>
                    {DATA
                      ? DATA.placeExtByAlias.address
                          .split(",")[0]
                          .replace("улица", "ул.")
                          .replace("проспект", "пр-т.")
                      : ""}
                  </SmallMapLocationM>
                  <SmallMapM
                    onMouseDown={mouseDownMapHandler}
                    onMouseUp={mouseUpMapHandler}
                  >
                    <Map
                      zoom={10}
                      togglePopupGoogleMap={togglePopup}
                      styleContainerMap={{ height: "180px" }}
                      DATA={DATA}
                      initialCenterMap={
                        DATA.placeExtByAlias.lat
                          ? {
                              lat: Number(DATA.placeExtByAlias.lat),
                              lng: Number(DATA.placeExtByAlias.lon),
                            }
                          : null
                      }
                      height={"100%"}
                    />
                  </SmallMapM>

                  <SmallMapTransparentBg
                    onClick={() => togglePopup()}
                  ></SmallMapTransparentBg>
                </SmallMapWrapM>
              </DescM>
            </ShadowBlockM>
          </FlexM>
        )}
      </CompanyM>

      {isLoading && <Loader />}
      {showPopup && (
        <Popup togglePopup={togglePopup} style={{ padding: 0 }}>
          <Map
            togglePopupGoogleMap={togglePopup}
            styleContainerMap={{ width: "100vw" }}
            closeBtn
            DATA={DATA}
            initialCenterMap={
              DATA.placeExtByAlias.lat
                ? {
                    lat: Number(DATA.placeExtByAlias.lat),
                    lng: Number(DATA.placeExtByAlias.lon),
                  }
                : null
            }
          />
        </Popup>
      )}
      <SlideSideMenu isShowMenu={isShowMenu} />
    </CompanyWrap>
  );
};

export default Company;
