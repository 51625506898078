import React from "react";
import { defaultColor } from "../../constants";
import styled from "styled-components";

import { AdminIcons } from "../../images";

const LeftAdminBtnD = styled.li`
    font-size: 13px;
    font-weight: 800;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${({ clicked }) => (clicked ? defaultColor : "#000")};
    letter-spacing: 1px;
    &:hover {
      color: ${defaultColor};
    }
  `,
  AdminImgWrapD = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 16px;
  `;

const LeftMenu = ({ el, setLeftMenuSettings, id }) => {
  const click = () => {
      setLeftMenuSettings((prev) => {
        let newArr = [...prev];
        newArr.forEach((el) => (el.clicked = false));
        newArr[id] = {
          ...newArr[id],
          clicked: true,
        };
        return newArr;
      });
    },
    enter = () => {
      setLeftMenuSettings((prev) => {
        let newArr = [...prev];
        newArr[id] = {
          ...newArr[id],
          enter: true,
        };
        return newArr;
      });
    },
    leave = () => {
      setLeftMenuSettings((prev) => {
        let newArr = [...prev];
        newArr[id] = {
          ...newArr[id],
          enter: false,
        };
        return newArr;
      });
    };

  return (
    <LeftAdminBtnD
      clicked={el.clicked}
      key={id}
      onClick={() => click()}
      onMouseEnter={() => enter()}
      onMouseLeave={() => leave()}
    >
      {el.img && (
        <AdminImgWrapD>
          {AdminIcons(
            el.img,
            el.clicked || el.enter ? defaultColor : "#000",
            27,
            27
          )}{" "}
        </AdminImgWrapD>
      )}
      {el.name}
    </LeftAdminBtnD>
  );
};

export default LeftMenu;
