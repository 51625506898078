import React, {useState, useEffect} from "react";
import styled from "styled-components";
import VideoPlayer from "../../components/VideoPlayer";
import Loader from "../../components/loader/Loader";
import {Range} from "react-range";

import {defaultColor, PLACE_QUERY, RTSP_CONNECTION} from "../../constants";
import QUERY from "../../query";

const StreamType = styled.span`
    text-transform: uppercase;
    color: ${defaultColor};
    font-weight: 600;
    font-size: 14px;
  `,
    StreamBlock = styled.div`
    font-size: 18px;
  `,
    H3 = styled.h3`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 28px;
  `,
    VideoWrap = styled.div`
    margin-top: 20px;
    width: 80%;
    height: 286px;
    border-radius: 10px;
    overflow: hidden;
  `,
    ChooseStreamAddress = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  `,
    ChooseStreamAddressSaveBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #eee;
    height: 35px;
    width: 120px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #f8104d;
    color: #fff;
    transition: 0.3s ease opacity;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      opacity: 0.8;
    }
  `,
    SaveRtspChanges = styled.div`
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d3d3d3;
    width: 120px;
    height: 37px;
    cursor: pointer;
    margin: 38px 20px 15px 0;
    &:hover {
      opacity: 0.7;
      transition: 0.2s ease all;
    }
  `,
    CancelBtnProfile = styled.div`
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 35px;
    cursor: pointer;
    margin: 38px 0 15px 0;
    background-color: #ff0960;
    &:hover {
      opacity: 0.8;
      transition: 0.2s ease all;
    }
  `,
    StreamTypeWrap = styled.div`
    display: flex;
  `,
    UserSelectDiv = styled.div`
    -o-user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
    user-select: text;
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 0 0;
  `,
    LoaderWrap = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: -200px;
  `,
    Label = styled.p`
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
  `,
    Inputs = styled.input`
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
    -webkit-appearance: none;
    transition: 0.3s ease all;
    width: 100%;
    font-size: 14px;
    height: 45px;
    outline: none;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-color: ${({err}) => (err ? "red" : "#e5e5e5")};
    box-sizing: border-box;
    border-radius: 7px;
    margin: 0px 0px;
    padding: 0 10px;
    color: #737373;
    font-weight: 500;
  `,
    CheckboxWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
    CheckboxText = styled.div`
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => (props.clicked ? ` ${defaultColor}` : `#000`)};
  `,
    Checkbox = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: ${(props) =>
        props.clicked ? `2px solid ${defaultColor}` : `2px solid #D3D3D3`};
    margin-right: 10px;
    &:after {
      border-radius: 50%;
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: ${(props) => (props.clicked ? defaultColor : "#fff")};
      position: relative;
      top: 3px;
      left: 3px;
    }
  `,
    CopyText = styled.div`
    cursor: pointer;
    font-size: 14px;
    transition: 0.2s ease opacity;
    color: ${defaultColor};
    &:hover {
      opacity: 0.7;
    }
  `,
    DisabledStreamText = styled.p`
    color: ${defaultColor};
    font-size: 11px;
    line-height: 11px;
    text-align: right;
    font-weight: bold;
    float: right;
    margin: 10px 0;
  `;

const Stream = ({index, DATA, props, refreshData, setDATA}) => {
    const [streamAddressData, setStreamAddressData] = useState(""),
        [streamPortData, setStreamPortData] = useState(""),
        [streamHostData, setStreamHostData] = useState(""),
        [streamPasswordData, setStreamPasswordData] = useState(""),
        [streamLoginData, setStreamLoginData] = useState(""),
        [streamType, setStreamType] = useState("rtmp"),
        [isStream, setIsStream] = useState(""),
        [rtmpUrl, setRtmpUrl] = useState(""),
        [isLoading, setIsLoading] = useState(false),
        [vedeoLoading, setVedeoLoading] = useState(false),
        [inputErrors, setInputErrors] = useState({
            login: false,
            password: false,
            host: false,
            port: false,
            address: false,
            cameraError: false,
        }),
        [volume, setVolume] = useState([1]);

    const clearData = () => {
            setStreamAddressData("");
            setStreamPortData("");
            setStreamHostData("");
            setStreamPasswordData("");
            setStreamLoginData("");
            setRtmpUrl("");
        },
        deleteErrors = () => {
            setInputErrors({
                login: false,
                password: false,
                host: false,
                port: false,
                address: false,
            });
        },
        createAllErrors = () => {
            setInputErrors({
                login: true,
                password: true,
                host: true,
                port: true,
                address: true,
            });
        },
        checkInpudData = () => {
            if (
                !streamAddressData ||
                !streamPortData ||
                !streamHostData ||
                !streamPasswordData ||
                !streamLoginData
            ) {
                !streamAddressData &&
                setInputErrors((prevState) => {
                    return {...prevState, address: true};
                });
                !streamPortData &&
                setInputErrors((prevState) => {
                    return {...prevState, port: true};
                });
                !streamHostData &&
                setInputErrors((prevState) => {
                    return {...prevState, host: true};
                });
                !streamPasswordData &&
                setInputErrors((prevState) => {
                    return {...prevState, password: true};
                });
                !streamLoginData &&
                setInputErrors((prevState) => {
                    return {...prevState, login: true};
                });
                return true;
            }
            return false;
        };

    useEffect(() => {
        if (DATA.streams && !DATA.streams[0]) {
            setIsStream("");
            clearData();
        }
        if (DATA.streams && DATA.streams[0]) {
            setIsStream(DATA.streams[0]);

            if (DATA.streams[0].rtsp_connection) {
                const RTSP = DATA.streams[0].rtsp_connection;
                setStreamPortData(RTSP.port);
                setStreamHostData(RTSP.host);
                setStreamPasswordData(RTSP.password);
                setStreamLoginData(RTSP.login);
                setStreamAddressData(RTSP.address);
                setStreamType("rtsp");
            }

            setVolume([DATA.streams[0].default_sound_volume]);

            if (DATA.streams[0].rtmp_url) {
                setStreamType("rtmp");
                setRtmpUrl(
                    DATA.streams[0].url
                        .replace("https://", "rtmp://")
                        .replace("/camera.m3u8", "")
                );
            }
        }
    }, [DATA]);

    const rtsp_connection_string = `rtsp_connection: {
      create: {
        login : "${streamLoginData}"
        password : "${streamPasswordData}"
        host : "${streamHostData}"
        port : ${streamPortData}
        address : "${streamAddressData}"
      }
    }`,
        query_string_create =
            streamType === "rtsp"
                ? ` 
          name: "${DATA.name}"
          place:{ connect: "${DATA.id}" }
          type: ${"RTSP"} 
          default_sound_volume: ${100}
          ${rtsp_connection_string}
        `
                : `
        name: "${DATA.name}"
        place:{connect:"${DATA.id}"}
        type: ${"RTMP"} 
        default_sound_volume: ${100}
      `;

    const createStream = () => {
            if (localStorage.getItem("token")) {
                setIsLoading(true);
                QUERY(
                    {
                        query: `mutation {
              createStream( input:{ ${query_string_create} }) {
                  id name url preview see_you_tomorrow ${PLACE_QUERY} 
                  ${RTSP_CONNECTION}
                  rtmp_url
              }
            }`,
                    },
                    localStorage.getItem("token")
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setIsLoading(false);

                        data.data.createStream &&
                        data.data.createStream.rtmp_url &&
                        setRtmpUrl(
                            data.data.createStream.rtmp_url.replace("https://", "rtmp://")
                        );
                        if (!data.errors) {
                            refreshData();
                            setDATA(data.data.createStream.place);
                        } else {
                            createAllErrors(true);
                            setInputErrors((prevState) => {
                                return {...prevState, cameraError: true};
                            });
                            console.log(data.errors, "CREATE STREAM ERRORS 1");
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);

                        createAllErrors(true);
                        setInputErrors((prevState) => {
                            return {...prevState, cameraError: true};
                        });

                        console.log(err, "CREATE STREAM ERR 2");
                    });
            }
        },
        saveChanges = () => {
            if (localStorage.getItem("token")) {
                setIsLoading(true);

                console.log(DATA, "---***");

                const query_string_create = ` 
              id:"${DATA.streams[0].rtsp_connection.id}"
              login : "${streamLoginData}"
              password : "${streamPasswordData}"
              host : "${streamHostData}"
              port : ${streamPortData}
              address : "${streamAddressData}"
            `;

                QUERY(
                    {
                        query: `mutation {
              updateRstpConnection( input:{ ${query_string_create} }) {
                id  login password host port address
              }
            }`,
                    },
                    localStorage.getItem("token")
                )
                    .then((res) => res.json())
                    .then((data) => {
                        if (!data.errors) {
                            refreshData();
                            setDATA(data.data.createStream.place);
                            console.log(data, "__DATA");
                        } else {
                            createAllErrors(true);
                            setInputErrors((prevState) => {
                                return {...prevState, cameraError: true};
                            });
                            console.log(data.errors, "CREATE STREAM ERRORS 3");
                        }
                    })
                    .catch((err) => {
                        // setIsLoading(false);

                        // createAllErrors(true);
                        // setInputErrors((prevState) => {
                        //   return { ...prevState, cameraError: true };
                        // });

                        console.log(err, "CREATE STREAM ERR 4");
                    });
            }
        },
        deleteStream = () => {
            if (localStorage.getItem("token") && isStream) {
                setIsLoading(true);
                QUERY(
                    {
                        query: `mutation { deleteStream(id:"${+isStream.id}") {
                  id name url see_you_tomorrow ${PLACE_QUERY} 
                  ${RTSP_CONNECTION}
                  rtmp_url
            }}`,
                    },
                    localStorage.getItem("token")
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setIsLoading(false);
                        if (!data.errors) {
                            refreshData();
                            setDATA(data.data.deleteStream.place);
                        } else {
                            console.log(data.errors, "DELETE STREAM ERRORS");
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        console.log(err, "CREATE STREAM ERR 5");
                    });
            }
        },
        save = () => {
            if (streamType === "rtsp") {
                !isStream && !checkInpudData() && createStream();
            } else {
                !isStream && createStream(true);
            }
        };

    let wasInterval = false;

    useEffect(() => {
        if (isStream && isStream.url && !wasInterval) {
            let count = 0;
            const urlTimer = setInterval(() => {
                count++;
                fetch(isStream.url)
                    .then((res) => {
                        if (res.ok) {
                            clearInterval(urlTimer);
                            count = 0;
                            wasInterval = true;
                            setVedeoLoading(false);
                        } else {
                            setVedeoLoading(true);
                        }
                    })
                    .catch((err) => setVedeoLoading(true));

                if (count > 120) {
                    clearInterval(urlTimer);
                    createAllErrors(true);
                    setInputErrors((prevState) => {
                        return {...prevState, cameraError: true};
                    });
                }
            }, 2000);
        }
    }, [isStream]);

    const setVolumeOnServer = (data) => {
        setIsLoading(true);
        QUERY(
            {
                query: `mutation {
          updateStream (
            input:{
              id:"${+isStream.id}"
              default_sound_volume:${+data}
            }
          ) {
            default_sound_volume
          }
        }`,
            },
            localStorage.getItem("token")
        )
            .then((res) => res.json())
            .then((data) => {
                refreshData();
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err, "setVolume ERR");
                setIsLoading(false);
            });
    };

    return (
        <StreamBlock key={index}>
            <H3>Камера 1</H3>
            {isStream && <StreamType>{streamType}</StreamType>}
            {!isStream && (
                <StreamTypeWrap>
                    <CheckboxWrap
                        style={{marginRight: "60px"}}
                        onClick={() => {
                            deleteErrors();
                            setStreamType("rtmp");
                        }}
                    >
                        <Checkbox clicked={streamType === "rtmp"}/>
                        <CheckboxText>RTMP</CheckboxText>
                    </CheckboxWrap>
                    <CheckboxWrap
                        onClick={() => {
                            deleteErrors();
                            setStreamType("rtsp");
                        }}
                    >
                        <Checkbox clicked={streamType === "rtsp"}/>
                        <CheckboxText>RTSP</CheckboxText>
                    </CheckboxWrap>
                </StreamTypeWrap>
            )}

            {isStream && (
                <div style={{position: "relative"}}>
                    {!vedeoLoading && (
                        <VideoWrap>
                            <VideoPlayer
                                preview={isStream.preview}
                                src={isStream.url}
                                volume={volume / 100}
                                admin={true}
                            />
                        </VideoWrap>
                    )}

                    {vedeoLoading && (
                        <div
                            style={{
                                color: inputErrors["cameraError"] ? "red" : "#000",
                            }}
                        >
                            {inputErrors["cameraError"] ? (
                                "Неверные данные"
                            ) : (
                                <VideoWrap
                                    style={{
                                        border: "1px solid #E4E4E4",
                                        borderRadius: "5px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        height: "286px",
                                        fontWeight: "600",
                                        fontSize: "18px",
                                        color: "#c4c4c4",
                                    }}
                                >
                                    Ожидание потока...
                                </VideoWrap>
                            )}
                        </div>
                    )}
                </div>
            )}

            <ChooseStreamAddress>
                {streamType === "rtsp" && (
                    <>
                        <p style={{fontWeight: "400", fontSize: "14px"}}>
                            Заполните все данные настроек Вашего роутера
                        </p>
                        <Label>Логин:</Label>
                        <Inputs
                            // disabled={isStream}
                            err={inputErrors["login"]}
                            placeholder={"Логин"}
                            value={
                                streamLoginData ||
                                (isStream &&
                                    isStream.rtsp_connection &&
                                    isStream.rtsp_connection.login)
                            }
                            onInput={(e) => {
                                deleteErrors();
                                setStreamLoginData(e.target.value);
                            }}
                        />
                        <Label>Пароль:</Label>
                        <Inputs
                            // disabled={isStream}
                            err={inputErrors["password"]}
                            placeholder={"Пароль"}
                            value={
                                streamPasswordData ||
                                (isStream &&
                                    isStream.rtsp_connection &&
                                    isStream.rtsp_connection.password)
                            }
                            onInput={(e) => {
                                deleteErrors();
                                setStreamPasswordData(e.target.value);
                            }}
                        />
                        <Label>Хост:</Label>
                        <Inputs
                            // disabled={isStream}
                            err={inputErrors["host"]}
                            placeholder={"Хост"}
                            value={
                                streamHostData ||
                                (isStream &&
                                    isStream.rtsp_connection &&
                                    isStream.rtsp_connection.host)
                            }
                            onInput={(e) => {
                                deleteErrors();
                                setStreamHostData(e.target.value);
                            }}
                        />
                        <Label>Порт:</Label>
                        <Inputs
                            // disabled={isStream}
                            err={inputErrors["port"]}
                            placeholder={"Порт"}
                            value={
                                streamPortData ||
                                (isStream &&
                                    isStream.rtsp_connection &&
                                    isStream.rtsp_connection.port)
                            }
                            onInput={(e) => {
                                deleteErrors();
                                setStreamPortData(e.target.value);
                            }}
                        />
                        <Label>Адрес:</Label>
                        <Inputs
                            // disabled={isStream}
                            err={inputErrors["address"]}
                            placeholder={"Адрес"}
                            value={
                                streamAddressData ||
                                (isStream &&
                                    isStream.rtsp_connection &&
                                    isStream.rtsp_connection.address)
                            }
                            onInput={(e) => {
                                deleteErrors();
                                setStreamAddressData(e.target.value);
                            }}
                        />
                    </>
                )}

                {streamType === "rtmp" && (
                    <div>
                        {rtmpUrl ? (
                            <div>
                                <p style={{fontWeight: 400, fontSize: "14px"}}>
                                    Скопируйте адрес видеосервера в соответствующее поле в панели
                                    настроек Вашей ip-камеры
                                </p>

                                <UserSelectDiv>{rtmpUrl}</UserSelectDiv>
                            </div>
                        ) : (
                            <p style={{fontSize: "14px", fontWeight: "400"}}>
                                Нажмите "Создать" для генерации ссылки видео-потока
                            </p>
                        )}
                    </div>
                )}

                {streamType === "rtmp" && rtmpUrl && (
                    <CopyText onClick={() => navigator.clipboard.writeText(rtmpUrl)}>
                        Скопировать текст
                    </CopyText>
                )}

                {isStream && (
                    <div style={{marginTop: "20px"}}>
                        <p
                            style={{
                                marginBottom: "20px",
                                fontSize: "16px",
                                fontWeight: 500,
                            }}
                        >
                            Настройка приватности:
                        </p>
                        <Range
                            step={5}
                            min={0}
                            max={100}
                            values={volume}
                            onChange={(values) => setVolume(values)}
                            onFinalChange={(values) => setVolumeOnServer(values[0])}
                            renderTrack={({props, children}) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "6px",

                                        backgroundColor: "#ccc",
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({props}) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        borderRadius: "4px",
                                        height: "30px",
                                        width: "30px",
                                        backgroundColor: defaultColor,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                  <span
                      style={{color: "#fff", fontSize: 12, fontWeight: "500"}}
                  >
                    {volume}
                  </span>
                                </div>
                            )}
                        />
                    </div>
                )}

                <div style={{display: "flex"}}>
                    {!isStream ? (
                        <ChooseStreamAddressSaveBtn
                            style={{marginRight: "19px"}}
                            onClick={() => save()}
                        >
                            Создать
                        </ChooseStreamAddressSaveBtn>
                    ) : (
                        <div style={{display: "flex"}}>
                            {streamType === "rtsp" && (
                                <SaveRtspChanges onClick={() => saveChanges()}>
                                    Сохранить
                                </SaveRtspChanges>
                            )}

                            <CancelBtnProfile onClick={() => deleteStream()}>
                                Удалить камеру
                            </CancelBtnProfile>
                        </div>
                    )}
                </div>
            </ChooseStreamAddress>

            {isLoading && (
                <LoaderWrap>
                    <Loader/>
                </LoaderWrap>
            )}
        </StreamBlock>
    );
};

export default Stream;
