import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BottomArrow, TopArrow } from "../../images";

import { defaultColor } from "../../constants";

const TPName = styled.p`
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => (props.clicked ? "#000" : "#D3D3D3")};
    @media (max-width: 760px) {
      margin: 20px 0;
    }
  `,
  StartEnd = styled.p`
    display: none;
    @media (max-width: 760px) {
      display: block;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #000;
    }
  `,
  TP = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    @media (max-width: 760px) {
      padding: 0;
    }
  `,
  TPHoursMinutesWrap = styled.div`
    font-size: 20px;
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 120px;
    align-items: center;
    justify-content: center;
  `,
  TPHoursMinutes = styled.span`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding: 10px 0;
    justify-content: center;
    color: ${(props) => (props.clicked ? "#000" : "#D3D3D3")};
  `,
  Cursor = styled.span`
    cursor: pointer;
  `,
  TwoDots = styled.span`
    display: flex;
    width: 10px;
    font-weight: 700;
    align-self: center;
    justify-content: center;
    color: ${(props) => (props.clicked ? "#000" : "#D3D3D3")};
  `;

const TimePicker = ({
  timePickerName,
  setTime,
  realTimeInPicker,
  isClick1,
  start,
}) => {
  const [hours, setHours] = useState(realTimeInPicker.split(":")[0]),
    [minutes, setMinutes] = useState(realTimeInPicker.split(":")[1]),
    [b1, setB1] = useState(false),
    [b2, setB2] = useState(false),
    [b3, setB3] = useState(false),
    [b4, setB4] = useState(false);

  const clickHandlerTop = (e, max) => {
      e.currentTarget.nextSibling.innerText =
        +e.currentTarget.nextSibling.innerText + 1;
      if (+e.currentTarget.nextSibling.innerText === max) {
        e.currentTarget.nextSibling.innerText = 0;
      }
      if (+e.currentTarget.nextSibling.innerText < 10) {
        e.currentTarget.nextSibling.innerText =
          "0" + e.currentTarget.nextSibling.innerText;
      }
      if (max === 24) {
        setHours(e.currentTarget.nextSibling.innerText);
      } else {
        setMinutes(e.currentTarget.nextSibling.innerText);
      }
    },
    clickHandlerBottom = (e, max) => {
      if (+e.currentTarget.previousSibling.innerText === 0) {
        e.currentTarget.previousSibling.innerText = max;
      }
      e.currentTarget.previousSibling.innerText =
        +e.currentTarget.previousSibling.innerText - 1;

      if (e.currentTarget.previousSibling.innerText < 10) {
        e.currentTarget.previousSibling.innerText =
          "0" + e.currentTarget.previousSibling.innerText;
      }
      if (max === 24) {
        setHours(e.currentTarget.previousSibling.innerText);
      } else {
        setMinutes(e.currentTarget.previousSibling.innerText);
      }
    };

  useEffect(() => {
    setTime(hours, minutes);
  }, [hours, minutes]);

  return (
    <div>
      <TPName clicked={isClick1}>{timePickerName}</TPName>
      <StartEnd>{start ? "Начало" : "Окончание"}</StartEnd>
      <TP>
        <TPHoursMinutesWrap>
          <Cursor
            onClick={(e) => isClick1 && clickHandlerTop(e, 24)}
            onMouseEnter={() => isClick1 && setB1(true)}
            onMouseLeave={() => isClick1 && setB1(false)}
          >
            {TopArrow(
              b1 ? defaultColor : "#fff",
              b1 ? defaultColor : "#d3d3d3"
            )}
          </Cursor>
          <TPHoursMinutes clicked={isClick1}>{hours}</TPHoursMinutes>
          <Cursor
            onClick={(e) => isClick1 && clickHandlerBottom(e, 24)}
            onMouseEnter={() => isClick1 && setB2(true)}
            onMouseLeave={() => isClick1 && setB2(false)}
          >
            {BottomArrow(
              b2 ? defaultColor : "#fff",
              b2 ? defaultColor : "#d3d3d3"
            )}
          </Cursor>
        </TPHoursMinutesWrap>

        <TwoDots clicked={isClick1}>:</TwoDots>

        <TPHoursMinutesWrap>
          <Cursor
            onClick={(e) => isClick1 && clickHandlerTop(e, 60)}
            onMouseEnter={() => isClick1 && setB3(true)}
            onMouseLeave={() => isClick1 && setB3(false)}
          >
            {TopArrow(
              b3 ? defaultColor : "#fff",
              b3 ? defaultColor : "#d3d3d3"
            )}
          </Cursor>
          <TPHoursMinutes clicked={isClick1}>{minutes}</TPHoursMinutes>
          <Cursor
            onClick={(e) => isClick1 && clickHandlerBottom(e, 60)}
            onMouseEnter={() => isClick1 && setB4(true)}
            onMouseLeave={() => isClick1 && setB4(false)}
          >
            {BottomArrow(
              b4 ? defaultColor : "#fff",
              b4 ? defaultColor : "#d3d3d3"
            )}
          </Cursor>
        </TPHoursMinutesWrap>
      </TP>
    </div>
  );
};

export default TimePicker;
