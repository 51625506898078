import React, { useRef } from "react";
import CropperMobile from "./CropperMobile";
import Dropzone from "react-dropzone";

import Popup from "../../components/Popup";
import AdminHeader from "../../components/AdminHeader";

const UploadFilePopup = ({
  togglePopupUploadFile,
  setImgSrc,
  imgSrc,
  uploadImageTranscode,
  acceptedFileTypes,
  imageMaxSize,
  handleOnDrop,
}) => {
  const editorRef = useRef(null);

  const onCrop = () => {
      if (editorRef.current) {
        const canvas = editorRef.current.getImage().toDataURL();
        fetch(canvas)
          .then((res) => res.blob())
          .then((blob) => uploadImageTranscode(blob));
      }
    },
    cancel = () => {
      setImgSrc(null);
      togglePopupUploadFile();
    },
    save = () => {
      togglePopupUploadFile();
      onCrop();
    };

  return (
    <Popup
      togglePopup={togglePopupUploadFile}
      style={{
        width: "100%",
        height: "100%",
        padding: 0,
      }}
    >
      <AdminHeader close={cancel} save={save} />
      <CropperMobile imgSrc={imgSrc} editorRef={editorRef} />
      <Dropzone
        multiple={false}
        accept={acceptedFileTypes}
        maxSize={imageMaxSize}
        onDrop={(acceptedFiles, rejectedFiles) =>
          handleOnDrop(acceptedFiles, rejectedFiles)
        }
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <section style={{ display: "flex" }}>
              <div className="changePhotoBlock" {...getRootProps()}>
                <input
                  className="changePhotoInput previewRef"
                  {...getInputProps()}
                />
              </div>
            </section>
          );
        }}
      </Dropzone>
    </Popup>
  );
};

export default UploadFilePopup;
