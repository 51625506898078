import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {
    EN_SHORT_DAY_OF_WEEK,
    EN_SHORT_TO_RU_SHORT,
    SHORT_DAY_OF_WEEK_EN,
    EN_SHORT_TO_NUMBER,
} from "../../constants";
import {queryPath} from "../../settings";
import styled, {keyframes} from "styled-components";
import {defaultColor} from "../../constants";
import {Location, TypeIcon, Kamera} from "../../images";

const SmallCompBlock = styled(Link)`
    width: 240px;
    height: 238px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    transition: 0.3s ease opacity;
    border: 1px solid #eee;
    margin: 7px;
    margin-left: 0;
    &:hover {
      opacity: 0.9;
    }
    &:nth-child(2n + 2) {
      margin-left: 6px;
      margin-right: 12px;
    }

    &:nth-child(4n + 4) {
      margin: 6px;
      margin-right: 0;
    }

    @media (max-width: 760px) {
      height: 235px;
      margin: 5px;
      margin-left: 5px;
      margin-right: 5px;
      width: calc(33% - 10px);
      border-radius: 5px;
      &:nth-child(2n + 2) {
        margin-left: 5px;
        margin-right: 5px;
      }

      &:nth-child(4n + 4) {
        margin: 5px;
        margin-right: 5px;
      }
    }
    @media (max-width: 650px) {
      width: calc(50% - 10px);
    }
    @media (max-width: 500px) {
      height: 165px;
    }
    @media (max-width: 375px) {
    }
  `,
    Desctop = styled.div`
    display: block;
    @media (max-width: 760px) {
      display: none;
    }
  `,
    PreviewBlockD = styled.div`
    border-radius: 10px;
    overflow: hidden;
    height: 150px;
    background: #000;
    background-size: cover;
    background-position: center;
  `,
    NoTranslationD = styled.div`
    border-radius: 10px;
    overflow: hidden;
    height: 150px;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: #eee;
    background: #000;
    background-image: url(${({bg}) => bg});
    background-size: cover;
    background-position: center;
  `,
    DescriptionD = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 10px;
  `,
    TopDescriptionBlockD = styled.div`
    width: 100%;
    display: flex;
    position: relative;
  `,
    IconToCenter = styled.div`
    width: 20px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    margin-top: -1px;
  `,
    IconLocToCenter = styled(IconToCenter)`
    margin-top: 2px;
  `,
    CompanyNameD = styled.p`
    color: #000;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
    MiddleDescriptionBlockD = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
  `,
    WorkTimeWrapD = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  `,
    CircleD = styled.div`
    width: 7px;
    height: 7px;
    background: ${({isWork}) => (isWork ? "#04b000" : " #C4C4C4")};
    border-radius: 50%;
    margin-top: 3px;
  `,
    IsOpenedD = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: #9d9d9d;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @media (max-width: 460px) {
      font-size: 13px;
    }
  `,
    LocationWrapD = styled.div`
    display: flex;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #000;
    padding-top: 7px;

    @media (max-width: 460px) {
      font-size: 13px;
    }
  `,
    LocationStyleD = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: #9d9d9d;
  `,
    Mobile = styled.div`
    display: none;
    @media (max-width: 760px) {
      display: block;
    }
  `,
    PreviewBlockM = styled.div`
    border-radius: 5px;
    overflow: hidden;
    height: 175px;
    background: #000;
    background-size: cover;
    background-position: center;
    @media (max-width: 500px) {
      height: 110px;
    }
  `,
    NoTranslationM = styled.div`
    border-radius: 5px;
    overflow: hidden;
    height: 175px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #eee;
    background: #000;
    background-image: url(${({bg}) => bg});
    background-size: cover;
    background-position: center;
    @media (max-width: 500px) {
      height: 110px;
    }
  `,
    DescriptionM = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 5px;
  `,
    TopDescriptionBlockM = styled.div`
    width: 100%;
    display: flex;
  `,
    CompanyNameM = styled.p`
    color: #363636;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
    DistanceM = styled.p`
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: ${defaultColor};
    line-height: 23px;
    white-space: nowrap;
  `,
    BottomDescriptionBlockM = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -1px;
  `,
    WorkTimeDistanceWrapM = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
  `,
    CircleRowM = styled.div`
    display: flex;
    align-items: center;
  `,
    IsOpenedM = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: #9d9d9d;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
    blik = keyframes`
  0% {
   opacity:1;
  }
  50% {
    opacity:0;
  }
  100% {
   opacity:1;
  }
`,
    FilmCircleWrap = styled.span`
    display: flex;
    align-items: "center";
  `,
    CircleTranslation = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #ff0000;
    border-radius: 50%;
    margin-top: 9px;
    animation: ${blik} 1.5s ease infinite;
  `,
    IsStreamNoAnim = styled.div`
    line-height: 12px;
    color: #fff;
    font-size: 12px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.4) inset;
    height: 15px;
    margin-top: 7px;
    padding: 1px 5px 2px 5px;
    border-radius: 3px;
    font-weight: 600;
    @media (max-width: 760px) {
      font-size: 12px;
      margin-top: 7px;
    }
  `,
    KameraWrap = styled.div`
    margin: 4px 7px 0 7px;
  `;

const SmallCompanyBlock = ({item}) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const locationAddress =
            item.address &&
            item.address
                .split(",")[0]
                .replace("улица", "ул.")
                .replace("проспект", "пр-т."),
        noTranslationBg = item.profile_image
            ? `${queryPath}/imgcache/240/150/storage/` + item.profile_image
            : "",
        openedTo =
            item.currentScheduleInterval &&
            item.currentScheduleInterval.end_time
                .split(" ")[1]
                .split(":")
                .slice(0, 2)
                .join(":"),
        openedFrom =
            item.currentScheduleInterval &&
            item.currentScheduleInterval.start_time
                .split(" ")[1]
                .split(":")
                .slice(0, 2)
                .join(":");

    const getStreamTime = () => {
            const numberDayNow =
                    new Date().getDay() === 0 ? 6 : new Date().getDay() - 1,
                currentDay = EN_SHORT_DAY_OF_WEEK[numberDayNow].day,
                currentTimeMs =
                    new Date().getHours() * 3600 + new Date().getMinutes() * 60;
            let currentDayNum = EN_SHORT_TO_NUMBER[currentDay];

            const sortFunc = (a, b) =>
                SHORT_DAY_OF_WEEK_EN.indexOf(a.day) -
                SHORT_DAY_OF_WEEK_EN.indexOf(b.day);

            if (item.streams[0].schedules.length) {
                const SCH = item.streams[0].schedules;
                let string = "";

                SCH.sort(sortFunc);
                let today = SCH.find((el) => el.day === currentDay);

                if (
                    today &&
                    currentTimeMs <
                    today.start_time.split(":")[0] * 3600 +
                    +today.start_time.split(":")[1] * 60
                ) {
                    string = "Сегодня " + today.start_time;
                } else {
                    currentDayNum++;
                    for (let i = 0; i < 7; i++) {
                        const day = SCH.find((el) => {
                            if (el.day === SHORT_DAY_OF_WEEK_EN[currentDayNum]) return el;
                        });

                        if (currentDayNum < 6) {
                            if (day) {
                                string = EN_SHORT_TO_RU_SHORT[day.day] + " " + day.start_time;
                                break;
                            } else {
                                currentDayNum++;
                            }
                        } else {
                            if (day) {
                                string = EN_SHORT_TO_RU_SHORT[day.day] + " " + day.start_time;
                                break;
                            } else {
                                currentDayNum = 0;
                            }
                        }
                    }
                }

                return string;
            }
        },
        whenStartStream = () => {
            if (
                !!item.streams &&
                !!item.streams.length &&
                !item.is_online &&
                !!item.streams[0].schedules.length &&
                !isDisabled &&
                item.streams[0].online_on
            ) {
                const online_on_ms = new Date(
                        item.streams[0].online_on.replaceAll("-", "/")
                    ).getTime(),
                    cur_time_ms = new Date().getTime();

                if (cur_time_ms - online_on_ms < 90000)
                    return <IsStreamNoAnim>{getStreamTime()}</IsStreamNoAnim>;
            }
        },
        isShowKamera = () => {
            if (
                item.streams &&
                item.streams[0] &&
                item.streams[0].schedules[0] &&
                !isDisabled &&
                item.streams[0].online_on
            ) {
                const online_on_ms = new Date(
                        item.streams[0].online_on.replaceAll("-", "/")
                    ).getTime(),
                    cur_time_ms = new Date().getTime();
                if (cur_time_ms - online_on_ms < 90000) return true;

                return false;
            } else {
                return false;
            }
        };

    useEffect(() => {
        if (item.streams && item.streams[0] && item.streams[0].see_you_tomorrow) {
            const year = new Date().getFullYear(),
                month =
                    new Date().getMonth() + 1 < 10
                        ? "0" + (new Date().getMonth() + 1)
                        : new Date().getMonth() + 1,
                dayNow =
                    new Date().getDate() < 10
                        ? "0" + new Date().getDate()
                        : new Date().getDate(),
                dayNext =
                    new Date(Date.now() + 8.64e7).getDate() < 10
                        ? "0" + new Date(Date.now() + 8.64e7).getDate()
                        : new Date(Date.now() + 8.64e7).getDate(),
                today = `${year}-${month}-${dayNow}`,
                tomorrow = `${year}-${month}-${dayNext}`,
                currentTime =
                    new Date().getHours() * 3600 + new Date().getMinutes() * 60,
                seeYouTime = item.streams[0].see_you_tomorrow.split(" ")[1].slice(0, 5),
                disabseeYouTimeMs =
                    seeYouTime.split(":")[0] * 3600 + seeYouTime.split(":")[1] * 60;

            if (tomorrow === item.streams[0].see_you_tomorrow.split(" ")[0])
                setIsDisabled(true);
            if (today === item.streams[0].see_you_tomorrow.split(" ")[0]) {
                if (currentTime < disabseeYouTimeMs) setIsDisabled(true);
            }
        }
    }, []);

    return (
        <SmallCompBlock
            to={{
                pathname: `/company/${item.alias}`,
            }}
        >
            <Desctop>
                {item.is_online ? (
                    <div style={{position: "relative"}}>
                        <PreviewBlockD
                            style={{
                                backgroundImage: `url(${item.streams[0].preview})`,
                                filter: `blur(${(
                                    (item.streams[0].default_sound_volume / 200) *
                                    5
                                ).toFixed()}px)`,
                            }}
                        ></PreviewBlockD>
                        <FilmCircleWrap style={{position: "absolute", top: 0}}>
                            <KameraWrap>{Kamera(false, 20, 20)}</KameraWrap>
                            <CircleTranslation/>
                        </FilmCircleWrap>
                    </div>
                ) : (
                    <NoTranslationD bg={noTranslationBg}>
                        <FilmCircleWrap>
                            {isShowKamera() && (
                                <KameraWrap>{Kamera(false, 20, 20)}</KameraWrap>
                            )}
                            {whenStartStream()}
                        </FilmCircleWrap>
                    </NoTranslationD>
                )}

                <DescriptionD>
                    <TopDescriptionBlockD>
                        <IconToCenter>
                            {item.categories[0] &&
                            TypeIcon(item.categories[0].slug, false, 20, 20)}
                        </IconToCenter>
                        <CompanyNameD>
                            <span translate="no">{item.name}</span>
                        </CompanyNameD>
                    </TopDescriptionBlockD>
                    <MiddleDescriptionBlockD>
                        <WorkTimeWrapD>
                            {item.is_work ? (
                                <>
                                    <IconToCenter>
                                        <CircleD isWork={item.is_work}/>
                                    </IconToCenter>
                                    <IsOpenedD>
                                        {openedTo !== openedFrom
                                            ? "Открыто до " + openedTo
                                            : "Круглосуточно"}
                                    </IsOpenedD>
                                </>
                            ) : (
                                <>
                                    <IconToCenter>
                                        <CircleD isWork={item.is_work}/>
                                    </IconToCenter>
                                    <IsOpenedD>Закрыто</IsOpenedD>
                                </>
                            )}
                        </WorkTimeWrapD>
                    </MiddleDescriptionBlockD>

                    <LocationWrapD>
                        <IconLocToCenter>{Location(false, 16, 16)}</IconLocToCenter>
                        <LocationStyleD>{locationAddress}</LocationStyleD>
                    </LocationWrapD>
                </DescriptionD>
            </Desctop>

            <Mobile>
                {item.is_online && item.is_work ? (
                    <PreviewBlockM
                        style={{backgroundImage: `url(${item.streams[0].preview})`}}
                    >
                        <FilmCircleWrap>
                            <KameraWrap>{Kamera(false, 20, 20)}</KameraWrap>
                            {item.is_online && <CircleTranslation/>}
                        </FilmCircleWrap>
                    </PreviewBlockM>
                ) : (
                    <NoTranslationM bg={noTranslationBg}>
                        <div style={{display: "flex"}}>
                            {isShowKamera() && (
                                <KameraWrap>{Kamera(false, 20, 20)}</KameraWrap>
                            )}
                            {whenStartStream()}
                        </div>
                    </NoTranslationM>
                )}
                <DescriptionM>
                    <TopDescriptionBlockM>
                        <IconToCenter>
                            {item.categories[0] &&
                            TypeIcon(item.categories[0].slug, false, 20, 20)}
                        </IconToCenter>
                        <CompanyNameM>
                            <span translate="no">{item.name}</span>
                        </CompanyNameM>
                    </TopDescriptionBlockM>

                    <BottomDescriptionBlockM>
                        <WorkTimeDistanceWrapM>
                            <CircleRowM>
                                <IconToCenter>
                                    <CircleD isWork={item.is_work}/>
                                </IconToCenter>
                                {item.is_work ? (
                                    <IsOpenedM>
                                        {openedTo !== openedFrom ? "До " + openedTo : "Круглосут."}
                                    </IsOpenedM>
                                ) : (
                                    <IsOpenedM>Закрыто</IsOpenedM>
                                )}
                            </CircleRowM>

                            <DistanceM>
                                {item.distance && item.distance.toFixed(2) + "km"}
                            </DistanceM>
                        </WorkTimeDistanceWrapM>
                    </BottomDescriptionBlockM>
                </DescriptionM>
            </Mobile>
        </SmallCompBlock>
    );
};

export default SmallCompanyBlock;
