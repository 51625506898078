import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import L from "leaflet";
import Geocode from "react-geocode";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import styled from "styled-components";

import { Location } from "../../images";
import AdminHeader from "../AdminHeader";
import { defaultColor } from "../../constants";
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet/dist/leaflet.css";
import "./Map.css";

const CloseBTN = styled.span`
    position: absolute;
    top: 20px;
    left: 85%;
    padding-bottom: 2px;
    height: 50px;
    width: 50px;
    display: -webkit-box;
    z-index: 1001;
    display: -ms-flexbox;
    display: flex;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 3px;
    &:hover {
      color: red;
    }
    @media (max-width: 760px) {
      top: 40px;
    }
  `,
  MapContainerStyle = styled.div`
    height: 100vh;
  `,
  BtnWrap = styled.div`
    z-index: 1001;
    position: absolute;
    left: 730px;
    display: flex;
    justify-content: center;
    width: 400px;
    top: 16px;
    @media (max-width: 760px) {
      display: none;
    }
  `,
  SaveBtn = styled.div`
    background-color: ${defaultColor};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 10px;
    width: calc(50vw - 25px);
    height: 50px;
    cursor: pointer;
    font-weight: 700;
    color: #fff;
    transition: 0.2s ease opacity;
    &:hover {
      opacity: 0.7;
    }
  `,
  CancelBtn = styled.div`
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: calc(50vw - 25px);
    height: 50px;
    border: 2px solid ${defaultColor};
    color: ${defaultColor};
    cursor: pointer;
    font-weight: 700;
    transition: 0.2s ease opacity;
    &:hover {
      opacity: 0.7;
    }
  `,
  LocationWrap = styled.div`
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 1000;
    top: calc(50% - 30px);
    left: calc(50% - 15px);
    border-radius: 50%;
  `;

const Map = ({
  togglePopupGoogleMap,
  initialCenterMap,
  chooseNewAddress,
  isNewAddress,
  styleContainerMap,
  closeBtn,
  height,
  zoom,
}) => {
  const initialCenter = initialCenterMap || {
    lat: 53.904241,
    lng: 27.556932,
  };
  const currentZoom = zoom || 14;

  const [streetName, setStreetName] = useState("");
  const [latLng, setLatLng] = useState("");
  const [mapRef, setMapRef] = useState(null);
  const [dancerPosition, setDancerPosition] = useState(null);
  const [CENTER, setCENTER] = useState(null);

  const locationRef = useRef(null);
  const dancerRef = useRef(null);

  useEffect(() => {
    if (navigator.geolocation && !dancerPosition) {
      navigator.geolocation.getCurrentPosition(
        (pos) =>
          setDancerPosition({
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          }),
        (err) => console.log(err, " GEOLOCATION MAP ERROR")
      );
    } else {
      console.log("Геолокация недоступна");
    }
  });

  useEffect(() => {
    if (!mapRef || !isNewAddress) return;

    const provider = new GoogleProvider({
      params: {
        key: "AIzaSyAUUvm0pPSksSkwwhx-Wlmj1q778Soj2XM",
        language: "ru",
        region: "by",
      },
    });

    const control = new GeoSearchControl({
      provider,
      style: "bar",
      showMarker: false,
    });

    mapRef.addControl(control);

    mapRef.on("geosearch/showlocation", (data) => {
      const { x, y, label } = data.location;
      setLatLng({ lat: y, lng: x });
      setStreetName(label);
    });

    Geocode.setApiKey("AIzaSyAUUvm0pPSksSkwwhx-Wlmj1q778Soj2XM");
    Geocode.setLanguage("ru");
    Geocode.setRegion("by");
    Geocode.setLocationType("ROOFTOP"); // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE
    Geocode.enableDebug();

    mapRef.on("dragend", (e) =>
      getAddressFromCoord(mapRef.getCenter().lat, mapRef.getCenter().lng)
    );

    mapRef.on("zoomend", (e) =>
      getAddressFromCoord(mapRef.getCenter().lat, mapRef.getCenter().lng)
    );
  }, [mapRef]);

  useEffect(() => {
    if (
      mapRef &&
      dancerRef.current &&
      locationRef.current &&
      !isNewAddress &&
      closeBtn
    ) {
      const bounds = mapRef.getBounds();

      bounds.extend(dancerRef.current._latlng);
      bounds.extend(locationRef.current._latlng);

      setCENTER(bounds.getCenter());

      mapRef.flyToBounds(bounds, { duration: 0.1 });
    }
  }, [mapRef, dancerRef, locationRef, isNewAddress, closeBtn, dancerPosition]);

  const getAddressFromCoord = (lat, lng) => {
    Geocode.fromLatLng(`${lat}`, `${lng}`).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setStreetName(address);
        setLatLng({ lat, lng });
        mapRef._controlContainer.childNodes[4].querySelector("input").value =
          response.results[0].formatted_address;
      },
      (error) => console.error(error)
    );
  };

  const save = () => streetName && latLng && chooseNewAddress(streetName, latLng);

  return (
    <MapContainerStyle style={styleContainerMap}>
      {isNewAddress && <AdminHeader close={togglePopupGoogleMap} save={save} />}
      <MapContainer
        whenCreated={(mapInstance) => setMapRef(mapInstance)}
        style={{ height: height || "100vh" }}
        center={CENTER || initialCenter}
        zoom={currentZoom}
        maxNativeZoom={19}
        maxZoom={20}
        zoomControl={false}
      >
        <TileLayer
          maxZoom={20}
          maxNativeZoom={19}
          url={
            "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          }
        />

        <Marker
          ref={locationRef}
          icon={L.divIcon({
            html: renderToStaticMarkup(Location("red", 30, 30)),
            iconAnchor: [15, 30],
            popupAnchor: null,
            shadowSize: [0, 0],
          })}
          position={[initialCenter.lat, initialCenter.lng]}
        />

        {dancerPosition && (
          <Marker
            ref={dancerRef}
            draggable={false}
            position={[dancerPosition.lat, dancerPosition.lng]}
            icon={L.icon({
              iconUrl: `${process.env.PUBLIC_URL}/img/dancer.png`,
              iconSize: [32, 32],
              iconAnchor: [16, 16],
            })}
          />
        )}

        {isNewAddress && (
          <>
            <BtnWrap>
              <SaveBtn onClick={() => save()}>Сохранить</SaveBtn>
              <CancelBtn onClick={togglePopupGoogleMap}>Отмена</CancelBtn>
            </BtnWrap>
            <LocationWrap>{Location(false, 30, 30)}</LocationWrap>
          </>
        )}

        {closeBtn && <CloseBTN onClick={togglePopupGoogleMap}>&#215;</CloseBTN>}
      </MapContainer>
    </MapContainerStyle>
  );
};

export default Map;
