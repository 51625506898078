import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { YMInitializer } from "react-yandex-metrika";
import styled from "styled-components";

import CompanyNav from "../../components/companyNav/CompanyNav";
import TypeNav from "../../components/TypeNav";
import SmallCompanyBlock from "../../components/smallCompanyBlock/SmallCompanyBlock";
import SlideSideMenu from "../../components/SlideSideMenu";
import BottomMenu from "../../components/BottomMenu";
import Header from "../../components/Header";
import Loader from "../../components/loader/Loader";
import Popup from "../../components/Popup";
import QUERY from "../../query";
import { PLACES_EXT_DATA_QUERY } from "../../constants";

const HomeContentWrap = styled.div`
  padding-top: 50px;
  width: 1000px;
  margin: 0 auto;
  padding-bottom: 70px;
  @media (max-width: 760px) {
    position: relative;
    padding-top: 100px;
    width: 100%;
    padding-bottom: 65px;
  }
`;

const NavContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 54px;
  @media (max-width: 760px) {
    height: 0;
    margin: 0;
  }
`;

const HomeContent = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  @media (max-width: 760px) {
    -webkit-transition: 0.3s ease all 0.2s;
    -o-transition: 0.3s ease all 0.2s;
    transition: 0.3s ease all 0.2s;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const NoOneCompany = styled.div`
  width: 100%;
  text-align: center;
  font-size: 22px;
  padding: 30px;
`;

const PopupText = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 760px) {
    font-size: 15px;
  }
`;

const Home = () => {
  const howMachLoad = 48;
  const [DATA, setDATA] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBottomLoading, setIsBottomLoading] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [first, setFirst] = useState(howMachLoad);
  const [typeId, setTypeId] = useState("");
  const [hasMorePages, setHasMorePages] = useState(true);
  const [userLat, setUserLat] = useState(null);
  const [userLon, setUserLon] = useState(null);
  const [wasLoacation, setWasLoacation] = useState(false);
  const [showPopupLocation, setShowPopupLocation] = useState(false);
  const [showSlideSideMenu, setShowSlideSideMenu] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);

  const hideSideMenu = () => {
    setShowSlideSideMenu(false);
    document.body.style.overflow = "visible";
    setIsShowMenu(false);
  };

  const showSideMenu = () => {
    setShowSlideSideMenu(true);
    document.body.style.overflow = "hidden";
    setIsShowMenu(true);
  };

  const findLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setUserLat(pos.coords.latitude);
          setUserLon(pos.coords.longitude);
          setIsLocation(true);
          setWasLoacation(true);
        },
        (err) => {
          !sessionStorage.getItem("requetsLocation") && togglePopupLocation();
          setIsLocation(false);
          setWasLoacation(true);
        }
      );
    } else {
      setIsLocation(false);
      setWasLoacation(true);
    }
  };

  const hide = (e) => {
    if (e.target.className !== "SlideSideMenu" && showSlideSideMenu)
      hideSideMenu();
  };

  const loadContent = (id, isFirst) => {
    const current_id = id || sessionStorage.getItem("filter_id"),
      current_first = isFirst || first,
      userLocatin = userLon && userLat ? `${userLat},${userLon}` : "";

    let searchString = current_id
      ? `where: { column: CATEGORY_IDS, operator: LIKE, value: "%[${current_id}]%"}, 
          first:${current_first}`
      : `first:${current_first}`;

    if (userLocatin)
      searchString = searchString + `, client_coordinates: "${userLocatin}"`;

    setIsLoading(true);
    QUERY({
      query: `query {
          placesExt(${searchString}) { paginatorInfo{hasMorePages lastItem total} ${PLACES_EXT_DATA_QUERY} }
        }`,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setIsBottomLoading(false);
        setDATA(data.data.placesExt.data);
        setFirst((prev) => (prev += howMachLoad));
        setHasMorePages(data.data.placesExt.paginatorInfo.hasMorePages);
      })
      .catch((err) => {});
  };
  
  const clickedType = (id) => {
    setDATA([]);
    window.scrollTo(0, 0);
    setTypeId(id);
    setFirst(howMachLoad);
    setHasMorePages(true);
    if (typeId !== id) {
      id ? loadContent(id, howMachLoad) : loadContent("", howMachLoad);
    }
  };

  const togglePopupLocation = () => {
    showPopupLocation && sessionStorage.setItem("requetsLocation", true);
    setShowPopupLocation(!showPopupLocation);
  };

  useEffect(() => {
    if (showPopupLocation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showPopupLocation]);

  useEffect(() => {
    window.scrollTo(0, 0);
    findLocation();
  }, []);

  useEffect(() => {
    window.onresize = (e) => hideSideMenu();
  });

  const SwipePageSpring = useSpring({
    right: isShowMenu ? 200 : 0,
    config: { duration: 200 },
  });

  const scrollHandler = (e) => {
    if (hasMorePages && !isLoading) {
      const { scrollHeight, scrollTop } = e.target.documentElement;
      if (scrollHeight - (scrollTop + window.innerHeight) < 3000)
        setIsLoading(true);
      setIsBottomLoading(true);
    }
  };

  useEffect(() => {
    wasLoacation && loadContent();
  }, [wasLoacation]);

  useEffect(() => {
    if (isLoading && hasMorePages && first > howMachLoad) {
      loadContent();
      sessionStorage.setItem("prevZoom", "");
      sessionStorage.setItem("prevCenter", "");
    }
    document.addEventListener("scroll", scrollHandler);
    return () => document.removeEventListener("scroll", scrollHandler);
  }, [hasMorePages, isLoading]);

  return (
    <div
      style={{
        minHeight: 1 + window.innerHeight,
      }}
      onClick={(e) => hide(e)}
    >
      <YMInitializer accounts={[83984023]} />
      <div>
        <Header
          isShowMenu={isShowMenu}
          logo
          burger
          showSlideSideMenu={showSlideSideMenu}
          showSideMenu={showSideMenu}
        />
        <HomeContentWrap as={animated.div} style={SwipePageSpring}>
          <NavContainer>
            <CompanyNav
              currentPage="/"
              toSlideFixedNav={isShowMenu}
              clickedType={(id) => clickedType(id)}
            />
            <TypeNav />
          </NavContainer>
          <HomeContent>
            {!!DATA.length &&
              DATA.map((el, i) => (
                <SmallCompanyBlock item={el} key={i} isLocation={isLocation} />
              ))}

            {isLoading && hasMorePages && isBottomLoading && (
              <Loader isBottom={true} />
            )}

            {!DATA.length && !isLoading && (
              <NoOneCompany>Нет заведений</NoOneCompany>
            )}
          </HomeContent>
        </HomeContentWrap>
        <BottomMenu isShowMenu={isShowMenu} border />
      </div>
      {isLoading && hasMorePages && !isBottomLoading && (
        <Loader isBottom={false} />
      )}
      <SlideSideMenu isShowMenu={isShowMenu} />

      {showPopupLocation && (
        <Popup
          style={{ borderRadius: "10px" }}
          togglePopup={togglePopupLocation}
        >
          <PopupText>
            Невозможно получить местоположение <br />
            Включите геолокацию в меню "Настройки"
          </PopupText>
        </Popup>
      )}
    </div>
  );
};

export default Home;
