import { queryPath } from "./settings";

const QUERY = (body, token) => {
  return Promise.resolve(
    fetch(`${queryPath}/graphql`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(body),
      headers: {
        "Access-Control-Allow-Origin": queryPath,
        "Content-Type": "application/json",
        Authorization: token ? "Bearer " + token : "",
      },
    })
  );
};

export default QUERY;
