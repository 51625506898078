import React from "react";
import styled from "styled-components";

import { defaultColor } from "../constants";

const HeaderWrap = styled.div`
    display: none;
    @media (max-width: 760px) {
      z-index: 1100;
      display: flex;
      background-color: #fff;
      width: 100%;
      height: 50px;
      z-index: 3;
      padding: 0 15px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
    }
  `,
  CancelSave = styled.p`
    letter-spacing: 0.5px;
    color: defaultColor;
    font-size: 16px;
    font-weight: normal;
    color: ${defaultColor};
  `;

const AdminHeader = ({ save, close, style, title }) => {
  return (
    <HeaderWrap style={style}>
      <CancelSave onClick={() => close()}>Отмена</CancelSave>
      <CancelSave onClick={() => save()}>{title ? title : 'Готово'}</CancelSave>
    </HeaderWrap>
  );
};

export default AdminHeader;
