import React from "react";

export const Zamok = (color) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.625 7.34375H14.2173V4.13164C14.2173 1.85344 12.3248 0 9.99855 0C7.67234 0 5.7798 1.85344 5.7798 4.13164V7.34375H4.375C3.08266 7.34375 2.03125 8.39516 2.03125 9.6875V17.6562C2.03125 18.9486 3.08266 20 4.375 20H15.625C16.9173 20 17.9688 18.9486 17.9688 17.6562V9.6875C17.9688 8.39516 16.9173 7.34375 15.625 7.34375ZM7.3423 4.13164C7.3423 2.715 8.53391 1.5625 9.99855 1.5625C11.4632 1.5625 12.6548 2.715 12.6548 4.13164V7.34375H7.3423V4.13164ZM16.4062 17.6562C16.4062 18.087 16.0558 18.4375 15.625 18.4375H4.375C3.94422 18.4375 3.59375 18.087 3.59375 17.6562V9.6875C3.59375 9.25672 3.94422 8.90625 4.375 8.90625H15.625C16.0558 8.90625 16.4062 9.25672 16.4062 9.6875V17.6562Z"
        fill={color}
      />
      <path
        d="M10 11.1719C9.20176 11.1719 8.55469 11.8189 8.55469 12.6172C8.55469 13.1269 8.81875 13.5746 9.2173 13.832V15.5469C9.2173 15.9783 9.56707 16.3281 9.99855 16.3281C10.43 16.3281 10.7798 15.9783 10.7798 15.5469V13.8338C11.18 13.5768 11.4453 13.1281 11.4453 12.6172C11.4453 11.8189 10.7982 11.1719 10 11.1719Z"
        fill={color}
      />
    </svg>
  );
};

export const Power = (color) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1527 2.92331C12.7495 2.76925 12.298 2.97093 12.1439 3.37398C11.9898 3.77694 12.1916 4.22862 12.5945 4.38273C15.3561 5.43878 17.2656 8.10069 17.2656 11.1716C17.2656 15.1779 14.0063 18.4373 10 18.4373C5.99371 18.4373 2.73438 15.1779 2.73438 11.1716C2.73438 8.09901 4.64484 5.43859 7.4052 4.38284C7.8082 4.2287 8.00992 3.77702 7.85582 3.37405C7.70164 2.97105 7.24988 2.76937 6.84703 2.92343C3.48379 4.2098 1.17188 7.44866 1.17188 11.1716C1.17188 16.0514 5.1207 19.9998 10 19.9998C14.8798 19.9998 18.8281 16.0509 18.8281 11.1716C18.8281 7.44702 16.5148 4.20909 13.1527 2.92331Z"
        fill={color}
      />
      <path
        d="M10 0C9.56852 0 9.21875 0.349766 9.21875 0.78125V8.43168C9.21875 8.86316 9.56852 9.21293 10 9.21293C10.4315 9.21293 10.7812 8.86312 10.7812 8.43168V0.78125C10.7812 0.349766 10.4315 0 10 0Z"
        fill={color}
      />
    </svg>
  );
};

export const Delete = (color) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M18.12 4.47037L17.6784 3.14667C17.5103 2.64252 17.0402 2.30377 16.5088 2.30377H12.7974V1.09543C12.7974 0.491486 12.3064 0 11.7026 0H8.30388C7.70025 0 7.20907 0.491486 7.20907 1.09543V2.30377H3.49782C2.96636 2.30377 2.49624 2.64252 2.32809 3.14667L1.8865 4.47037C1.78594 4.77173 1.83691 5.10544 2.0226 5.36316C2.2083 5.62088 2.50875 5.77484 2.82644 5.77484H3.28802L4.30395 18.3376C4.37948 19.2697 5.17049 20 6.10509 20H14.1076C15.042 20 15.8332 19.2697 15.9086 18.3374L16.9245 5.77484H17.1801C17.4978 5.77484 17.7982 5.62088 17.9839 5.36331C18.1696 5.10559 18.2206 4.77173 18.12 4.47037ZM8.38094 1.17188H11.6256V2.30377H8.38094V1.17188ZM14.7405 18.243C14.7139 18.5712 14.4359 18.8281 14.1076 18.8281H6.10509C5.77672 18.8281 5.49871 18.5712 5.47216 18.243L4.46371 5.77484H15.7488L14.7405 18.243ZM3.07775 4.60297L3.43984 3.51746C3.44808 3.49243 3.47143 3.47565 3.49782 3.47565H16.5088C16.5352 3.47565 16.5584 3.49243 16.5668 3.51746L16.9289 4.60297H3.07775Z"
          fill={color}
        />
        <path
          d="M12.8716 18.2014C12.882 18.202 12.8922 18.2022 12.9026 18.2022C13.2122 18.2022 13.471 17.9596 13.4872 17.6467L14.0374 7.08431C14.0542 6.76113 13.8058 6.4854 13.4827 6.46862C13.1588 6.45138 12.884 6.70009 12.867 7.02328L12.317 17.5857C12.3002 17.9089 12.5484 18.1846 12.8716 18.2014Z"
          fill={color}
        />
        <path
          d="M6.54555 17.648C6.56264 17.9604 6.82113 18.2021 7.13011 18.2021C7.1408 18.2021 7.15178 18.2018 7.16262 18.2011C7.48564 18.1836 7.73329 17.9074 7.71574 17.5842L7.13942 7.0218C7.12188 6.69862 6.84569 6.45097 6.52251 6.46867C6.19949 6.48621 5.95184 6.7624 5.96938 7.08558L6.54555 17.648Z"
          fill={color}
        />
        <path
          d="M10.0099 18.2021C10.3335 18.2021 10.5958 17.9398 10.5958 17.6161V7.05371C10.5958 6.73007 10.3335 6.46777 10.0099 6.46777C9.68625 6.46777 9.42395 6.73007 9.42395 7.05371V17.6161C9.42395 17.9398 9.68625 18.2021 10.0099 18.2021Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TopArrow = (color, color1) => {
  return (
    <svg
      width="28"
      height="15"
      viewBox="0 0 28 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.018 1.51186C13.5817 1.02371 14.4183 1.02372 14.982 1.51186L26.938 11.8661C27.9879 12.7753 27.3449 14.5 25.9561 14.5H2.04395C0.655117 14.5 0.0121088 12.7753 1.06196 11.8661L13.018 1.51186Z"
        fill={color}
        stroke={color1}
      />
    </svg>
  );
};

export const BottomArrow = (color, color1) => {
  return (
    <svg
      width="28"
      height="15"
      viewBox="0 0 28 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.018 13.4881C13.5817 13.9763 14.4183 13.9763 14.982 13.4881L26.938 3.13389C27.9879 2.22469 27.3449 0.5 25.9561 0.5H2.04395C0.655117 0.5 0.0121088 2.22469 1.06196 3.13389L13.018 13.4881Z"
        fill={color}
        stroke={color1}
      />
    </svg>
  );
};

export const TypeIcon = (slug, color, height, width) => {
  switch (slug) {
    case "pab":
      return (
        <svg
          width={width || "30"}
          height={height || "30"}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                fill={color}
                d="M361.363,437.528c-9.369-29.106-8.534-60.871,2.352-89.447l1.95-5.125c42.285-110.997,30.145-226.544,24.438-242.964
            c0.561-3.314,0.846-6.68,0.846-10.077c0-33.091-26.921-60.012-60.012-60.012c-4.657,0-9.306,0.548-13.825,1.615
            C303.196,11.963,280.415,0,255.921,0c-24.494,0-47.276,11.963-61.19,31.516c-4.52-1.067-9.168-1.615-13.825-1.615
            c-33.091,0-60.012,26.922-60.012,60.012c0,3.398,0.284,6.764,0.846,10.077c-1.418,4.081-22.493,119.774,24.438,242.963
            l1.952,5.129c10.885,28.574,11.72,60.339,2.351,89.445l-2.779,8.634c-7.216,13.772-6.887,29.877,0.972,43.42
            C156.822,503.619,171.374,512,187.604,512h136.637c16.23,0,30.782-8.381,38.929-22.419c7.859-13.542,8.189-29.648,0.972-43.42
            L361.363,437.528z M180.906,59.908c4.814,0,9.405,1.1,13.645,3.272c3.64,1.863,7.88,2.16,11.74,0.825
            c3.862-1.335,7.012-4.189,8.723-7.901c7.335-15.917,23.393-26.201,40.907-26.201c17.515,0,33.573,10.285,40.907,26.201
            c1.71,3.712,4.86,6.565,8.723,7.901c3.862,1.335,8.102,1.038,11.74-0.825c4.239-2.17,8.83-3.272,13.645-3.272
            c16.545,0,30.006,12.566,30.006,29.11h-45.009c-8.286,0-15.003,7.613-15.003,15.899v62.013c0,8.273-6.73,15.003-15.003,15.003
            c-8.273,0-15.003-6.73-15.003-15.003v-62.909c0-8.286-6.717-15.003-15.003-15.003H150.899
            C150.899,72.473,164.36,59.908,180.906,59.908z M148.694,126.549c0.007-0.058,0.013-0.116,0.02-0.175l0.683-6.453h91.522v47.01
            c0,24.818,20.191,45.009,45.009,45.009c24.818,0,45.009-20.191,45.009-45.009v-47.01h31.509l0.683,6.453
            c0.007,0.058,0.013,0.116,0.02,0.175c8.156,69.34-0.67,140.48-25.526,205.727l-1.952,5.126
            c-10.272,26.965-13.214,56.255-8.753,84.58H184.923c4.461-28.325,1.519-57.615-8.753-84.578l-1.952-5.129
            C149.362,267.028,140.536,195.89,148.694,126.549z M337.215,474.52c-1.304,2.246-5.179,7.473-12.977,7.473H187.602
            c-7.798,0-11.672-5.226-12.977-7.473c-4.749-8.183-0.476-12.598,2.723-22.534h157.145
            C337.927,462.656,341.809,466.605,337.215,474.52z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      );

    case "klub":
      return (
        <svg
          width={width || "30"}
          height={height || "30"}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <g>
                <path
                  fill={color}
                  d="M267.1,85.9c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7,0-1-0.1c0,0,0,0,0,0c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.2,0-0.3,0
             c-0.3,0-0.7,0-1-0.1c0,0,0,0-0.1,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0-1.1-0.1c-0.1,0-0.2,0-0.4,0
             c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.7,0c-0.3,0-0.6,0-0.9-0.1c0,0-0.1,0-0.1,0c-0.3,0-0.5,0-0.8-0.1c-0.2,0-0.4,0.1-0.6,0.1
             c-0.4,0-0.7,0-1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0
             c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.7,0.1-1,0.1
             c-0.2,0-0.5,0-0.7,0c-0.4,0.1-0.7,0.1-1.1,0.1c-0.2,0-0.3,0-0.5,0c-0.3,0.1-0.7,0.1-1,0.1c-0.1,0-0.2,0-0.3,0
             c-0.5,0.2-1,0.3-1.6,0.3c-0.2,0-0.4,0-0.6,0C132.6,91.6,42.7,184.7,42.7,298.7C42.7,416.3,138.4,512,256,512
             s213.3-95.7,213.3-213.3C469.3,184.8,379.6,91.7,267.1,85.9z M245.3,107.6v84.1c-26.5-0.5-51.9-2.8-75-6.9
             C187.7,142.4,214.6,113.2,245.3,107.6z M245.3,213.1v85.4c-36.7-0.7-68.9-4.8-95.7-11.1c1-29.7,5.6-57.6,13.3-82.2
             C188.3,209.9,216.3,212.6,245.3,213.1z M184.7,120.5c-14.3,15.7-26.4,36.1-35.8,59.9c-12.6-3-24.3-6.6-34.9-10.6
             C133.4,148.5,157.5,131.5,184.7,120.5z M100,187.1c12.5,5.2,26.5,9.8,41.9,13.6c-7.4,24.5-12.1,51.8-13.5,81
             c-28.4-8.9-48.3-20.3-58-31.9C76.6,227,86.6,205.8,100,187.1z M64,298.7c0-8.2,0.7-16.3,1.7-24.2c14.8,11.8,36.3,21.8,62.5,29.3
             c0.4,31.6,4.8,61.2,12.3,87.7c-29.7-7.8-53.1-18.6-66.2-31.2C67.7,340.9,64,320.2,64,298.7z M91.1,396.5
             c16,7.6,35.4,13.9,57.2,18.8c9.4,24.5,21.8,45.4,36.3,61.4C145.4,461,112.6,432.6,91.1,396.5z M245.3,489.7
             c-28.9-5.2-54.4-31.2-71.8-69.5c22.4,3.6,46.5,5.7,71.8,6.2V489.7z M245.3,405L245.3,405c-28.6-0.6-56.1-3.4-80.8-8.1
             c-8.6-25.9-13.9-55.7-14.9-87.6c28.4,6.3,60.9,10,95.7,10.6V405z M412,187.1c13.4,18.7,23.5,39.8,29.5,62.7
             c-9.5,11.3-29.6,22.7-58,31.6c-1.3-29.1-6-56.3-13.4-80.7C385.4,196.9,399.4,192.3,412,187.1z M398,169.9
             c-10.6,4.1-22.2,7.6-34.9,10.6c-9.4-23.8-21.5-44.2-35.8-59.9C354.5,131.5,378.6,148.5,398,169.9z M266.7,107.6
             c30.7,5.5,57.6,34.8,75.1,77.2c-23.1,4.1-48.6,6.4-75.1,6.9V107.6z M266.7,213.1c29-0.5,57-3.2,82.4-7.9
             c7.6,24.5,12.3,52.3,13.3,81.9c-26.9,6.4-59.2,10.7-95.7,11.4V213.1z M362.4,309.2c-1,31.9-6.3,61.7-14.9,87.6
             c-24.7,4.7-52.2,7.5-80.8,8.1v-85.2h0C301.5,319.2,334,315.5,362.4,309.2z M266.7,489.7v-63.3c25.3-0.5,49.5-2.6,71.8-6.2
             C321.1,458.5,295.5,484.5,266.7,489.7z M327.3,476.8c14.6-16,26.9-37,36.3-61.4c21.8-4.9,41.3-11.2,57.2-18.8
             C399.4,432.6,366.6,461,327.3,476.8z M371.5,391.5c7.5-26.5,11.9-56.1,12.3-87.7c26.2-7.6,47.6-17.5,62.5-29.3
             c1,7.9,1.7,16,1.7,24.2c0,21.6-3.7,42.2-10.3,61.6C424.6,372.9,401.2,383.6,371.5,391.5z"
                />
                <path
                  fill={color}
                  d="M391.8,63.6c10.6,2.9,11,3.3,13.9,13.9c1.3,4.6,5.5,7.8,10.3,7.8s9-3.2,10.3-7.8c2.9-10.6,3.3-11,13.9-13.9
             c4.6-1.3,7.8-5.5,7.8-10.3s-3.2-9-7.8-10.3c-10.6-2.9-11-3.3-13.9-13.9c-2.6-9.3-18-9.3-20.6,0c-2.9,10.6-3.3,11-13.9,13.9
             c-4.6,1.3-7.8,5.5-7.8,10.3S387.2,62.3,391.8,63.6z"
                />
                <path
                  fill={color}
                  d="M56.2,469.7c-10.6-2.9-11-3.3-13.9-13.9c-2.6-9.3-18-9.3-20.6,0c-2.9,10.6-3.3,11-13.9,13.9C3.2,471,0,475.2,0,480
             s3.2,9,7.8,10.3c10.6,2.9,11,3.3,13.9,13.9c1.3,4.6,5.5,7.8,10.3,7.8s9-3.2,10.3-7.8c2.9-10.6,3.3-11,13.9-13.9
             c4.6-1.3,7.8-5.5,7.8-10.3S60.8,471,56.2,469.7z"
                />
                <path
                  fill={color}
                  d="M504.2,117.7c-1.4-0.4-2.3-0.7-2.4-0.6c-0.2-0.4-0.4-1.2-0.8-2.6c-2.6-9.3-18-9.3-20.6,0c-0.4,1.4-0.7,2.2-0.6,2.4
             c-0.4,0.2-1.2,0.4-2.6,0.8c-4.6,1.3-7.8,5.5-7.8,10.3s3.2,9,7.8,10.3c1.4,0.4,2.2,0.5,2.4,0.6c0.2,0.4,0.4,1.2,0.8,2.6
             c1.3,4.6,5.5,7.8,10.3,7.8s9-3.2,10.3-7.8c0.4-1.4,0.7-2.2,0.6-2.4c0.4-0.2,1.2-0.4,2.6-0.8c4.6-1.3,7.8-5.5,7.8-10.3
             S508.8,119,504.2,117.7z"
                />
              </g>
              <ellipse fill={color} cx="256" cy="91.2" rx="30.4" ry="5.8" />
            </g>
          </g>
        </svg>
      );

    case "bar":
      return (
        <svg
          id="Capa_1"
          enableBackground="new 0 0 512 512"
          width={width || "30"}
          height={height || "30"}
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              fill={color}
              d="m313.242 334.191 15.654 177.809h167.45l15.654-177.809zm166 30-2.667 30.296h-127.908l-2.667-30.296zm-122.871 117.809-5.063-57.513h122.626l-5.062 57.513z"
            />
            <path
              fill={color}
              d="m258.016 164.35-51.383-11.524v-62.475h30.179l.002-90.351h-180.446l-.002 90.351h30.184v62.475l-51.384 11.524c-22.241 4.988-37.256 25.832-34.928 48.479l30.688 299.171h231.332l30.687-299.167c2.328-22.652-12.687-43.495-34.929-48.483zm-171.648-134.35h120.446l-.002 30.351h-120.446zm-44.637 163.623 74.819-16.78v-86.492h60.083v86.492l74.818 16.78c7.417 1.663 12.425 8.603 11.65 16.146l-5.172 50.423h-56.892c-6.595-23.851-28.498-41.423-54.446-41.423s-47.851 17.572-54.446 41.423h-56.892l-5.173-50.427c-.774-7.539 4.235-14.479 11.651-16.142zm-3.4 96.568h53.815c6.595 23.852 28.498 41.424 54.446 41.424s47.851-17.572 54.446-41.424h53.814l-10.601 103.344h-195.32zm81.772-15c0-14.569 11.883-26.423 26.488-26.423s26.488 11.854 26.488 26.423c0 14.57-11.883 26.424-26.488 26.424s-26.488-11.853-26.488-26.424zm-62.097 206.809-5.997-58.465h189.166l-5.998 58.465z"
            />
          </g>
        </svg>
      );

    case "launge":
      return (
        <svg
          width={width || "30"}
          height={height || "30"}
          viewBox="-31 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={color}
            d="m75 331h77.578125c2.582031 7.277344 6.988281 13.699219 12.664063 18.714844-27.320313 18.976562-45.242188 50.574218-45.242188 86.285156 0 47.582031 29.886719 73.882812 31.210938 76h147.578124c1.324219-2.121094 31.210938-28.398438 31.210938-76 0-35.710938-17.921875-67.308594-45.242188-86.285156 9.339844-8.25 15.242188-20.304688 15.242188-33.714844 0-24.8125-20.1875-45-45-45h-15v-32.578125c12.765625-4.527344 22.894531-14.65625 27.421875-27.421875h77.578125c36.992188 0 67.792969-26.925781 73.886719-62.203125 18.035156-5.867187 31.113281-22.828125 31.113281-42.796875v-75h-30v-31h-30v31h-30v75c0 18.957031 11.789062 35.199219 28.414062 41.820312-5.203124 19.09375-22.691406 33.179688-43.414062 33.179688h-77.578125c-4.527344-12.765625-14.65625-22.894531-27.421875-27.421875v-32.578125h60v-30h-24.261719l30-91h-161.476562l30 91h-24.261719v30h60v32.578125c-12.765625 4.527344-22.894531 14.65625-27.421875 27.421875h-107.578125c-41.355469 0-75 33.644531-75 75s33.644531 75 75 75zm315-270h30v45c0 8.269531-6.730469 15-15 15s-15-6.730469-15-15zm-204.261719-31h78.523438l-20.113281 61h-38.300782zm114.261719 406c0 16.628906-5.414062 33.050781-15.019531 46h-119.960938c-9.605469-12.949219-15.019531-29.371094-15.019531-46 0-41.355469 33.644531-75 75-75s75 33.644531 75 75zm-30-120c0 8.269531-6.730469 15-15 15h-60c-8.269531 0-15-6.730469-15-15s6.730469-15 15-15h60c8.269531 0 15 6.730469 15 15zm-45-135c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm-150 30h107.578125c4.527344 12.765625 14.65625 22.894531 27.421875 27.421875v32.578125h-15c-19.554688 0-36.226562 12.539062-42.421875 30h-77.578125c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45zm0 0"
          />
        </svg>
      );

    case "karaoke":
      return (
        <svg
          width={width || "30"}
          height={height || "30"}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 512.001 512.001"
        >
          <g>
            <g>
              <path
                fill={color}
                d="M359.422,0.001c-82.522,0-149.746,65.511-152.491,147.379c-0.096,2.83,0.615,5.593,1.982,8.011L41.597,375.545
           c-17.222,22.66-18.042,53.432-3.517,76.8L4.47,485.954c-5.96,5.958-5.96,15.619,0,21.578c5.958,5.959,15.619,5.959,21.578,0
           l33.622-33.622c23.63,14.684,54.34,13.556,76.788-3.504l220.115-167.288c2.459,1.426,5.271,2.167,8.187,2.066
           c81.817-2.882,147.241-70.236,147.241-152.603C512.001,68.448,443.554,0.001,359.422,0.001z M69.203,442.797
           c-12.459-12.459-14.335-32.068-4.819-46.633l51.454,51.453C101.273,457.134,81.662,455.257,69.203,442.797z M140.431,429.056
           l-57.486-57.485l112.393-147.886l92.978,92.978L140.431,429.056z M312.837,298.028l-98.863-98.862l16.367-21.535l104.03,104.03
           L312.837,298.028z M370.156,274.292l-1.843-1.843c-0.001-0.001-0.003-0.003-0.004-0.005
           c-4.764-4.765-125.248-125.248-130.498-130.498c2.065-24.256,11.084-46.349,25.044-64.355L434.43,249.167
           C416.445,263.14,394.38,272.184,370.156,274.292z M455.991,227.572L284.435,56.016c20.667-16.019,46.671-25.499,74.986-25.499
           c67.306,0.001,122.063,54.758,122.063,122.064C481.484,180.883,472.005,206.891,455.991,227.572z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill={color}
                d="M220.249,291.752c-5.958-5.959-15.619-5.959-21.578,0l-43.155,43.156c-5.959,5.959-5.959,15.619,0,21.578
           c5.958,5.958,15.619,5.959,21.578,0l43.155-43.156C226.208,307.371,226.208,297.711,220.249,291.752z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      );
  }
};

export const Location = (color, height, width) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    width={width || "30"}
    height={height || "30"}
  >
    <g>
      <g>
        <path
          fill={color}
          d="M256,110.062c-52.412,0-95.053,42.641-95.053,95.053s42.641,95.053,95.053,95.053s95.053-42.641,95.053-95.053
			S308.413,110.062,256,110.062z M256,260.146c-30.344,0-55.031-24.687-55.031-55.031s24.687-55.031,55.031-55.031
			s55.031,24.687,55.031,55.031S286.344,260.146,256,260.146z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M256,0C142.899,0,50.885,92.015,50.885,205.115v5.67c0,57.2,32.794,123.856,97.474,198.113
			c46.888,53.832,93.121,91.368,95.065,92.94L256,512l12.576-10.161c1.945-1.572,48.178-39.108,95.065-92.94
			c64.679-74.258,97.474-140.913,97.474-198.113v-5.67C461.115,92.015,369.101,0,256,0z M421.093,210.786
			c0,96.665-124.551,213.68-165.093,249.202C215.447,424.455,90.907,307.444,90.907,210.786v-5.67
			c0-91.032,74.061-165.093,165.093-165.093s165.093,74.061,165.093,165.093V210.786z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const Enter = (color, height, width) => {
  return (
    <svg
      height={height}
      viewBox="0 -10 490.66667 490"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="m325.332031 251h-309.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h309.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
      />
      <path
        fill={color}
        d="m240 336.332031c-4.097656 0-8.191406-1.554687-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.636719l74.027344-74.023437-74.027344-74.027344c-6.25-6.25-6.25-16.386719 0-22.636719 6.253906-6.25 16.386719-6.25 22.636719 0l85.332031 85.335938c6.25 6.25 6.25 16.382812 0 22.632812l-85.332031 85.332032c-3.136719 3.160156-7.230469 4.714843-11.328125 4.714843zm0 0"
      />
      <path
        fill={color}
        d="m256 469.667969c-97.089844 0-182.804688-58.410157-218.410156-148.824219-3.242188-8.191406.808594-17.492188 9.023437-20.734375 8.191407-3.199219 17.515625.789063 20.757813 9.046875 30.742187 78.058594 104.789062 128.511719 188.628906 128.511719 111.742188 0 202.667969-90.925781 202.667969-202.667969s-90.925781-202.667969-202.667969-202.667969c-83.839844 0-157.886719 50.453125-188.628906 128.511719-3.265625 8.257812-12.566406 12.246094-20.757813 9.046875-8.214843-3.242187-12.265625-12.542969-9.023437-20.734375 35.605468-90.414062 121.320312-148.824219 218.410156-148.824219 129.386719 0 234.667969 105.28125 234.667969 234.667969s-105.28125 234.667969-234.667969 234.667969zm0 0"
      />
    </svg>
  );
};

export const Kamera = (color, height, width) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width || "30"}
      height={height || "30"}
      x="0"
      y="0"
      viewBox="0 0 467.968 467.968"
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M264.704,96.512H51.2c-28.16,0-51.2,23.04-51.2,51.2v172.544c0,28.16,23.04,51.2,51.2,51.2h213.504    c28.16,0,51.2-23.04,51.2-51.2V147.712C315.904,119.04,292.864,96.512,264.704,96.512z"
              fill="#ffffff"
              data-original="#000000"
            />
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M430.08,124.672c-3.072,0.512-6.144,2.048-8.704,3.584l-79.872,46.08V293.12l80.384,46.08    c14.848,8.704,33.28,3.584,41.984-11.264c2.56-4.608,4.096-9.728,4.096-15.36V154.368    C467.968,135.424,450.048,120.064,430.08,124.672z"
              fill="#ffffff"
              data-original="#000000"
            />
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
      </g>
    </svg>
  );
};

export const AdminIcons = (name, color, height, width) => {
  switch (name) {
    case "barIcon":
      return (
        <svg
          id="Layer_1"
          enableBackground="new 0 0 512 512"
          height={height}
          width={width}
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={color}
            d="m492.606 319.394c-6.839-6.84-10.606-15.934-10.606-25.607v-113.787h-90v113.787c0 9.673-3.767 18.767-10.606 25.607-12.507 12.505-19.394 29.133-19.394 46.819v8.828c-12.544-9.438-28.129-15.041-45-15.041h-17v-30h-31.586c16.63-16.281 27.886-38.03 30.823-62.308 17.857-5.97 30.763-22.853 30.763-42.692v-90c0-41.355-33.645-75-75-75h-47.58c-6.192-17.458-22.865-30-42.42-30h-30c-8.271 0-15-6.729-15-15v-15h-15c-41.355 0-75 33.645-75 75 0 36.219 25.808 66.522 60 73.491v34.08c-17.461 6.191-30 22.874-30 42.43 0 19.839 12.906 36.722 30.763 42.692 2.937 24.277 14.193 46.026 30.823 62.307h-31.586v30h-15c-41.355 0-75 33.645-75 75v77h512v-145.787c0-17.686-6.887-34.314-19.394-46.819zm-10.606 70.606v30h-90v-30zm-30-180v30h-30v-30zm-30 83.787v-23.787h30v23.787c0 17.687 6.887 34.314 19.394 46.82 5.351 5.351 8.811 12.085 10.068 19.394h-88.923c1.257-7.309 4.717-14.042 10.068-19.394 12.506-12.506 19.393-29.134 19.393-46.82zm-212 66.213v30h-30v-30zm-43.153 60h56.306l14.351 3.588-22.758 58.412h-39.492l-22.758-58.412zm73.153-26.712v-33.288h30v40.789zm60-258.288v47.57c-4.693-1.664-9.743-2.57-15-2.57-8.271 0-15-6.729-15-15 0-24.813-20.187-45-45-45h-15v-30h45c24.813 0 45 20.187 45 45zm-240-60c0-20.718 14.075-38.211 33.164-43.421 6.615 16.632 22.875 28.421 41.836 28.421h30c8.271 0 15 6.729 15 15v45h-75c-24.813 0-45-20.187-45-45zm60 180v-15h-15c-8.271 0-15-6.729-15-15s6.729-15 15-15h15v-60h105c8.271 0 15 6.729 15 15 0 24.813 20.187 45 45 45 8.271 0 15 6.729 15 15s-6.729 15-15 15h-15v15c0 41.355-33.645 75-75 75s-75-33.645-75-75zm0 105h30v33.288l-30 7.5zm-90 75c0-24.813 20.187-45 45-45h15v49.211l33.157-8.289 19.9 51.078h-113.057zm216.942 47 19.9-51.078 33.158 8.289v-49.211h17c24.813 0 45 20.187 45 45v47zm145.058 0v-32h90v32z"
          />
        </svg>
      );
    case "streaming":
      return (
        <svg
          id="Layer_1"
          enableBackground="new 0 0 511.998 511.998"
          height={height}
          width={width}
          viewBox="0 0 511.998 511.998"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              fill={color}
              d="m255.999 215.983c-59.355 0-107.644 48.289-107.644 107.643 0 59.355 48.289 107.644 107.644 107.644 59.354 0 107.644-48.289 107.644-107.644 0-59.354-48.289-107.643-107.644-107.643zm0 185.287c-42.813 0-77.644-34.831-77.644-77.644 0-42.812 34.831-77.643 77.644-77.643 42.812 0 77.644 34.831 77.644 77.643 0 42.813-34.831 77.644-77.644 77.644z"
            />
            <path
              fill={color}
              d="m255.999 275.842c-26.349 0-47.785 21.436-47.785 47.785s21.436 47.785 47.785 47.785 47.784-21.436 47.784-47.785-21.435-47.785-47.784-47.785zm0 65.569c-9.807 0-17.785-7.978-17.785-17.785s7.978-17.785 17.785-17.785 17.784 7.978 17.784 17.785-7.977 17.785-17.784 17.785z"
            />
            <path fill={color} d="m112.471 222.406h30v30.002h-30z" />
            <path
              fill={color}
              d="m0 17.959v52.524c0 30.109 23.806 54.762 53.584 56.125v165.015c0 111.612 90.803 202.415 202.415 202.415s202.415-90.803 202.415-202.415v-165.014c29.778-1.363 53.584-26.016 53.584-56.125v-52.525zm83.584 108.709h344.83v30.71h-344.83zm172.415 337.371c-95.07 0-172.415-77.345-172.415-172.415v-104.245h344.83v104.245c0 95.069-77.345 172.415-172.415 172.415zm225.999-393.555c0 14.438-11.746 26.185-26.185 26.185h-399.628c-14.439-.001-26.185-11.747-26.185-26.185v-22.525h451.998z"
            />
          </g>
        </svg>
      );
    case "video-camera":
      return (
        <svg
          id="Capa_1"
          enableBackground="new 0 0 512 512"
          height={height}
          width={width}
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              fill={color}
              d="m504.928 233.267c-4.397-2.739-9.897-3.013-14.548-.727l-100.38 49.367v-15.907c0-23.447-14.754-43.503-35.46-51.401 21.734-19.251 35.46-47.35 35.46-78.599 0-57.897-47.103-105-105-105-38.151 0-71.612 20.455-90 50.971-18.388-30.516-51.849-50.971-90-50.971-57.897 0-105 47.103-105 105 0 31.249 13.726 59.348 35.46 78.599-20.706 7.898-35.46 27.954-35.46 51.401v160c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55v-15.907l100.38 49.367c2.094 1.03 4.359 1.54 6.619 1.54 2.76 0 5.511-.761 7.929-2.267 4.398-2.738 7.072-7.552 7.072-12.733v-200c0-5.181-2.674-9.995-7.072-12.733zm-219.928-172.267c41.355 0 75 33.645 75 75s-33.645 75-75 75-75-33.645-75-75 33.645-75 75-75zm-73.404 150h-33.192c6.377-6.242 11.965-13.286 16.596-20.971 4.631 7.685 10.219 14.729 16.596 20.971zm-181.596-75c0-41.355 33.645-75 75-75s75 33.645 75 75-33.645 75-75 75-75-33.645-75-75zm330 290c0 13.785-11.215 25-25 25h-280c-13.785 0-25-11.215-25-25v-160c0-13.785 11.215-25 25-25h280c13.785 0 25 11.215 25 25v40 80zm122-4.093-92-45.246v-61.322l92-45.246z"
            />
            <path
              fill={color}
              d="m105 181c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z"
            />
            <path
              fill={color}
              d="m285 181c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z"
            />
            <path
              fill={color}
              d="m315 271h-110c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15h110c8.284 0 15-6.716 15-15v-90c0-8.284-6.716-15-15-15zm-15 90h-80v-60h80z"
            />
            <path
              fill={color}
              d="m145 391h-70c-8.284 0-15 6.716-15 15s6.716 15 15 15h70c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
            />
            <path
              fill={color}
              d="m145 331h-70c-8.284 0-15 6.716-15 15s6.716 15 15 15h70c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
            />
          </g>
        </svg>
      );
    case "clocklite":
      return Clock(color, height, width);
  }
};

export const Clock = (color, height, width) => {
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            fill={color}
            d="M347.216,301.211l-71.387-53.54V138.609c0-10.966-8.864-19.83-19.83-19.83c-10.966,0-19.83,8.864-19.83,19.83v118.978
       c0,6.246,2.935,12.136,7.932,15.864l79.318,59.489c3.569,2.677,7.734,3.966,11.878,3.966c6.048,0,11.997-2.717,15.884-7.952
       C357.766,320.208,355.981,307.775,347.216,301.211z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill={color}
            d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.833,256-256S397.167,0,256,0z M256,472.341
       c-119.275,0-216.341-97.066-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.066,216.341,216.341
       S375.275,472.341,256,472.341z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
