import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { debounce } from "lodash";
import { matchSorter } from "match-sorter";
import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";

import Header from "../../components/Header";
import SlideSideMenu from "../../components/SlideSideMenu";
import Loader from "../../components/loader/Loader";
import QUERY from "../../query";
import { defaultColor } from "../../constants";
import Popup from "../../components/Popup";
import { Zamok, Power, Delete } from "../../images";

const EditCompanyWrap = styled.div`
  @media (max-width: 760px) {
    padding: 0 10px;
    width: 100vw;
  }
`;
const GoBackBtnD = styled(Link)`
  font-size: 14px;
  display: block;
  font-weight: 500;
  height: 100px;
  width: 150px;
  line-height: 100px;
  &:hover {
    color: ${defaultColor};
  }
  @media (max-width: 760px) {
    display: none;
  }
`;

const GoBackBtnArrowD = styled.span`
  font-size: 18px;
  padding-right: 5px;
`;

const EditCompanyContent = styled.div`
  width: 1000px;
  margin: 0 auto;
  @media (max-width: 760px) {
    width: 100%;
    overflow: hidden;
    margin: 0px;
    display: block;
    padding-top: 60px;
  }
`;

const HeaderText = styled.h3`
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 50px;
  @media (max-width: 760px) {
    width: 100vw;
    padding: 5px;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0px;
    text-align: center;
  }
`;

const TopBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 760px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const SearchInput = styled.input`
  display: flex;
  width: 550px;
  height: 50px;
  border: none;
  outline: none;
  padding: 0 20px;
  font-weight: 400;
  font-size: 18px;
  color: #3d3d3d;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  @media (max-width: 760px) {
    height: 50px;
    margin-right: 10px;
    width: 220px;
  }
`;

const NewCompany = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${defaultColor};
  cursor: pointer;
  color: #fff;
  width: 240px;
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 760px) {
    height: 48px;
    flex: 1;
  }
`;

const Table = styled.table`
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  width: 100%;
  @media (max-width: 760px) {
    margin: 0;
    width: 100vw;
  }
`;

const Tr = styled.tr`
  display: flex;
  margin: 10px 0;
  @media (max-width: 760px) {
    display: flex;
    width: 100vw;
  }
`;

const Td1 = styled.td`
  width: 65px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 80px;
  @media (max-width: 760px) {
    width: 50px;
    padding-right: 5px;
    padding-left: 5px;
  }
`;

const Td1H = styled.td`
  width: 65px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 80px;
  @media (max-width: 760px) {
    width: 50px;
    padding-right: 5px;
    padding-left: 5px;
  }
`;

const Td2 = styled.td`
  width: 280px;
  font-weight: 600;
  @media (max-width: 760px) {
    display: flex;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
  }
`;

const Td2H = styled.td`
  width: 280px;
  font-weight: 600;
  font-size: 18px;
  @media (max-width: 760px) {
    display: flex;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
  }
`;

const Td2alias = styled.td`
  width: 280px;
  font-weight: 600;
  color: #9d9d9d;
  @media (max-width: 760px) {
    display: none;
  }
`;

const Td2Halias = styled.td`
  width: 280px;
  font-weight: 600;
  font-size: 18px;
  @media (max-width: 760px) {
    display: none;
  }
`;

const Td3 = styled.td`
  width: 180px;
  font-size: 18px;
  font-weight: 400;
  color: ${defaultColor};
  text-align: center;
  @media (max-width: 760px) {
    display: none;
  }
`;

const Td3H = styled.td`
  width: 180px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 760px) {
    display: none;
  }
`;

const Td4 = styled.td`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  @media (max-width: 760px) {
    display: flex;
    flex: 0.6;
  }
`;

const Td4H = styled.td`
  display: flex;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 760px) {
    display: flex;
    flex: 0.6;
  }
`;

const TdBtnsWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 760px) {
    flex: 1;
    align-self: flex-end;
  }
`;

const TdBtn = styled.div`
  display: flex;
  width: 50px;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 760px) {
    width: 30px;
    padding-right: 5px;
    padding-left: 5px;
  }
`;

const NameLink = styled(Link)`
  display: block;
  width: 100%;
  transition: 0.3s ease color;
  &:hover {
    color: ${defaultColor};
  }
`;

const PopupQuestion = styled.p`
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10px;
`;

const PopupBtnsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const Yes = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  text-transform: capitalize;
  margin-right: 20px;
  &:hover {
    opacity: 0.7;
  }
  background-color: ${defaultColor};
  @media (max-width: 760px) {
    height: 50px;
    width: 50%;
  }
`;

const No = styled(Yes)`
  margin-right: 0;
  background-color: #fff;
  border: 2px solid ${defaultColor};
  color: ${defaultColor};
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthInput = styled.input`
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  -webkit-appearance: none;
  transition: 0.3s ease all;
  width: 100%;
  font-size: 16px;
  height: 45px;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 7px 0px;
  padding: 0 10px;
  border: ${(props) => (props.err ? "1px solid red" : "1px solid #e5e5e5")};
  @media (max-width: 760px) {
    height: 50px;
  }
`;

const ErrorText = styled.p`
  text-align: center;
  font-size: 10px;
  color: ${defaultColor};
  font-weight: bold;
`;

const SelectedCity = styled.div`
  border: 1px solid ${defaultColor};
  border-radius: 5px;
  display: inline-block;
  position: relative;
  padding: 10px 30px;
  background: #fff;
  font-weight: bold;
`;

const SelectedCityClose = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
  width: 12px;
  height: 12px;
`;

const Line = styled.span`
  width: 12px;
  height: 2px;
  background: #000000;
  display: block;
  position: absolute;
  &:first-child {
    transform: rotate(45deg);
  }
  &:last-child {
    transform: rotate(-45deg);
  }
`;

const CitySearch = styled(Autocomplete)`
  width: 55%;
  background-color: white;
  border-radius: 10px;
  label {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    letter-spacing: 0.5px;
    padding-left: 8px;
    padding-right: 8px;
  }
  fieldset {
    border-radius: 10px;
  }
  @media (max-width: 760px) {
    width: 100%;
  }
`;

const EditCompany = () => {
  const [showSlideSideMenu, setShowSlideSideMenu] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [places, setPlaces] = useState([]);
  const [showPopupIsDelete, setShowPopupIsDelete] = useState(false);
  const [showPopupNewCompany, setShowPopupNewCompany] = useState(false);
  const [showPopupNewUser, setShowPopupNewUser] = useState(false);
  const [clickedDeleteBtnName, setClickedDeleteBtnName] = useState("");
  const [clickedDeleteBtnId, setClickedDeleteBtnId] = useState("");
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [placeName, setPlaceName] = useState("");
  const [user, setUser] = useState("");
  const [userType, setUserType] = useState("");
  const [newUserError, setNewUserError] = useState("");
  const [newCompanyError, setNewCompanyError] = useState("");
  const [onlineCounterWorkFiz, setOnlineCounterWorkFiz] = useState(0);
  const [countOfCameras, setCountOfCameras] = useState(0);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  const togglePopupIsDelete = () => setShowPopupIsDelete(!showPopupIsDelete);

  const togglePopupNewCompany = () =>
    setShowPopupNewCompany(!showPopupNewCompany);

  const togglePopupNewUser = () => {
    showPopupNewUser && setUser("");
    setShowPopupNewUser(!showPopupNewUser);
  };

  const hideSideMenu = () => {
    setShowSlideSideMenu(false);
    document.body.style.overflow = "visible";
    setIsShowMenu(false);
  };

  const showSideMenu = () => {
    setShowSlideSideMenu(true);
    document.body.style.overflow = "hidden";
    setIsShowMenu(true);
  };

  useEffect(() => {
    if (showPopupIsDelete || showPopupNewCompany || showPopupNewUser) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    if (!showPopupNewCompany || !showPopupNewUser) {
      clearAllInputs();
      clearAllErrors();
    }
  }, [showPopupIsDelete, showPopupNewCompany, showPopupNewUser]);

  window.onresize = function (e) {
    hideSideMenu();
  };

  const ME = () => {
    setIsLoading(true);

    QUERY({
      query: `query {
        user(id:"${localStorage.getItem("userId")}") { 
          id type name email 
          places {id name alias disabled categories{ name }} 
        }
        cities {id name lat lon}
      }`,
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.errors) {
          setUserType(res?.data?.user.type);
          if (res?.data?.user.type === "client") {
            setPlaces(res?.data?.user.places);
          } else {
            refreshData(false, true);
          }
          setCities(res?.data?.cities);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, " ERROR USER");
        setIsLoading(false);
        setCities([]);
      });
  };

  const refreshData = (filterText, admin) => {
    if (userType === "employee" || admin) {
      let queryText = filterText
        ? `first : 300, name: "%${filterText}%"`
        : `first : 300, orderBy: [
                      { placeExt: { aggregate: AVG, column: IS_ONLINE }, order: DESC },
                      { streams: { aggregate: COUNT }, order: DESC }
                    ]`;

      if (selectedCity) {
        queryText = `${queryText}, city_id: "${selectedCity.id}"`;
      }

      QUERY({
        query: `query {
            places(${queryText}) { data {id name alias disabled categories{name } user {id type email name} streams{ id online_on}}}
          }`,
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.errors) {
            setIsLoading(false);
            setPlaces(data.data.places.data);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    ME();
    sessionStorage.setItem("prevZoom", "");
    sessionStorage.setItem("prevCenter", "");
  }, []);

  useEffect(() => {
    refreshData();
  }, [selectedCity?.id]);

  const SwipePageSpring = useSpring({
    right: isShowMenu ? 200 : 0,
    config: { duration: 200 },
  });

  const createNewCompany = (name, alias) => {
    clearAllErrors("");
    setIsLoading(true);
    QUERY(
      {
        query: `mutation {
          createPlace(
            input:{
              name: "${name}",
              address:"улица Ленина 8, Минск, Беларусь",
              description: "введите описание",
              lat: 53.9006799,
              lon: 27.5582599,
              alias: "${alias}",
              categories:{
                connect: 2
              }    
            }){id name address description lat lon categories{slug}}        
      }`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (!data.errors) {
          clearAllInputs();
          togglePopupNewCompany();
          refreshData();
          return <Redirect to={`/admin/${data.data.createPlace.id}`} />;
        } else {
          if (
            data.errors &&
            data.errors.length &&
            data.errors[0].extensions &&
            data.errors[0].extensions.category === "validation"
          ) {
            setNewCompanyError(
              data.errors[0].extensions.validation[
                Object.keys(data.errors[0].extensions.validation)[0]
              ]
                .toString()
                .replace("input.", "")
            );
          }
        }
      })
      .catch((err) => console.log(err, "EDIT ERROR"));
  };

  const deleteCompany = (id) => {
    setIsLoading(true);
    QUERY(
      {
        query: `mutation {
          deletePlace(id:${id}){id name}        
        }`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (!data.errors) refreshData();
      })
      .catch((err) => {});
  };

  const toggleDisabled = (id, disabled) => {
    QUERY(
      {
        query: `mutation {
          updatePlace(
            input:{
              id:"${id}"            
              disabled : ${disabled ? false : true}
            }
          ){id disabled}
      }`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.errors) refreshData();
      })
      .catch((err) => {});
  };

  const hide = (e) => {
    e.target.className !== "SlideSideMenu" &&
      showSlideSideMenu &&
      hideSideMenu();
  };

  const deleteOne = (name, id) => {
    togglePopupIsDelete();
    setClickedDeleteBtnName(name);
    setClickedDeleteBtnId(id);
  };

  const clearAllInputs = () => {
    setName("");
    setAlias("");
    setUserEmail("");
    setUserPassword("");
  };

  const clearAllErrors = () => {
    setNewUserError("");
    setNewCompanyError("");
  };

  const registration = (email, password) => {
    clearAllErrors("");
    const client = "client";
    setIsLoading(true);
    QUERY({
      query: `mutation {
          register (input: {
            name: "${email}", email: "${email}", type: ${client},
            password: "${password}", password_confirmation: "${password}"
          }) { status tokens{user{id type name email }} }
        }`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.errors) {
          QUERY(
            {
              query: `mutation {
                  updatePlace(
                    input:{ id:"${placeId}" user_id: ${data.data.register.tokens.user.id} }
                  ){id disabled}
                }`,
            },
            localStorage.getItem("token")
          )
            .then((res) => res.json())
            .then((data) => {
              setIsLoading(false);
              if (!data.errors) {
                togglePopupNewUser();
                setUser("");
                refreshData();
              }
            })
            .catch((err) => setIsLoading(false));
        } else {
          setIsLoading(false);
          if (
            data.errors &&
            data.errors.length &&
            data.errors[0].extensions &&
            data.errors[0].extensions.category === "validation"
          ) {
            setNewUserError(
              data.errors[0].extensions.validation[
                Object.keys(data.errors[0].extensions.validation)[0]
              ]
                .toString()
                .replace("input.", "")
            );
          }

          console.log(data, "REGISER ERR");
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const changePassword = (password) => {
    setIsLoading(true);
    QUERY(
      {
        query: `mutation {
            setPassword(
              input:{ user_id: "${user.id}" password: "${password}" }
            ){status message}
          }`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (!data.errors) {
          togglePopupNewUser();
          setUser("");
          refreshData();
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const deleteUser = () => {
    setIsLoading(true);
    QUERY(
      {
        query: `mutation { deleteUser( id: "${user.id}" ){id name email} }`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (!data.errors) {
          togglePopupNewUser();
          refreshData();
        }
      })
      .catch((err) => setIsLoading(false));
  };

  let countWorkFiz = 0;
  let camerasCount = 0;

  const GetTable = React.memo(() => {
    if (places && places.length) {
      return (
        <Table>
          <thead>
            <Tr>
              <Td1H>№</Td1H>
              <Td2H>Заведение</Td2H>
              <Td2Halias>Псевдоним</Td2Halias>
              <Td3H>Категория</Td3H>
              <Td4H></Td4H>
            </Tr>
          </thead>
          <tbody>
            {places.map(
              (
                { id, name, alias, categories, disabled, user, streams },
                index
              ) => {
                let online_on_ms,
                  cur_time_ms,
                  broken = false;

                if (streams && streams[0] && streams[0].online_on) {
                  online_on_ms = new Date(
                    streams[0].online_on.replaceAll("-", "/")
                  ).getTime();
                  cur_time_ms = new Date().getTime();
                  if (cur_time_ms - online_on_ms > 90000) broken = true;
                } else {
                  if (streams && streams[0] && !streams[0].online_on)
                    broken = true;
                }

                if (streams && streams[0]) {
                  camerasCount++;
                  !broken && countWorkFiz++;
                }

                if (places.length - 1 === index) {
                  setOnlineCounterWorkFiz(countWorkFiz);
                  setCountOfCameras(camerasCount);
                }

                return (
                  <Tr key={id}>
                    <Td1>{id}</Td1>
                    <Td2>
                      <NameLink
                        to={`/admin/${alias}`}
                        style={
                          broken
                            ? { color: "red" }
                            : streams && streams[0] && streams[0]
                            ? { color: "green" }
                            : {}
                        }
                      >
                        {name}
                      </NameLink>
                    </Td2>
                    <Td2alias>{alias}</Td2alias>
                    <Td3>
                      {categories[0] &&
                        categories[0].name &&
                        categories[0].name.toLowerCase()}
                    </Td3>
                    <Td4>
                      {userType === "employee" && (
                        <TdBtnsWrap>
                          <TdBtn
                            onClick={() => {
                              user && user.type === "client" && setUser(user);
                              togglePopupNewUser();
                              setPlaceId(id);
                              setPlaceName(name);
                            }}
                          >
                            {user && user.type === "client"
                              ? Zamok("green")
                              : Zamok("red")}
                          </TdBtn>
                          <TdBtn onClick={() => toggleDisabled(id, disabled)}>
                            {disabled ? Power("red") : Power("green")}
                          </TdBtn>

                          <TdBtn onClick={() => deleteOne(name, id)}>
                            {Delete("black")}
                          </TdBtn>
                        </TdBtnsWrap>
                      )}
                    </Td4>
                  </Tr>
                );
              }
            )}
          </tbody>
        </Table>
      );
    } else {
      return <div />;
    }
  });

  const onSearchName = (e) => {
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = debounce(async (criteria) => {
    setInputValue(await refreshData(criteria));
  }, 500);

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: [
        { threshold: matchSorter.rankings.STARTS_WITH, key: "name" },
        inputValue,
      ],
    });
  };

  if (!localStorage.getItem("userId")) {
    return <Redirect to="/login" />;
  } else {
    return (
      <EditCompanyWrap onClick={(e) => hide(e)}>
        <Header
          isShowMenu={isShowMenu}
          logo
          burger
          arrow
          showSlideSideMenu={showSlideSideMenu}
          showSideMenu={showSideMenu}
        />

        <EditCompanyContent as={animated.div} style={SwipePageSpring}>
          <GoBackBtnD to="/">
            <GoBackBtnArrowD>&#8592;</GoBackBtnArrowD>
            На главную
          </GoBackBtnD>
          <HeaderText>
            Список заведений{" "}
            {userType === "employee" && (
              <span>
                <span style={{ color: "green" }}>{onlineCounterWorkFiz}</span>
                {"/" + countOfCameras}
              </span>
            )}
          </HeaderText>

          {userType === "employee" && (
            <>
              <TopBlockContainer>
                <SearchInput
                  placeholder="Поиск"
                  value={inputValue}
                  onChange={onSearchName}
                />
                <NewCompany onClick={() => togglePopupNewCompany()}>
                  Добавить
                </NewCompany>
              </TopBlockContainer>
              <TopBlockContainer>
                <CitySearch
                  options={cities}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Поиск по городам" />
                  )}
                  onChange={(e, value) => {
                    setSelectedCity(value);
                  }}
                  noOptionsText={"Ничего не найдено"}
                />
              </TopBlockContainer>
              {!!selectedCity && (
                <TopBlockContainer>
                  <SelectedCity>
                    <SelectedCityClose
                      onClick={() => {
                        setSelectedCity(null);
                      }}
                    >
                      <Line />
                      <Line />
                    </SelectedCityClose>
                    {selectedCity.name}
                  </SelectedCity>
                </TopBlockContainer>
              )}
            </>
          )}
          <GetTable />
        </EditCompanyContent>

        {isLoading && <Loader style={{ zIndex: 1220 }} />}

        <SlideSideMenu isShowMenu={isShowMenu} />
        {showPopupNewCompany && (
          <Popup
            style={{ borderRadius: "10px" }}
            togglePopup={togglePopupNewCompany}
          >
            <PopupQuestion>Введите данные для регистрации</PopupQuestion>
            <Form>
              <AuthInput
                err={false}
                type="text"
                name="name"
                placeholder="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <AuthInput
                err={false}
                type="text"
                name="alias"
                placeholder="alias"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
              />
              {newCompanyError && <ErrorText>{newCompanyError}</ErrorText>}
            </Form>
            <PopupBtnsWrap>
              <Yes onClick={() => createNewCompany(name, alias)}>Сохранить</Yes>
              <No onClick={() => togglePopupNewCompany()}>Отмена</No>
            </PopupBtnsWrap>
          </Popup>
        )}

        {showPopupNewUser && (
          <Popup
            style={{ borderRadius: "10px" }}
            togglePopup={togglePopupNewUser}
          >
            <PopupQuestion>
              {user
                ? "Смена пароля пользователя"
                : "Данные регистрации пользователя"}
              <br /> "{placeName}"
            </PopupQuestion>

            <Form>
              <AuthInput
                disabled={user}
                type="email"
                name="email"
                placeholder={user ? user.email : "email"}
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <AuthInput
                type="password"
                name="password"
                placeholder="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
              {newUserError && <ErrorText>{newUserError}</ErrorText>}
            </Form>

            <PopupBtnsWrap>
              <Yes
                onClick={() => {
                  !user && registration(userEmail, userPassword);
                  user && userPassword && changePassword(userPassword);
                }}
              >
                Сохранить
              </Yes>
              <No
                onClick={() => {
                  togglePopupNewUser();
                  setUser("");
                }}
              >
                Отмена
              </No>
            </PopupBtnsWrap>
            {user && (
              <Yes
                onClick={deleteUser}
                style={{
                  width: "100%",
                  textTransform: "none",
                  marginTop: "15px",
                }}
              >
                Удалить пользователя
              </Yes>
            )}
          </Popup>
        )}

        {showPopupIsDelete && (
          <Popup
            style={{ borderRadius: "10px" }}
            togglePopup={togglePopupIsDelete}
          >
            <PopupQuestion>{`Действительно хотите удалить  "${clickedDeleteBtnName}"?`}</PopupQuestion>
            <PopupBtnsWrap>
              <Yes
                onClick={() => {
                  deleteCompany(clickedDeleteBtnId);
                  togglePopupIsDelete();
                }}
              >
                да
              </Yes>
              <No onClick={() => togglePopupIsDelete()}>нет</No>
            </PopupBtnsWrap>
          </Popup>
        )}
      </EditCompanyWrap>
    );
  }
};

export default EditCompany;
