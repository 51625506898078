import React from "react";
import { defaultColor } from "../../constants";
import styled from "styled-components";

import QUERY from "../../query";

import Popup from "../../components/Popup";
import { useCookies } from "react-cookie";
import AdminHeader from "../../components/AdminHeader";

const AdminMenuTitleM = styled.p`
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  `,
  CategoryBtn = styled.span`
    display: inline-block;
    width: calc(50% - 20px);
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    margin: 8px;
    &:hover {
      background-color: #f8104d;
      color: #fff;
    }
  `,
  ImgWrap = styled.span`
    position: relative;
    top: 10px;
    margin-right: 10px;
  `,
  Name = styled.span`
    position: relative;
    top: 4px;
  `,
  Btn = styled.div`
    text-align: center;
  `;

const ChooseTypePopup = ({
  togglePopupChooseType,
  DATA,
  setTypeOfCompany,
  setTypeOfCompanyId,
  typeOfCompanyId,
  refreshData,
  uniqueCompanyType,
  typeOfCompany,
  setHoveredBtn,
  renderCustomTypeImg,
  hoveredBtn,
}) => {
  const updateCategory = () => {
      if (localStorage.getItem("token")) {
        QUERY(
          {
            query: `mutation {
            updatePlace(
              input:{
                id:"${DATA.id}"
                ${
                  DATA.categories &&
                  DATA.categories[0] &&
                  typeOfCompanyId &&
                  typeOfCompanyId !== DATA.categories[0].id
                    ? `categories:{
                        disconnect:"${DATA.categories[0].id}"
                        connect:"${typeOfCompanyId}"
                      }`
                    : typeOfCompanyId
                    ? `categories:{ connect: "${typeOfCompanyId}" }`
                    : `categories:{}`
                }
              }
            ){id}
          }`,
          },
          localStorage.getItem("token")
        )
          .then((res) => res.json())
          .then((data) => {
            !data.errors ? refreshData() : console.log(data.errors, " ERRORS");
          })
          .catch((err) => console.log(err, "  *******ERR"));
      }
    },
    cancel = () => {
      setTypeOfCompany(
        DATA.categories && DATA.categories[0] && DATA.categories[0].name
      );
      setTypeOfCompanyId(
        DATA.categories && DATA.categories[0] && DATA.categories[0].id
      );
      togglePopupChooseType();
    },
    save = () => {
      updateCategory();
      togglePopupChooseType();
    },
    btnClick = (el) => {
      setTypeOfCompany(el.name);
      setTypeOfCompanyId(el.id);
    },
    getBtnStyle = (el) => {
      return el && el.name && typeOfCompany && typeOfCompany === el.name
        ? {
            background: defaultColor,
            color: "#fff",
          }
        : !typeOfCompany &&
          DATA.categories &&
          DATA.categories[0] &&
          el &&
          el.name &&
          DATA.categories[0].name === el.name
        ? {
            background: defaultColor,
            color: "#fff",
          }
        : {};
    },
    getBtnImg = (el) => {
      return typeOfCompany && typeOfCompany === el.name
        ? renderCustomTypeImg(el.slug, true)
        : !typeOfCompany &&
          DATA.categories &&
          DATA.categories[0] &&
          DATA.categories[0].name === el.name
        ? renderCustomTypeImg(el.slug, true)
        : hoveredBtn === el.name
        ? renderCustomTypeImg(el.slug, true)
        : renderCustomTypeImg(el.slug, false);
    };

  return (
    <Popup
      togglePopup={togglePopupChooseType}
      style={{
        width: "100%",
        height: "100%",
        padding: 0,
      }}
    >
      <AdminHeader close={cancel} save={save} />

      <AdminMenuTitleM>Тип заведения</AdminMenuTitleM>
      <div style={{ width: "100%", marginTop: "-8px" }}>
        {!!uniqueCompanyType &&
          uniqueCompanyType.map((el, i) => (
            <CategoryBtn
              key={i}
              style={getBtnStyle(el)}
              onClick={() => btnClick(el)}
              onMouseOver={() => setHoveredBtn(el.name)}
              onMouseOut={() => setHoveredBtn("")}
            >
              <Btn>
                <ImgWrap>{getBtnImg(el)}</ImgWrap>
                <Name>{el.name}</Name>
              </Btn>
            </CategoryBtn>
          ))}
      </div>
    </Popup>
  );
};

export default ChooseTypePopup;
