import React, { useState, useEffect } from "react";
import styled from "styled-components";
import VideoPlayer from "../../components/VideoPlayer";
import { Range } from "react-range";

import { defaultColor, PLACE_QUERY, RTSP_CONNECTION } from "../../constants";
import QUERY from "../../query";
import Loader from "../../components/loader/Loader";
import AdminHeader from "../../components/AdminHeader";

const Wrap = styled.div`
    position: relative;
    top: -46px;
  `,
  AdminMenuTitleM = styled.p`
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  `,
  StreamTypeWrap = styled.div`
    display: flex;
  `,
  RtspBtn = styled.div`
    border: 1px solid ${defaultColor};
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 50px;
    cursor: pointer;
    margin: 0 0 10px 10px;
    margin-right: 10px;
    background-color: ${({ active }) => (active ? defaultColor : "#fff")};
    color: ${({ active }) => (active ? "#fff" : defaultColor)};
    &:hover {
      color: ${({ active }) => (active ? "#fff" : "#000")};
      transition: 0.2s ease all;
    }
  `,
  RtmpBtn = styled(RtspBtn)`
    border: 1px solid ${defaultColor};
    color: ${defaultColor};
    margin-left: 0;
  `,
  ChooseStreamAddress = styled.div`
    padding: 0 10px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  `,
  CameraAddressWrapper = styled.div`
    flex: 1;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  `,
  StreamAddress = styled.input`
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: 2px solid #e5e5e5;
    padding: 0 7px;
    height: 50px;
    font-size: 16px;
    flex: 1;
    border-color: ${({ err }) => (err ? "red" : "#e5e5e5")};
  `,
  UserSelectDiv = styled.div`
    -o-user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
    user-select: text;
    margin-bottom: 15px;
  `,
  ChooseStreamAddressSaveBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${defaultColor};
    height: 50px;
    flex: 1;
    margin-top: 37px;
    border-radius: 5px;
    background-color: #f8104d;
    color: #fff;
    transition: 0.3s ease opacity;
    font-weight: bold;
    font-size: 16px;
    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 760px) {
      display: none;
    }
  `,
  CancelBtnProfile = styled.div`
    border: 1px solid ${defaultColor};
    color: ${defaultColor};
    font-weight: 700;
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 50px;
    cursor: pointer;
    margin: 37px 0 15px 0;
    &:hover {
      color: rgb(227, 42, 108);
      border: 2px solid rgb(227, 42, 108);
      transition: 0.3s ease all;
    }
    @media (max-width: 760px) {
      /* display: none; */
    }
  `,
  LoaderWrap = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: -200px;
  `,
  VideoWrapAdminMobile = styled.div`
    display: none;
    @media (max-width: 760px) {
      display: block;
      margin-top: 15px;
    }
  `;

const StreamMobile = ({
  closeAllSidebar,
  DATA,
  setDATA,
  refreshData,
  streamOpen,
}) => {
  const [streamType, setStreamType] = useState("rtsp"),
    [inputErrors, setInputErrors] = useState(false),
    [streamAddressData, setStreamAddressData] = useState(""),
    [streamPortData, setStreamPortData] = useState(""),
    [streamHostData, setStreamHostData] = useState(""),
    [streamPasswordData, setStreamPasswordData] = useState(""),
    [streamLoginData, setStreamLoginData] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [rtmpUrl, setRtmpUrl] = useState(""),
    [isStream, setIsStream] = useState(""),
    [vedeoLoading, setVedeoLoading] = useState(false),
    [volume, setVolume] = useState([1]),
    [isPause, setIsPause] = useState(false);

  let wasInterval = false;
  useEffect(() => {
    if (isStream && isStream.url && !wasInterval) {
      let count = 0;

      const urlTimer = setInterval(() => {
        count++;
        fetch(isStream.url)
          .then((res) => {
            if (res.ok) {
              clearInterval(urlTimer);
              count = 0;
              wasInterval = true;
              setVedeoLoading(false);
            } else {
              setVedeoLoading(true);
            }
          })
          .catch((err) => setVedeoLoading(true));

        if (count > 120) {
          clearInterval(urlTimer);
          setInputErrors(true);
        }
      }, 2500);
    }
  }, [isStream]);

  useEffect(() => {
    !streamOpen ? setIsPause(true) : setIsPause(false);
  }, [streamOpen]);

  useEffect(() => {
    if (DATA.streams && !DATA.streams[0]) {
      setIsStream("");
      clearData();
    }
    if (DATA.streams && DATA.streams[0]) {
      setIsStream(DATA.streams[0]);

      setVolume([DATA.streams[0].default_sound_volume]);

      if (DATA.streams[0].rtsp_connection) {
        const RTSP = DATA.streams[0].rtsp_connection;
        setStreamPortData(RTSP.port);
        setStreamHostData(RTSP.host);
        setStreamPasswordData(RTSP.password);
        setStreamLoginData(RTSP.login);
        setStreamAddressData(RTSP.address);
      } else {
        setStreamType("rtmp");
        setRtmpUrl(
          DATA.streams[0].url
            .replace("https://", "rtmp://")
            .replace("/camera.m3u8", "")
        );
      }
    }
  }, [DATA]);

  const rtsp_connection_string = `rtsp_connection: {
    create: {
      login : "${streamLoginData}"
      password : "${streamPasswordData}"
      host : "${streamHostData}"
      port : ${streamPortData}
      address : "${streamAddressData}"
    }
  }`,
    query_string_create =
      streamType === "rtsp"
        ? ` 
        name: "${DATA.name}"
        place:{ connect: "${DATA.id}" }
        type: ${"RTSP"} 
        default_sound_volume: ${100}
        ${rtsp_connection_string}
      `
        : `
      name: "${DATA.name}"
      place:{connect:"${DATA.id}"}
      type: ${"RTMP"} 
      default_sound_volume: ${100}
    `;

  const createStream = (name) => {
      console.log(query_string_create, "---query_string_create");
      if (localStorage.getItem("token")) {
        setIsLoading(true);
        QUERY(
          {
            query: `mutation {
            createStream( input:{ ${query_string_create} }) {
                id name url preview see_you_tomorrow ${PLACE_QUERY} 
                ${RTSP_CONNECTION}
                rtmp_url
            }
          }`,
          },
          localStorage.getItem("token")
        )
          .then((res) => res.json())
          .then((data) => {
            setIsLoading(false);

            data.data.createStream &&
              data.data.createStream.rtmp_url &&
              setRtmpUrl(
                data.data.createStream.rtmp_url.replace("https://", "rtmp://")
              );
            if (!data.errors) {
              refreshData();
              setDATA(data.data.createStream.place);
            } else {
              setInputErrors(true);
              console.log(data.errors, "CREATE STREAM ERRORS");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setInputErrors(true);
            console.log(err, "CREATE STREAM ERR");
          });
      }
    },
    save = () => {
      !isStream && createStream(streamAddressData);
    },
    clearData = () => {
      setStreamAddressData("");
      setStreamPortData("");
      setStreamHostData("");
      setStreamPasswordData("");
      setStreamLoginData("");
      setRtmpUrl("");
    },
    deleteStream = () => {
      if (localStorage.getItem("token") && isStream) {
        setIsLoading(true);
        QUERY(
          {
            query: `mutation { deleteStream(id:"${+isStream.id}") {
                  id name url see_you_tomorrow ${PLACE_QUERY} 
                  ${RTSP_CONNECTION}
                  rtmp_url
            }}`,
          },
          localStorage.getItem("token")
        )
          .then((res) => res.json())
          .then((data) => {
            setIsLoading(false);
            if (!data.errors) {
              refreshData();
              setDATA(data.data.deleteStream.place);
            } else {
              console.log(data.errors, "DELETE STREAM ERRORS");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err, "CREATE STREAM ERR");
          });
      }
    };

  const setVolumeOnServer = (data) => {
    setIsLoading(true);
    QUERY(
      {
        query: `mutation {
          updateStream (
            input:{
              id:"${+isStream.id}"
              default_sound_volume:${+data}
            }
          ) {
            default_sound_volume
          }
        }`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "disableStream ERR");
        setIsLoading(false);
      });
  };

  return (
    <Wrap>
      <AdminHeader save={save} close={closeAllSidebar} />
      <AdminMenuTitleM>Стрим</AdminMenuTitleM>
      {!isStream && (
        <StreamTypeWrap>
          <RtspBtn
            active={streamType === "rtsp"}
            onClick={() => setStreamType("rtsp")}
          >
            RTSP
          </RtspBtn>
          <RtmpBtn
            active={streamType === "rtmp"}
            onClick={() => setStreamType("rtmp")}
          >
            RTMP
          </RtmpBtn>
        </StreamTypeWrap>
      )}

      {isStream && (
        <>
          {!vedeoLoading && (
            <VideoWrapAdminMobile>
              <VideoPlayer
                preview={isStream.preview}
                src={isStream.url}
                volume={volume / 100}
                admin={true}
                isPause={isPause}
              />
            </VideoWrapAdminMobile>
          )}

          {vedeoLoading && (
            <div
              style={{
                padding: "15px",
                color: inputErrors ? "red" : "#000",
              }}
            >
              {inputErrors ? "Неверные данные" : "Идет настройка камеры..."}
            </div>
          )}

          <div style={{ margin: "10px 20px 40px 20px" }}>
            <p
              style={{
                marginBottom: "20px",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Настройка приватности:
            </p>
            <Range
              step={5}
              min={0}
              max={100}
              values={volume}
              onChange={(values) => setVolume(values)}
              onFinalChange={(values) => setVolumeOnServer(values[0])}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    backgroundColor: "#ccc",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    borderRadius: "5px",
                    height: "42px",
                    width: "42px",
                    backgroundColor: defaultColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    outline: "none",
                  }}
                >
                  <span
                    style={{ color: "#fff", fontSize: 18, fontWeight: "500" }}
                  >
                    {volume}
                  </span>
                </div>
              )}
            />
          </div>
        </>
      )}

      <ChooseStreamAddress>
        {streamType === "rtsp" && (
          <>
            <CameraAddressWrapper>
              <StreamAddress
                disabled={isStream}
                err={inputErrors}
                placeholder={"логин"}
                value={
                  streamLoginData ||
                  (isStream &&
                    isStream.rtsp_connection &&
                    isStream.rtsp_connection.login)
                }
                onInput={(e) => {
                  setInputErrors(false);
                  setStreamLoginData(e.target.value);
                }}
              />
            </CameraAddressWrapper>
            <CameraAddressWrapper>
              <StreamAddress
                disabled={isStream}
                err={inputErrors}
                placeholder={"пароль"}
                value={
                  streamPasswordData ||
                  (isStream &&
                    isStream.rtsp_connection &&
                    isStream.rtsp_connection.password)
                }
                onInput={(e) => {
                  setInputErrors(false);
                  setStreamPasswordData(e.target.value);
                }}
              />
            </CameraAddressWrapper>
            <CameraAddressWrapper>
              <StreamAddress
                disabled={isStream}
                err={inputErrors}
                placeholder={"хост"}
                value={
                  streamHostData ||
                  (isStream &&
                    isStream.rtsp_connection &&
                    isStream.rtsp_connection.host)
                }
                onInput={(e) => {
                  setInputErrors(false);
                  setStreamHostData(e.target.value);
                }}
              />
            </CameraAddressWrapper>
            <CameraAddressWrapper>
              <StreamAddress
                disabled={isStream}
                err={inputErrors}
                placeholder={"порт"}
                value={
                  streamPortData ||
                  (isStream &&
                    isStream.rtsp_connection &&
                    isStream.rtsp_connection.port)
                }
                onInput={(e) => {
                  setInputErrors(false);
                  setStreamPortData(e.target.value);
                }}
              />
            </CameraAddressWrapper>
            <CameraAddressWrapper>
              <StreamAddress
                disabled={isStream}
                err={inputErrors}
                placeholder={"адрес"}
                value={
                  streamAddressData ||
                  (isStream &&
                    isStream.rtsp_connection &&
                    isStream.rtsp_connection.address)
                }
                onInput={(e) => {
                  setInputErrors(false);
                  setStreamAddressData(e.target.value);
                }}
              />
            </CameraAddressWrapper>
          </>
        )}

        {streamType === "rtmp" && (
          <UserSelectDiv>
            {rtmpUrl
              ? rtmpUrl
              : 'Нажмите "Создать" для генерации ссылки видео-потока'}
          </UserSelectDiv>
        )}

        <div style={{ display: "flex" }}>
          {!isStream && (
            <ChooseStreamAddressSaveBtn
              style={{ marginRight: "10px" }}
              onClick={() => save()}
            >
              {streamType === "rtsp" ? "Сохранить" : "Создать"}
            </ChooseStreamAddressSaveBtn>
          )}
          {!isStream && streamType === "rtsp" && (
            <CancelBtnProfile onClick={() => clearData("")}>
              Отмена
            </CancelBtnProfile>
          )}
          {isStream && (
            <CancelBtnProfile onClick={() => deleteStream()}>
              Удалить
            </CancelBtnProfile>
          )}
        </div>
      </ChooseStreamAddress>

      {isLoading && (
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      )}
    </Wrap>
  );
};

export default StreamMobile;
