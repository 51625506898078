import React from "react";

import QUERY from "../../query";
import Map from "../../components/Map/Map";
import Popup from "../../components/Popup";

const MapPopup = ({ togglePopupGoogleMap, DATA, refreshData, city }) => {
  const chooseNewAddress = (streetName, latLng) => {
    if (localStorage.getItem("token")) {
      QUERY(
        {
          query: `mutation {
          updatePlace(
            input:{
              id: "${DATA.id}"
              city_id: ${city?.id}
              address: "${streetName}"
              lat: ${latLng.lat}
              lon: ${latLng.lng}
            }
          ){id address lat lon}
        }`,
        },
        localStorage.getItem("token")
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data.errors) {
            togglePopupGoogleMap();
            refreshData();
          }
        })
        .catch((err) => console.log(err, "ADMIN UPDATEPLACE ERR"));
    }
  };

  const initialCenterMap = DATA.lat
    ? {
        lat: Number(DATA.lat),
        lng: Number(DATA.lon),
      }
    : null;

  const initialCenterMapByCity = city?.lat
  ? {
      lat: Number(city?.lat),
      lng: Number(city?.lon),
    }
  : null;

  return (
    <Popup
      togglePopup={togglePopupGoogleMap}
      style={{
        width: "100%",
        height: "100%",
        padding: 0,
      }}
    >
      <Map
        initialCenterMap={initialCenterMapByCity || initialCenterMap}
        togglePopupGoogleMap={togglePopupGoogleMap}
        chooseNewAddress={chooseNewAddress}
        isNewAddress
      />
    </Popup>
  );
};

export default MapPopup;
