import React from "react";
import PropTypes from "prop-types";
import ObjectPath from "object-path";

import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./video.css";

const DEFAULT_STATE = {
  options: {
    controls: true,
    techOrder: ["html5"],
    autoplay: false,
    muted: false,
    loop: false,
    preload: "none",
    aspectRatio: "16:9",
    fluid: true,
    poster: "",
    sources: [],
    subtitles: [],
    defaultSubtitle: "",
  },
  playInline: true,
  crossOrigin: "",
  percentageLoader: "",
};

const DEFAULT_EVENTS = [
  "loadeddata",
  "canplay",
  "canplaythrough",
  "play",
  "pause",
  "waiting",
  "playing",
  "ended",
  "error",
];

class Video extends React.Component {
  static defaultProps = {
    options: {},
  };

  video = null;
  state = DEFAULT_STATE;

  componentDidMount() {
    window.addEventListener("resize", (event) => {
      if (this.player && isSource) {
        try {
          this.player.pause();
        } catch (err) {
          console.log(err, "--PAUSE ERR 1");
        }
      }
    });

    const sources = ObjectPath(this.props).get("options.sources") || [],
      isSource = sources.length;
    if (!this.player && isSource) this.initialize();
  }

  componentWillReceiveProps() {}

  componentDidUpdate(prevProps) {
    this.player &&
      this.props.options !== prevProps.options &&
      !this.props.admin &&
      this.resetUrl();

    const { volume, isPause } = this.props,
      preview = document.querySelectorAll(".vjs-poster");

    if (this.player && (volume || volume === 0)) {
      this.video.style.filter = `blur(${volume * 5}px)`;

      if (preview) {
        preview.forEach((el) => (el.style.filter = `blur(${volume * 5}px)`));
        try {
          isPause && this.player.pause();
        } catch (err) {
          console.log(err, "--PAUSE ERR");
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.player) this.player.dispose();
  }

  resetUrl() {
    const sources = ObjectPath(this.props).get("options.sources") || [];

    sources[0] && this.player.src(sources[0].src);
    this.player.poster(this.props.options.poster);
  }

  initialize() {
    if (this.state.playInline) {
      this.video.setAttribute("webkit-playsinline", true);
      this.video.setAttribute("playsInline", true);
      this.video.setAttribute("x5-playsinline", true);
      this.video.setAttribute("x5-video-player-type", "h5");
      this.video.setAttribute("x5-video-player-fullscreen", false);
    }

    if (this.state.crossOrigin !== "") {
      this.video.crossOrigin = this.state.crossOrigin;
      this.video.setAttribute("crossOrigin", this.state.crossOrigin);
    }

    if (this.state.options.plugins) {
      this.setState((prevState) => {
        delete prevState.plugins.__ob__;
        return prevState;
      });
    }

    this.setState(
      {
        options: {
          ...this.state.options,
          ...this.props.options,
        },
      },
      () => {
        const context = this;

        this.player = videojs(this.video, this.state.options, function () {
          const events = DEFAULT_EVENTS.concat(context.props.events),
            onEdEvents = {};

          for (let i = 0; i < events.length; i += 1) {
            if (
              typeof events[i] === "string" &&
              onEdEvents[events[i]] === undefined
            ) {
              ((event) => {
                onEdEvents[event] = null;
                this.on(event, () => {
                  if (typeof context.props[event] === "function")
                    context.props[event](true);
                });
              })(events[i]);
            }
          }

          this.on("timeupdate", function () {
            if (typeof context.props.timeupdate === "function")
              context.props.timeupdate(this.currentTime());
          });
        });

        const { volume, admin } = this.props,
          { nextElementSibling } = this.video;

        if (this.player && (volume || volume === 0)) {
          this.video.style.filter = `blur(${volume * 5}px)`;
          this.player.controlBar.volumePanel.removeChild("VolumeControl");

          if (nextElementSibling) {
            nextElementSibling.style.filter = `blur(${volume * 5}px)`;
          }
        }

        //ПРОЕНТНЫЙ ЛОДЕ НА ВИДЕО
        // const outerThis = this;
        // document.querySelectorAll(".vjs-loading-spinner").forEach((el) => {
        //   el.innerHTML = `<span style="color:#fff; font-wewight:600; position:absolute; top:12px; left:12px"> 0%</span>`;
        // });
        // let origOpen = XMLHttpRequest.prototype.open;
        // console.log(XMLHttpRequest, "-XMLHttpRequest outer");
        // XMLHttpRequest.prototype.open = function (e) {
        //   console.log("-XMLHttpRequest inner");
        //   this.addEventListener("progress", function (oEvent) {
        //     console.log(oEvent, "-addEventListener 1");
        //     if (oEvent.target.uri && oEvent.target.uri.indexOf(".ts") !== -1) {
        //       if (oEvent.lengthComputable) {
        //         const percentToFixed = (
        //           (oEvent.loaded / oEvent.total) *
        //           100
        //         ).toFixed();
        //         outerThis.setState({
        //           percentageLoader: percentToFixed,
        //         });
        //         document
        //           .querySelectorAll(".vjs-loading-spinner")
        //           .forEach((el) => {
        //             let left = 12;
        //             if (percentToFixed < 10) {
        //               left = 12;
        //             } else if (percentToFixed == 100) {
        //               left = 7;
        //             } else {
        //               left = 10;
        //             }
        //             el.innerHTML = `<span style="color:#fff; font-wewight:600; position:absolute; top:12px; left:${left}px">${
        //               ((oEvent.loaded / oEvent.total) * 100).toFixed() + "%"
        //             }</span>`;
        //           });
        //       }
        //     } else {
        //       console.log(oEvent, "-addEventListener 2");
        //       if (oEvent.loaded && oEvent.total) {
        //         const percentToFixed = (
        //           (oEvent.loaded / oEvent.total) *
        //           100
        //         ).toFixed();
        //         outerThis.setState({
        //           percentageLoader: percentToFixed,
        //         });
        //         document
        //           .querySelectorAll(".vjs-loading-spinner")
        //           .forEach((el) => {
        //             let left = 12;
        //             if (percentToFixed < 10) {
        //               left = 12;
        //             } else if (percentToFixed == 100) {
        //               left = 7;
        //             } else {
        //               left = 10;
        //             }
        //             el.innerHTML = `<span style="color:#fff; font-wewight:600; position:absolute; top:12px; left:${left}px">${
        //               ((oEvent.loaded / oEvent.total) * 100).toFixed() + "%"
        //             }</span>`;
        //           });
        //       }
        //     }
        //   });
        //   origOpen.apply(this, arguments);
        // };
      }
    );
  }

  render() {
    const { onRef } = this.props;

    return (
      <div className="react-awesome-player-container">
        <video
          className="video-js react-awesome-player"
          ref={(video) => {
            this.video = video;
            onRef && onRef(video);
          }}
        />
      </div>
    );
  }
}

Video.propTypes = {
  options: PropTypes.object,
  defaultSubtitle: PropTypes.string,
  events: PropTypes.array,
  onRef: PropTypes.func,
  loadeddata: PropTypes.func,
  canplay: PropTypes.func,
  canplaythrough: PropTypes.func,
  play: PropTypes.func,
  pause: PropTypes.func,
  waiting: PropTypes.func,
  playing: PropTypes.func,
  ended: PropTypes.func,
  error: PropTypes.func,
  timeupdate: PropTypes.func,
};

export default Video;
