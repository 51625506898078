import React, { useState, useEffect, useRef } from "react";
import Cropper from "cropperjs";
import Dropzone from "react-dropzone";
import { Redirect, Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import autosize from "autosize";
import axios from "axios";
import Switch from "react-switch";
import { matchSorter } from "match-sorter";
import { Autocomplete, TextField } from "@mui/material";

import Header from "../../components/Header";
import SlideSideMenu from "../../components/SlideSideMenu";
import Loader from "../../components/loader/Loader";
import QUERY from "../../query";
import {
  EN_SHORT_DAY_OF_WEEK,
  EN_SHORT_TO_RU_SHORT,
  SHORT_DAY_OF_WEEK,
  EN_SHORT_TO_NUMBER,
  defaultColor,
  EN_SHORT_PREV_DAY,
  ADMIN_PLACE_BY_ALIAS,
} from "../../constants";
import { queryPath } from "../../settings";
import { TypeIcon } from "../../images";

import SideBar from "./Sidebar";
import Stream from "./Stream";
import StreamMobile from "./StreamMobile";

import "./admin.css";
import "./sidebar.css";
import "cropperjs/dist/cropper.min.css";
import "./imagecropper.css";
import DatePickerPopup from "./DatePickerPopup";
import MapPopup from "./MapPopup";
import DescriptionPopup from "./DescriptionPopup";
import UploadFilePopup from "./UploadFilePopup";
import ChooseTypePopup from "./ChooseTypePopup";
import LeftMenu from "./LeftMenu";
import AdminHeader from "../../components/AdminHeader";

const AdminStyle = styled.div`
    position: relative;
    background: #edeef0;
    height: 100%;
    @media (max-width: 760px) {
      background: #fff;
      height: 100vh;
    }
  `,
  DisableStreamD = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-left: 15px;
    @media (max-width: 760px) {
      margin-top: -30px;
    }
  `,
  DisableStreamToNexDayD = styled.span`
    font-size: 14px;
    color: #000;
    font-weight: 600;
    margin-right: 25px;
  `,
  AdminWrapper = styled.div`
    display: flex;
    width: 1000px;
    margin: 0 auto;
    background: #edeef0;
    @media (max-width: 760px) {
      max-width: 100%;
      background: #fff;
    }
  `,
  LeftAdminMenuD = styled.div`
    flex: 1.5;
    padding: 30px 10px 10px 0;
    @media (max-width: 760px) {
      display: none;
    }
  `,
  LeftAdminMenuInnerD = styled.div`
    position: fixed;
  `,
  AdminContentD = styled.div`
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    background-color: #fff;
    display: flex;
    flex: 4;
    margin-top: 35px;
    padding: 35px 70px 45px 45px;
    flex-direction: column;
    margin-bottom: 35px;
    @media (max-width: 760px) {
      display: none;
    }
  `,
  PhotoDescWrapper = styled.div`
    display: flex;
    margin-bottom: 15px;
  `,
  CropWrapperD = styled.div`
    position: relative;
    background: #fff;
  `,
  PreviewPhotoTextD = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
  `,
  PreviewPhotoD = styled.div`
    display: flex;
    height: 150px;
    width: 200px;
    background-color: #fff;
    border-radius: 10px;
    color: #aeaeae;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &:hover ${PreviewPhotoTextD} {
      color: ${defaultColor};
      transition: 0.3s ease all;
    }
  `,
  ImgContainerD = styled.div`
    width: 200px;
    height: 150px;
    overflow: hidden;
  `,
  CanvasImageD = styled.canvas`
    display: none;
  `,
  LengthofDescriptionD = styled.span`
    margin-top: 10px;
    padding-left: 10px;
    font-weight: 500;
    font-size: 11px;
    text-align: end;
    color: ${(props) =>
      props.descOfCompany.length === props.descOfCompanyLimit
        ? "red"
        : "green"};
    @media (max-width: 760px) {
      width: 100%;
      font-size: 10px;
    }
  `,
  TimeBlock = styled.div`
    width: 240px;
    height: 50px;
    border: 2px solid #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #4f4f4f;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease all;
    font-weight: 600;
    opacity: ${(props) => (props.opacity ? 0.3 : 1)};
    &:hover {
      border: 2px solid ${defaultColor};
      color: ${defaultColor};
    }
  `,
  TimeWrapper = styled.div`
    display: flex;
    justify-content: space-around;
  `,
  TimeText = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: grey;
  `,
  UploadImgStyle = styled.img`
    border-radius: 10px !important;
    height: 150px;
    width: 202px;
    border: 2px solid #fff;
    box-sizing: content-box;
    &:hover {
      transition: 0.1s ease all;
      border: 2px solid #f8104d;
      box-sizing: content-box;
    }
  `,
  PreviewPhotoM = styled.div`
    display: flex;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    color: #aeaeae;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s ease all;
    align-self: center;
    margin-top: 15px;
    &:hover {
      border: 2px solid ${defaultColor};
      color: ${defaultColor};
    }
  `,
  AdminMenuTitleM = styled.p`
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  `,
  Inputs = styled.input`
    user-select: initial;
    font-weight: 500;
    transition: 0.3s ease all;
    font-size: 16px;
    display: flex;
    height: 50px;
    outline: none;
    flex: 2;
    color: #4f4f4f;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-color: ${({ err }) => (err ? "red" : "#e5e5e5")};
    box-sizing: border-box;
    border-radius: 7px;
    /* margin: 7px 0px; */
    padding: 0 10px;
  `,
  InputsAddress = styled.div`
    user-select: initial;
    font-weight: 500;
    transition: 0.3s ease all;
    font-size: 16px;
    display: flex;
    align-items: center;
    height: 50px;
    outline: none;
    cursor: pointer;
    flex: 2;
    color: #4f4f4f;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 0 10px;
    &:hover {
      border: 1px solid ${defaultColor};
    }
  `,
  AutocompleteWrapper = styled.div`
    width: 100%;
    max-width: 76%;
  `,
  AutocompleteWrapperMobile = styled.div`
    width: 100%;
    max-width: 100%;
    margin-top: 6px;
  `,
  InputWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 0;
  `,
  BlockName = styled.div`
    font-weight: 600;
    font-size: 16px;
    color: #000;
    width: 150px;
  `,
  CategoryBtnsWRap = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
  `,
  CategoryImgTextWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  `,
  KlubWrap = styled.div`
    margin-bottom: ${(props) => (props.slug ? "5px" : 0)};
    margin-right: ${(props) => (props.slug ? "2px" : "4px")};
  `,
  TextareaDescription = styled.textarea`
    border-radius: 5px;
    outline: none;
    overflow: hidden;
    resize: none;
    min-height: 150px;
    font-size: 16px;
    font-weight: 500;
    color: #4f4f4f;
    flex: 1;
    opacity: none;
    border: 1px solid #d3d3d3;
    padding: 12px;
    box-sizing: border-box;
    line-height: 24px;
    letter-spacing: 0.5px;
  `,
  TextareaDescriptionWrap = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column !important;
  `,
  CategoryBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    flex: 1;
    width: 50%;
    flex-wrap: wrap;
    padding: 0px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 5px 0;
  `,
  AddressBtn = styled(CategoryBtn)`
    width: 100%;
    justify-content: flex-start;
    padding: 0 15px;
    text-transform: none;
    font-weight: 400;
    font-size: 16px;
  `,
  DescriptionBtn = styled(AddressBtn)`
    margin-bottom: 20px;
  `,
  UploadImgStyleMobile = styled.img`
    border-radius: 10px;
    height: 120px;
    width: 100%;
    &:hover {
      transition: 0.1s ease all;
      border: 2px solid #f8104d;
      box-sizing: content-box;
    }
    @media (max-width: 760px) {
      height: auto;
    }
  `,
  InputMobile = styled.input`
    display: flex;
    font-size: 16px;
    flex: 1;
    height: 50px;
    width: 100%;
    padding: 0 15px;
    outline: none;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 5px 0;
  `,
  ChangeDeleteWrap = styled.div`
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    color: ${defaultColor};
  `,
  MenuBlock = styled.div`
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.05em;
    padding: 10px 17px;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease background;
    font-weight: 500;
    &:hover {
      background-color: #eee;
    }
  `,
  MenuBlockWrapp = styled.div`
    height: 60px;
    padding: 10px;
  `,
  RotateArrow = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 17px;
    top: 10px;
    &:before {
      content: "";
      width: 12px;
      height: 2px;
      position: absolute;
      right: 0;
      top: 6px;
      transform: rotate(45deg);
      background-color: #bdbdbd;
    }
    &:after {
      content: "";
      width: 12px;
      height: 2px;
      position: absolute;
      top: 14px;
      right: 0px;
      transform: rotate(135deg);
      background-color: #bdbdbd;
    }
  `,
  UploadFile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 0 15px;
  `,
  DayOfWeek = styled.td`
    text-transform: uppercase;
    font-size: 14px;
    width: 80px;
    color: ${(props) => (props.isCurrent ? defaultColor : "#000")};
    font-weight: ${(props) => (props.isCurrent ? 700 : 400)};
  `,
  GraphTime = styled.td`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.opacity ? 0.1 : 1)};
    font-weight: ${(props) => (props.opacity || props.bold ? 700 : 400)};
  `,
  GraphTr = styled.tr`
    height: 50px;
    display: flex;
    flex: 1;
    width: 100vw;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid #e5e5e5;
    border-top: ${(props) => (props.borderTop ? "1px solid #e5e5e5" : "none")};
  `,
  Label = styled.tr`
    position: relative;
    top: 13px;
    left: 10px;
    padding: 0 5px;
    font-size: 12px;
    background: #fff;
    z-index: 9;
  `,
  CurrentDay = styled.td`
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => (props.isCurrent ? defaultColor : "#000")};
    margin-top: 12px !important;
    text-transform: capitalize !important;
  `;

const Admin = (props) => {
  const [showSlideSideMenu, setShowSlideSideMenu] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [DATA, setDATA] = useState([]);
  const [isStream, setIsStream] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(1);
  const [showPopupDatePicker, setShowPopapDatePicker] = useState(false);
  const [showPopupGoogleMap, setShowPopapGoogleMap] = useState(false);
  const [showPopupDescription, setShowPopapDescription] = useState(false);
  const [showPopupChooseType, setShowPopapChooseType] = useState(false);
  const [showPopupUploadFile, setShowPopapUploadFile] = useState(false);
  const [startRealTimeInPicker, setStartRealTimeInPicker] = useState();
  const [endRealTimeInPicker, setEndRealTimeInPicker] = useState();
  const [isEmptyTime, setIsEmptyTime] = useState(true);
  const [enumWeekName, setEnumWeekName] = useState("");
  const [isSetWorkTimeDPick, setIsSetWorkTimeDPick] = useState(false);
  const [clickedTime, setClickedTime] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uniqueCompanyType, setUniqueCompanyType] = useState([]);
  const [hoveredBtn, setHoveredBtn] = useState("");
  const [nameOfCompany, setNameOfCompany] = useState("");
  const [aliasOfCompany, setAliasOfCompany] = useState("");
  const [typeOfCompany, setTypeOfCompany] = useState("");
  const [typeOfCompanyId, setTypeOfCompanyId] = useState("");
  const [descOfCompany, setDescOfCompany] = useState("");
  const [titleInPicker, setTitleInPicker] = useState("");
  const [isSuccessSave, setIsSuccessSave] = useState(false);
  const [leftMenuSettings, setLeftMenuSettings] = useState(
    sessionStorage.getItem("opened_li_admin")
      ? JSON.parse(sessionStorage.getItem("opened_li_admin"))
      : [
          {
            name: "ПРОФИЛЬ ЗАВЕДЕНИЯ",
            img: "barIcon",
            altImg: "profile",
            clicked: true,
            class: "barIcon",
            enter: false,
          },
          {
            name: "ГРАФИК РАБОТЫ",
            img: "clocklite",
            altImg: "work",
            clicked: false,
            class: "clockliteClass",
            enter: false,
          },
          {
            name: "ГРАФИК ТРАНСЛЯЦИЙ",
            img: "video-camera",
            altImg: "camera",
            clicked: false,
            class: "videoCameraClass",
            enter: false,
          },
          {
            name: "СТРИМ",
            img: "streaming",
            altImg: "stream",
            clicked: false,
            class: "streamingClass",
            enter: false,
          },
        ]
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imgSrc, setImgSrc] = useState(null);
  const [imageDestination, setImageDestination] = useState("");
  const [validationErr, setValidationErr] = useState({});
  const [streamOpen, setStreamOpen] = useState(false);
  const [profileOpen, setPprofileOpen] = useState(false);
  const [workScheduleOpen, setWorkScheduleOpen] = useState(false);
  const [streamScheduleOpen, setStreamScheduleOpen] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(null);
  const [currentSeansStreamDay, setCurrentSeansStreamDay] = useState(null);
  const [currentSeansStreamTime, setCurrentSeansStreamTime] = useState(null);
  const [currentSeansWarkDay, setCurrentSeansWarkDay] = useState(null);

  const descOfCompanyLimit = 180,
    numberDayNow = new Date().getDay() === 0 ? 6 : new Date().getDay() - 1,
    numberDayPrev = numberDayNow === 0 ? 6 : numberDayNow - 1,
    imageMaxSize = 10000000000,
    acceptedFileTypes =
      "image/x-png, image/png, image/jpg, image/jpeg, image/gif",
    acceptedFileTypesArray = acceptedFileTypes
      .split(",")
      .map((item) => item.trim());

  const imageElementRef = useRef(null);

  !windowWidth && setWindowWidth(window.innerWidth);

  const refreshData = () => {
    QUERY({
      query: `query {
                placeByAlias (alias:"${props.match.params.id}") {${ADMIN_PLACE_BY_ALIAS}}
                cities {id name lat lon}
              }`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.errors) {
          let cityArray = data?.data?.cities.map((c) => {
            return {
              id: c.id,
              label: c.name,
            };
          });
          setIsLoading(false);
          setDATA(data.data.placeByAlias);
          setCitiesList(cityArray);
        }
      })
      .catch((err) => {
        setCitiesList([]);
      });
  };

  const setAsDayOf = (id) => {
    if (localStorage.getItem("token")) {
      QUERY(
        {
          query: `mutation {
            deleteSchedule(id:"${
              id || clickedTime.id
            }"){id day start_time end_time}
          }`,
        },
        localStorage.getItem("token")
      )
        .then((res) => res.json())
        .then((data) => {
          !data.errors
            ? refreshData()
            : console.log(data.errors, "DELETESCHEDULE ERRORS");
        })
        .catch((err) => {});
    }
  };

  const hideSideMenu = () => {
    setShowSlideSideMenu(false);
    document.body.style.overflow = "visible";
    setIsShowMenu(false);
  };

  const showSideMenu = () => {
    setShowSlideSideMenu(true);
    document.body.style.overflow = "hidden";
    setIsShowMenu(true);
  };

  const togglePopupDatePicker = (whatPickerWillShow) => {
    setTitleInPicker(whatPickerWillShow);
    setShowPopapDatePicker(!showPopupDatePicker);
    setIsStream(false);
  };

  const togglePopupGoogleMap = () => setShowPopapGoogleMap(!showPopupGoogleMap);

  const togglePopupDescription = () =>
    setShowPopapDescription(!showPopupDescription);

  const togglePopupChooseType = () =>
    setShowPopapChooseType(!showPopupChooseType);

  const togglePopupUploadFile = () =>
    setShowPopapUploadFile(!showPopupUploadFile);

  const SetNewTimeObject = (data) => {
    const timeObject = {};
    EN_SHORT_DAY_OF_WEEK.forEach((e, i) => {
      data.forEach((el, ind) => {
        if (!timeObject[e.day]) timeObject[e.day] = "Пусто";
        if (el.section || el.day) timeObject[el.section || el.day] = el;
      });
    });
    return timeObject;
  };

  const updatePlaceData = () => {
    if (localStorage.getItem("token")) {
      QUERY(
        {
          query: `
            mutation {
              updatePlace(
                input:{
                  id: "${DATA.id}"
                  city_id: ${selectedCityId}
                  name: "${nameOfCompany || DATA.name}"
                  alias: "${aliasOfCompany || DATA.alias}"              
                  ${
                    DATA.categories &&
                    DATA.categories[0] &&
                    typeOfCompanyId &&
                    +typeOfCompanyId !== +DATA.categories[0].id
                      ? `categories:{
                          disconnect:"${DATA.categories[0].id}"
                          connect:"${typeOfCompanyId}"
                      }`
                      : typeOfCompanyId &&
                        DATA.categories &&
                        !DATA.categories[0]
                      ? `categories:{
                          connect:"${typeOfCompanyId}"
                      }`
                      : `categories:{ }`
                  }
                }
              ){id}
            }
          `,
        },
        localStorage.getItem("token")
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data.errors) {
            setIsSuccessSave(true);
            setTimeout(() => {
              setIsSuccessSave(false);
            }, 2000);
          }
        })
        .catch((err) => {});
    }
  };

  const updateOrRemoveUploadImage = (profile_image) => {
    QUERY(
      {
        query: `mutation {
          updatePlace(
            input:{
                id:"${DATA.id}"
                ${
                  profile_image
                    ? `profile_image: "${profile_image}"`
                    : `profile_image: ${null}`
                }             
            }
          ){id profile_image}
        }`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.errors) {
          handeleClearToDefault();
          refreshData();
        }
      })
      .catch((err) => {});
  };

  const uploadImageTranscode = (blob) => {
    if (localStorage.getItem("token")) {
      const query = `
          mutation ($file: Upload!) {
            placeImage(file: $file)
          }
        `,
        data = {
          file: null,
        },
        operations = JSON.stringify({
          query,
          variables: {
            data,
          },
        });

      let formData = new FormData();
      formData.append("operations", operations);
      const map = {
        0: ["variables.file"],
      };
      formData.append("map", JSON.stringify(map));
      formData.append("0", blob || imageDestination);
      axios({
        url: `${queryPath}/graphql`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : "",
        },
        data: formData,
      })
        .then((res) => {
          res.data.data.placeImage &&
            updateOrRemoveUploadImage(res.data.data.placeImage);
        })
        .catch((err) => {});
    }
  };

  const handeleClearToDefault = () => setImgSrc(null);

  const verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0],
        currentFileType = currentFile.type,
        currentFileSize = currentFile.size;

      if (currentFileSize > imageMaxSize) {
        alert("слишком большой размер файла");
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        alert("поддерживаются только изображения");
        return false;
      }
      return true;
    }
  };

  const handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) verifyFile(rejectedFiles);
    if (files && files.length > 0) {
      const isVerified = verifyFile(files);
      if (isVerified) {
        const currentFile = files[0],
          myFileItemReader = new FileReader();

        myFileItemReader.onloadend = function (theFile) {
          const image = new Image();
          image.src = theFile.target.result;
        };

        myFileItemReader.addEventListener(
          "load",
          (theFile) => {
            const myResult = myFileItemReader.result;
            setImgSrc("");
            setImgSrc(myResult);
          },
          false
        );
        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  const isWorkTimeOrDayOff = (oneDay, isStartTime) => {
    if (oneDay && oneDay.id) {
      const startTime =
          +oneDay.start_time.split(":")[0] * 3600 +
          +oneDay.start_time.split(":")[1] * 60,
        curTime = new Date().getHours() * 3600 + new Date().getMinutes() * 60;
      if (
        oneDay.start_time === oneDay.end_time &&
        oneDay.start_time === "00:00"
      ) {
        return isStartTime ? curTime < startTime : "Круглосуточно";
      }
      if (
        oneDay.start_time.split(":")[0] * 3600 +
          oneDay.start_time.split(":")[1] * 60 <=
        oneDay.end_time.split(":")[0] * 3600 +
          oneDay.end_time.split(":")[1] * 60
      ) {
        if (isStartTime) {
          return curTime < startTime;
        } else {
          return (
            <div>
              <TimeWrapper>
                <TimeText>{EN_SHORT_TO_RU_SHORT[oneDay.day]}</TimeText>
                <TimeText>{EN_SHORT_TO_RU_SHORT[oneDay.day]}</TimeText>
              </TimeWrapper>
              <p>
                {oneDay.start_time.replace(":", ".")}
                {" - "}
                {oneDay.end_time.replace(":", ".")}{" "}
              </p>
            </div>
          );
        }
      } else {
        if (isStartTime) {
          return curTime < startTime;
        } else {
          return (
            <div>
              <TimeWrapper>
                <TimeText>{EN_SHORT_TO_RU_SHORT[oneDay.section]}</TimeText>
                <TimeText>{SHORT_DAY_OF_WEEK[tomorrowFromDay(EN_SHORT_TO_NUMBER[oneDay.section])]}</TimeText>
              </TimeWrapper>
              <p>
                {oneDay.start_time.replace(":", ".")}
                {" - "}
                {oneDay.end_time.replace(":", ".")}
              </p>
            </div>
          );
        }
      }
    } else {
      return "Выходной";
    }
  };

  const renderCustomTypeImg = (slug, active) =>
    TypeIcon(slug, active ? "#fff" : "#000", 27, 27);

  const animateProps = useSpring({
    right: isShowMenu ? 200 : 0,
    config: { duration: 200 },
  });

  const animateSavedProps = useSpring({
    bottom: isSuccessSave ? 20 : -100,
    config: { duration: 200 },
  });

  const tomorrowFromDay = (day) => {
    if (day === 6) {
      return 0;
    } else {
      return day + 1;
    }
  };

  const checkValidationError = () => {
    if (nameOfCompany.length < 1) {
      setValidationErr({
        nameOfCompany: "обязательно для заполнения",
      });
      return false;
    } else if (
      (aliasOfCompany && !aliasOfCompany.match("^[a-zA-Z0-9]+$")) ||
      aliasOfCompany.length < 1
    ) {
      setValidationErr({
        aliasOfCompany: "только латиница и цифры и поле не пустое",
      });
      return false;
    } else {
      setValidationErr({});
      return true;
    }
  };

  const closeAllSidebar = () => {
    setStreamOpen(false);
    setPprofileOpen(false);
    setWorkScheduleOpen(false);
    setStreamScheduleOpen(false);
  };

  const hide = (e) => {
    if (e.target.className !== "SlideSideMenu" && showSlideSideMenu)
      hideSideMenu();
  };

  const categorySyle = (el) => {
    if (el && el.name && typeOfCompany && typeOfCompany === el.name) {
      return { background: defaultColor, color: "#fff" };
    } else if (
      !typeOfCompany &&
      DATA.categories &&
      DATA.categories[0] &&
      el &&
      el.name &&
      DATA.categories[0].name === el.name
    ) {
      return {
        background: defaultColor,
        color: "#fff",
      };
    } else {
      return {};
    }
  };

  const disableStream = (data) => {
    const redact = (num) => {
      if (num > 9) return num;
      return "0" + num;
    };

    const currentDay = EN_SHORT_DAY_OF_WEEK[numberDayNow].day,
      prevDay = EN_SHORT_DAY_OF_WEEK[numberDayPrev].day,
      curTime = new Date().getHours() * 3600 + new Date().getMinutes() * 60;

    let dateNew, timeNew;

    var template =
      new Date().getDay() === 1
        ? ["Sun", "Tue", "Wed", "Thu", "Fri", "Sat", "Mon"]
        : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const sortFunc = (a, b) => {
      return template.indexOf(a.day) - template.indexOf(b.day);
    };

    const sch = DATA.streams[0].schedules.sort(sortFunc);

    try {
      sch.forEach((el) => {
        const end = el.end_time.split(":"),
          start = el.start_time.split(":");

        if (
          el.day === prevDay &&
          +end[0] * 3600 + +end[1] * 60 <= +start[0] * 3600 + +start[1] * 60 &&
          curTime < +end[0] * 3600 + +end[1] * 60
        ) {
          const today = new Date().toLocaleString().split(","),
            date = today[0],
            dateArr = date.includes("/") ? date.split("/") : date.split(".");

          dateNew = data
            ? `${dateArr[2]}-${redact(+new Date().getMonth() + 1)}-${redact(
                +new Date().getDate()
              )}`
            : `${dateArr[2] - 1}-${redact(+new Date().getMonth() + 1)}-${redact(
                +new Date().getDate()
              )}`;
          timeNew = el.end_time;
          console.log(dateNew, "==dateNew 1");
          console.log(timeNew, "==timeNew 1");
          throw "myException";
        } else if (
          el.day === currentDay &&
          +end[0] * 3600 + +end[1] * 60 > +start[0] * 3600 + +start[1] * 60
        ) {
          const today = new Date().toLocaleString().split(","),
            date = today[0],
            dateArr = date.includes("/") ? date.split("/") : date.split(".");

          dateNew = data
            ? `${dateArr[2]}-${redact(+new Date().getMonth() + 1)}-${redact(
                +new Date().getDate()
              )}`
            : `${dateArr[2] - 1}-${redact(+new Date().getMonth() + 1)}-${redact(
                +new Date().getDate()
              )}`;

          timeNew = el.end_time;
          throw "myException";
        } else if (
          el.day === currentDay &&
          +end[0] * 3600 + +end[1] * 60 <= +start[0] * 3600 + +start[1] * 60
        ) {
          const today = new Date();
          const tom = new Date(today);
          tom.setDate(tom.getDate() + 1);

          const tomorrow = tom.toLocaleString().split(","),
            date = tomorrow[0],
            dateArr = date.includes("/") ? date.split("/") : date.split(".");

          dateNew = data
            ? `${dateArr[2]}-${redact(+tom.getMonth() + 1)}-${redact(
                +tom.getDate()
              )}`
            : `${dateArr[2] - 1}-${redact(+tom.getMonth() + 1)}-${redact(
                +tom.getDate()
              )}`;
          timeNew = el.end_time;

          throw "myException";
        }
      });
    } catch {}

    if (localStorage.getItem("token")) {
      QUERY(
        {
          query: `mutation {
              updateStream (
                input:{
                  id:"${+DATA.streams[0].id}"
                  see_you_tomorrow: ${data}
                }
              ) { id name url is_see_you_tomorrow see_you_tomorrow }
            }`,
        },
        localStorage.getItem("token")
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "updateStream data");
        })
        .catch((err) => {
          console.log(err, "updateStream err");
        });
    }
  };

  const getDayNum = (el, oneDay, i) => {
    // if (oneDay && oneDay.start_time) {
    //   const start_time = oneDay.start_time.split(":"),
    //     end_time = oneDay.end_time.split(":");

    //   if (
    //     start_time[0] * 3600 + start_time[1] * 60 <=
    //     end_time[0] * 3600 + end_time[1] * 60
    //   ) {
    //     return EN_SHORT_TO_RU_SHORT[el.day];
    //   } else {
    //     return `${EN_SHORT_TO_RU_SHORT[el.day]}-${
    //       SHORT_DAY_OF_WEEK[tomorrowFromDay(i)]
    //     }`;
    //   }
    // } else {
    //   return EN_SHORT_TO_RU_SHORT[el.day];
    // }
    return EN_SHORT_TO_RU_SHORT[el.day];
  };

  const clickTranslationTimeBlockDesc = (el, oneDay, i) => {
    if (!DATA.streams[0]) {
      alert("Стрим еще не создан");
    } else {
      if (currentSeansStreamDay === i && switchChecked) {
        alert("Стрим приостановлен");
      } else {
        setEnumWeekName(el.day);
        if (oneDay && oneDay.id) {
          setIsEmptyTime(false);
          setStartRealTimeInPicker(oneDay.start_time);
          setEndRealTimeInPicker(oneDay.end_time);
          togglePopupDatePicker();
          setIsStream(true);
          setClickedTime(oneDay);
          setIsSetWorkTimeDPick(false);
        } else {
          setIsEmptyTime(true);
          setStartRealTimeInPicker("00:00");
          setEndRealTimeInPicker("00:00");
          setIsStream(true);
          togglePopupDatePicker();
          setIsSetWorkTimeDPick(false);
        }
      }
    }
  };

  const clickTranslationTimeBlockMob = (el, oneDay, i) => {
    if (!DATA.streams[0]) {
      alert("Стрим еще не создан");
    } else {
      if (currentSeansStreamDay === i && switchChecked) {
        alert("Стрим приостановлен");
      } else {
        setEnumWeekName(el.day);
        if (oneDay && oneDay.id) {
          setIsEmptyTime(false); //не пустое время
          setStartRealTimeInPicker(oneDay.start_time);
          setEndRealTimeInPicker(oneDay.end_time);
          togglePopupDatePicker("График трансляций");
          setIsStream(true);
          setClickedTime(oneDay);
          setIsSetWorkTimeDPick(false);
        } else {
          setIsEmptyTime(true); //пустое время
          setStartRealTimeInPicker("00:00");
          setEndRealTimeInPicker("00:00");
          togglePopupDatePicker("График трансляций");
          setIsStream(true);
          setIsSetWorkTimeDPick(false);
        }
      }
    }
  };

  const clickWorkTimeBlock = (el, oneDay) => {
    setEnumWeekName(el.day);
    if (oneDay && oneDay.id) {
      setIsEmptyTime(false); //не пустое время
      setStartRealTimeInPicker(oneDay.start_time);
      setEndRealTimeInPicker(oneDay.end_time);
      togglePopupDatePicker();
      setClickedTime(oneDay); //объект для отправки запроса
      setIsSetWorkTimeDPick(true);
    } else {
      setIsEmptyTime(true);
      setStartRealTimeInPicker("00:00");
      setEndRealTimeInPicker("00:00");
      togglePopupDatePicker();
      setIsSetWorkTimeDPick(true);
    }
  };

  const setStreamDay = () => {
    if (DATA.streams && DATA.streams[0]) {
      const currentDay = EN_SHORT_DAY_OF_WEEK[numberDayNow].day,
        prevDay = EN_SHORT_PREV_DAY[currentDay],
        currentTimeMs =
          new Date().getHours() * 3600 + new Date().getMinutes() * 60;

      let currSchEndTime = null,
        prevSchEndTimeMs = null,
        prevSchEndTime = null;

      DATA.streams[0].schedules.forEach((el) => {
        const end = el.end_time.split(":"),
          start = el.start_time.split(":");
        if (el.day === currentDay) currSchEndTime = el.end_time;
        if (
          el.day === prevDay &&
          +end[0] * 3600 + +end[1] * 60 < +start[0] * 3600 + +start[1] * 60
        ) {
          prevSchEndTimeMs = +end[0] * 3600 + +end[1] * 60;
          prevSchEndTime = el.end_time;
        }
      });

      if (prevSchEndTimeMs) {
        if (currentTimeMs < prevSchEndTimeMs) {
          setCurrentSeansStreamDay(numberDayPrev);
          setCurrentSeansStreamTime(prevSchEndTime);
        } else {
          setCurrentSeansStreamDay(numberDayNow);
          setCurrentSeansStreamTime(currSchEndTime);
        }
      } else {
        setCurrentSeansStreamDay(numberDayNow);
        setCurrentSeansStreamTime(currSchEndTime);
      }
    }
  };

  const setWarkDay = () => {
    if (DATA.schedules) {
      const currentDay = EN_SHORT_DAY_OF_WEEK[numberDayNow].day,
        prevDay = EN_SHORT_PREV_DAY[currentDay],
        currentTime =
          new Date().getHours() * 3600 + new Date().getMinutes() * 60;

      let prevSchEndTime = null;

      DATA.schedules.forEach((el) => {
        const end = el.end_time.split(":"),
          start = el.start_time.split(":");
        if (
          el.day === prevDay &&
          +end[0] * 3600 + +end[1] * 60 < +start[0] * 3600 + +start[1] * 60
        )
          prevSchEndTime = +end[0] * 3600 + +end[1] * 60;
      });

      if (prevSchEndTime) {
        currentTime < prevSchEndTime
          ? setCurrentSeansWarkDay(numberDayPrev)
          : setCurrentSeansWarkDay(numberDayNow);
      } else {
        setCurrentSeansWarkDay(numberDayNow);
      }
    }
  };

  const companyHover = (el) => {
    if (typeOfCompany && typeOfCompany === el.name) {
      return renderCustomTypeImg(el.slug, true);
    } else {
      if (
        !typeOfCompany &&
        DATA.categories &&
        DATA.categories[0] &&
        DATA.categories[0].name === el.name
      ) {
        return renderCustomTypeImg(el.slug, true);
      } else {
        if (hoveredBtn === el.name) {
          return renderCustomTypeImg(el.slug, true);
        } else {
          return renderCustomTypeImg(el.slug, false);
        }
      }
    }
  };

  const getAddress = () => {
    if (DATA.address) {
      return DATA.address
        .split(",")[0]
        .replace("улица", "ул.")
        .replace("проспект", "пр-т.");
    }
  };

  const setCityByName = (label) => {
    const city = citiesList.filter((c) => c.label === label);
    setSelectedCityId(city[0]?.id || "");
  };

  const getCityNameById = (id) => {
    const city = citiesList.filter((c) => +c.id === +id);
    return city[0]?.label || "";
  };

  const getCityById = (id) => {
    const city = citiesList.filter((c) => +c.id === +id);
    return city[0] || {};
  };

  useEffect(() => {
    sessionStorage.setItem("prevZoom", "");
    sessionStorage.setItem("prevCenter", "");
    if (localStorage.getItem("token")) refreshData();

    QUERY({
      query: `query {
      categories { 
        id 
        name 
        slug
      }
    }`,
    })
      .then((res) => res.json())
      .then((res) => {
        setUniqueCompanyType(res?.data?.categories);
      })
      .catch((err) => {
        setUniqueCompanyType([]);
      });
  }, []);

  useEffect(() => {
    window.onresize = function (e) {
      setWindowWidth(e.target.innerWidth);
    };
    autosize(document.querySelectorAll("textarea"));
  });

  useEffect(() => {
    showPopupDatePicker ||
    showPopupGoogleMap ||
    showPopupDescription ||
    showPopupChooseType ||
    showPopupUploadFile
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [
    showPopupDatePicker,
    showPopupGoogleMap,
    showPopupDescription,
    showPopupChooseType,
    showPopupUploadFile,
  ]);

  useEffect(() => {
    sessionStorage.setItem("opened_li_admin", JSON.stringify(leftMenuSettings));
  }, [leftMenuSettings]);

  useEffect(() => {
    DATA.description && setDescOfCompany(DATA.description);
    DATA.name && setNameOfCompany(DATA.name);
    DATA.alias && setAliasOfCompany(DATA.alias);

    if (DATA.streams && DATA.streams[0] && DATA.streams[0].see_you_tomorrow) {
      setSwitchChecked(DATA.streams[0].is_see_you_tomorrow);
    }

    setStreamDay();
    setWarkDay();
  }, [DATA]);

  useEffect(() => {
    if (imageElementRef.current) {
      const cropper = new Cropper(imageElementRef.current, {
        zoomable: true,
        scalable: false,
        dragMode: "move",
        aspectRatio: 16 / 9,
        rotatable: true,
        minCropBoxWidth: 50,
        movableL: true,
        modal: true,
        imageSmoothingEnabled: true,
        crop: (e) => {
          const canvas = cropper.getCroppedCanvas();
          canvas.toBlob(function (blob) {
            if (blob) {
              const formData = new FormData();
              formData.append("my-file", blob, "filename.png");
              setImageDestination(blob);
            }
          });
        },
      });
    }
  }, [imgSrc]);

  window.onresize = function (e) {
    hideSideMenu();
  };

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: [
        { threshold: matchSorter.rankings.STARTS_WITH, key: "label" },
        inputValue,
      ],
    });
  };

  if (!localStorage.getItem("userId")) {
    return <Redirect to="/login" />;
  } else {
    return (
      <div style={{ height: "100%" }}>
        <div onClick={(e) => hide(e)} style={{ minHeight: "100%" }}>
          <AdminStyle
            as={animated.div}
            style={animateProps}
            onClick={(e) => hide(e)}
          >
            <Header
              isShowMenu={isShowMenu}
              logo
              burger
              arrow
              showSlideSideMenu={showSlideSideMenu}
              showSideMenu={showSideMenu}
            />
            {isLoading && <Loader />}
            {!isLoading && (
              <AdminWrapper>
                {/* __________________DESCTOP__________________ */}
                <LeftAdminMenuD>
                  <LeftAdminMenuInnerD>
                    <ul>
                      {leftMenuSettings.map((el, id) => (
                        <LeftMenu
                          el={el}
                          setLeftMenuSettings={setLeftMenuSettings}
                          id={id}
                        />
                      ))}
                    </ul>
                  </LeftAdminMenuInnerD>
                </LeftAdminMenuD>

                <AdminContentD>
                  {leftMenuSettings.map((el, i) => {
                    if (el.clicked && i === 0) {
                      return (
                        <div key={i}>
                          <PhotoDescWrapper>
                            <BlockName>Фото:</BlockName>
                            {imgSrc ? (
                              <CropWrapperD>
                                <ImgContainerD>
                                  <img
                                    ref={imageElementRef}
                                    src={imgSrc}
                                    alt="src"
                                  />
                                </ImgContainerD>
                                <CanvasImageD />
                              </CropWrapperD>
                            ) : (
                              <PreviewPhotoD
                                doNotHoverBorder={DATA.profile_image}
                                onClick={() =>
                                  document.querySelector(".previewRef").click()
                                }
                              >
                                {DATA.profile_image ? (
                                  <UploadImgStyle
                                    src={`${queryPath}/storage/${DATA.profile_image}`}
                                    alt="image"
                                  />
                                ) : (
                                  <PreviewPhotoTextD>
                                    Загрузить
                                  </PreviewPhotoTextD>
                                )}
                              </PreviewPhotoD>
                            )}
                          </PhotoDescWrapper>

                          <Dropzone
                            multiple={false}
                            accept={acceptedFileTypes}
                            maxSize={imageMaxSize}
                            onDrop={(acceptedFiles, rejectedFiles) =>
                              handleOnDrop(acceptedFiles, rejectedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => {
                              return (
                                <section style={{ display: "flex" }}>
                                  <div
                                    className="changePhotoBlock previewRef"
                                    {...getRootProps()}
                                  >
                                    <input
                                      className="changePhotoInput"
                                      {...getInputProps()}
                                    />
                                    {imgSrc && (
                                      <p className="changePhoto">Изменить</p>
                                    )}
                                  </div>
                                  {imgSrc && (
                                    <span
                                      className="changePhoto"
                                      onClick={handeleClearToDefault}
                                    >
                                      Отмена
                                    </span>
                                  )}
                                </section>
                              );
                            }}
                          </Dropzone>

                          <div className="profileDataDesc">
                            <InputWrap>
                              <BlockName>Название:</BlockName>
                              <Inputs
                                err={validationErr["nameOfCompany"]}
                                placeholder={DATA.name}
                                value={nameOfCompany}
                                onInput={(e) =>
                                  setNameOfCompany(e.target.value)
                                }
                              />
                            </InputWrap>

                            <InputWrap>
                              <BlockName>Псевдоним:</BlockName>
                              <Inputs
                                err={validationErr["aliasOfCompany"]}
                                type="text"
                                placeholder={DATA.alias}
                                value={aliasOfCompany}
                                pattern="^[a-zA-Z0-9]+$"
                                onInput={(e) =>
                                  setAliasOfCompany(e.target.value)
                                }
                              />
                            </InputWrap>

                            <InputWrap>
                              <BlockName>Категория:</BlockName>
                              <CategoryBtnsWRap>
                                {!!uniqueCompanyType &&
                                  uniqueCompanyType.map((el, i) => (
                                    <span
                                      className="categoryBtn"
                                      key={i}
                                      style={categorySyle(el)}
                                      onClick={() => {
                                        setTypeOfCompany(el.name);
                                        setTypeOfCompanyId(el.id);
                                      }}
                                      onMouseOver={() => setHoveredBtn(el.name)}
                                      onMouseOut={() => setHoveredBtn("")}
                                    >
                                      <CategoryImgTextWrap>
                                        <KlubWrap slug={el.slug === "klub"}>
                                          {companyHover(el)}
                                        </KlubWrap>
                                        {el.name}
                                      </CategoryImgTextWrap>
                                    </span>
                                  ))}
                              </CategoryBtnsWRap>
                            </InputWrap>

                            <InputWrap
                              style={{
                                position: "relative",
                              }}
                            >
                              <BlockName>Адрес:</BlockName>
                              <InputsAddress
                                onClick={() => togglePopupGoogleMap()}
                              >
                                {getAddress()}
                              </InputsAddress>
                            </InputWrap>
                            {citiesList && citiesList.length > 0 && (
                              <InputWrap>
                                <BlockName>Город:</BlockName>
                                <AutocompleteWrapper>
                                  <Autocomplete
                                    options={citiesList}
                                    sx={{ width: "100%" }}
                                    defaultValue={getCityNameById(
                                      DATA?.city_id
                                    )}
                                    filterOptions={filterOptions}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(e, value) =>
                                      setCityByName(value?.label)
                                    }
                                    noOptionsText={"Ничего не найдено"}
                                  />
                                </AutocompleteWrapper>
                              </InputWrap>
                            )}

                            <div style={{ marginTop: "20px" }}>
                              <BlockName style={{ marginTop: "12px" }}>
                                Описание:
                              </BlockName>
                              <TextareaDescriptionWrap>
                                <TextareaDescription
                                  maxLength={descOfCompanyLimit}
                                  value={descOfCompany}
                                  onChange={(e) =>
                                    setDescOfCompany(e.target.value)
                                  }
                                />
                                <LengthofDescriptionD
                                  descOfCompany={descOfCompany}
                                  descOfCompanyLimit={descOfCompanyLimit}
                                >
                                  {descOfCompany.length} / {descOfCompanyLimit}
                                </LengthofDescriptionD>
                              </TextareaDescriptionWrap>
                            </div>

                            <div>
                              <p
                                style={{ marginRight: "20px" }}
                                className="saveBtnProfile"
                                onClick={() => {
                                  checkValidationError() &&
                                    uploadImageTranscode();
                                  checkValidationError() && updatePlaceData();
                                }}
                              >
                                Сохранить
                              </p>
                              <Link
                                to={"/editCompany"}
                                className="cancelBtnProfile"
                                onClick={() => {
                                  setValidationErr({});
                                  setNameOfCompany(DATA.name);
                                  setAliasOfCompany(DATA.alias);
                                  setTypeOfCompany(
                                    DATA.categories &&
                                      DATA.categories[0] &&
                                      DATA.categories[0].name
                                  );
                                  setTypeOfCompanyId(
                                    DATA.categories &&
                                      DATA.categories[0] &&
                                      DATA.categories[0].id
                                  );
                                  setDescOfCompany(DATA.description);
                                }}
                              >
                                Отмена
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    if (el.clicked && i === 1) {
                      return (
                        <div key={i} className="workTimeTableWrap">
                          <table className="tableWorkDesc">
                            <tbody>
                              {DATA.schedules &&
                                EN_SHORT_DAY_OF_WEEK.map((el, i) => {
                                  const oneDay = SetNewTimeObject(
                                    DATA.schedules
                                  )[el.day];

                                  return (
                                    <tr key={i}>
                                      <CurrentDay
                                        isCurrent={currentSeansWarkDay === i}
                                      >
                                        {getDayNum(el, oneDay, i)}:
                                      </CurrentDay>
                                      <td className="timeTd">
                                        <TimeBlock
                                          onClick={() =>
                                            clickWorkTimeBlock(el, oneDay)
                                          }
                                        >
                                          {isWorkTimeOrDayOff(oneDay)}
                                        </TimeBlock>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      );
                    }
                    if (el.clicked && i === 2) {
                      return (
                        <div className="workTimeTableWrap">
                          <table className="tableWorkDesc">
                            <tbody>
                              {DATA.streams &&
                                EN_SHORT_DAY_OF_WEEK.map((el, i) => {
                                  const oneDay = SetNewTimeObject(
                                    DATA.streams[0]
                                      ? DATA.streams[0].schedules
                                      : []
                                  )[el.day];

                                  return (
                                    <tr key={i}>
                                      <CurrentDay
                                        isCurrent={currentSeansStreamDay === i}
                                      >
                                        {getDayNum(el, oneDay, i)}:
                                      </CurrentDay>
                                      <td
                                        className="timeTd"
                                        style={{ position: "relative" }}
                                      >
                                        <TimeBlock
                                          opacity={
                                            currentSeansStreamDay === i &&
                                            switchChecked
                                          }
                                          onClick={() =>
                                            clickTranslationTimeBlockDesc(
                                              el,
                                              oneDay,
                                              i
                                            )
                                          }
                                        >
                                          {currentSeansStreamDay === i &&
                                            isWorkTimeOrDayOff(oneDay) !==
                                              "Круглосуточно" &&
                                            isWorkTimeOrDayOff(oneDay) !==
                                              "Выходной" &&
                                            isWorkTimeOrDayOff(
                                              oneDay,
                                              true
                                            ) && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  width: "10px",
                                                  height: "10px",
                                                  top: "-4px",
                                                  left: "129px",
                                                  background: defaultColor,
                                                  borderRadius: "10px",
                                                }}
                                              >
                                                {isWorkTimeOrDayOff(
                                                  oneDay,
                                                  true
                                                )}
                                              </div>
                                            )}

                                          {isWorkTimeOrDayOff(oneDay)}
                                        </TimeBlock>
                                      </td>
                                    </tr>
                                  );
                                })}

                              {DATA.streams && DATA.streams[0] && (
                                <DisableStreamD>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DisableStreamToNexDayD>
                                      Вкл/выкл стрима
                                    </DisableStreamToNexDayD>
                                    <Switch
                                      onChange={(data) => {
                                        setSwitchChecked(data);
                                        disableStream(data);
                                      }}
                                      checked={switchChecked}
                                      onColor={defaultColor}
                                      offColor="#cccccc"
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                    />
                                  </div>
                                </DisableStreamD>
                              )}
                            </tbody>
                          </table>
                        </div>
                      );
                    }
                    if (el.clicked && i === 3) {
                      return (
                        <Stream
                          index={i}
                          DATA={DATA}
                          props={props}
                          setDATA={setDATA}
                          refreshData={refreshData}
                          setIsLoading={setIsLoading}
                        />
                      );
                    }
                  })}
                </AdminContentD>

                {/* __________________MOBILE__________________ */}
                <div className="adminContentMobile">
                  <div>
                    {/* ____________________________________ Стрим */}
                    <MenuBlockWrapp>
                      <MenuBlock onClick={(e) => setStreamOpen(true)}>
                        Стрим
                        <RotateArrow />
                      </MenuBlock>
                      <SideBar
                        isOpen={streamOpen}
                        right
                        pageWrapId={"page-wrap"}
                        outerContainerId={"App"}
                        width={"100%"}
                      >
                        <StreamMobile
                          closeAllSidebar={closeAllSidebar}
                          streamOpen={streamOpen}
                          DATA={DATA}
                          setDATA={setDATA}
                          refreshData={refreshData}
                        />
                      </SideBar>
                    </MenuBlockWrapp>
                    {/* ______________________________Профиль заведения */}
                    <MenuBlockWrapp>
                      <MenuBlock onClick={(e) => setPprofileOpen(true)}>
                        Профиль заведения
                        <RotateArrow />
                      </MenuBlock>

                      <div className="drDownWrap">
                        <SideBar
                          styles={{ padding: "0px !important" }}
                          style={{ padding: 0 }}
                          isOpen={profileOpen}
                          right
                          pageWrapId={"page-wrap"}
                          outerContainerId={"App"}
                          width={"100%"}
                        >
                          <div style={{ position: "relative", top: "-46px" }}>
                            <AdminHeader
                              close={() => {
                                closeAllSidebar();
                                setValidationErr({});
                                setNameOfCompany(DATA.name);
                                setAliasOfCompany(DATA.alias);
                              }}
                              save={() => {
                                checkValidationError() && updatePlaceData();
                                checkValidationError() && closeAllSidebar();
                              }}
                            />

                            <AdminMenuTitleM>Профиль</AdminMenuTitleM>
                            <div
                              className="uploadFileContainer"
                              style={{ paddingTop: "0", marginTop: "-20px" }}
                            >
                              <UploadFile>
                                {imgSrc ? (
                                  <></>
                                ) : (
                                  <>
                                    <PreviewPhotoM
                                      onClick={() => {
                                        togglePopupUploadFile();
                                        document
                                          .querySelector(".previewRef")
                                          .click();
                                      }}
                                    >
                                      {DATA.profile_image ? (
                                        <UploadImgStyleMobile
                                          src={`${queryPath}/storage/${DATA.profile_image}`}
                                          alt="image"
                                        />
                                      ) : (
                                        <p>Загрузить фото</p>
                                      )}
                                    </PreviewPhotoM>
                                    <ChangeDeleteWrap>
                                      <span
                                        style={{ marginRight: "30px" }}
                                        onClick={() => {
                                          togglePopupUploadFile();
                                          document
                                            .querySelector(".previewRef")
                                            .click();
                                        }}
                                      >
                                        Изменить
                                      </span>
                                      <span onClick={updateOrRemoveUploadImage}>
                                        Удалить
                                      </span>
                                    </ChangeDeleteWrap>
                                  </>
                                )}
                                <Dropzone
                                  multiple={false}
                                  accept={acceptedFileTypes}
                                  maxSize={imageMaxSize}
                                  onDrop={(acceptedFiles, rejectedFiles) =>
                                    handleOnDrop(acceptedFiles, rejectedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => {
                                    return (
                                      <section style={{ display: "flex" }}>
                                        <div
                                          className="changePhotoBlock"
                                          {...getRootProps()}
                                        >
                                          <input
                                            className="changePhotoInput previewRef"
                                            {...getInputProps()}
                                          />
                                          {imgSrc && (
                                            <p className="changePhoto">
                                              Изменить
                                            </p>
                                          )}
                                        </div>
                                        {imgSrc && (
                                          <span
                                            className="changePhoto"
                                            onClick={handeleClearToDefault}
                                          >
                                            Удалить
                                          </span>
                                        )}
                                      </section>
                                    );
                                  }}
                                </Dropzone>
                              </UploadFile>
                            </div>
                            <div className="profileDataDesc">
                              <Label>Имя</Label>
                              <InputMobile
                                style={
                                  validationErr["nameOfCompany"] && {
                                    color: "red",
                                  }
                                }
                                type="text"
                                placeholder={DATA.name}
                                value={nameOfCompany}
                                onChange={(e) =>
                                  setNameOfCompany(e.target.value)
                                }
                              />
                              <Label>Псевдоним</Label>
                              <InputMobile
                                type="text"
                                style={
                                  validationErr["aliasOfCompany"] && {
                                    color: "red",
                                  }
                                }
                                placeholder={DATA.alias}
                                value={aliasOfCompany}
                                onChange={(e) =>
                                  setAliasOfCompany(
                                    e.target.value.toLowerCase()
                                  )
                                }
                              />

                              <Label>Тип </Label>
                              {DATA.categories &&
                              DATA.categories[0] &&
                              DATA.categories[0].slug ? (
                                <CategoryBtn
                                  onClick={() => togglePopupChooseType()}
                                >
                                  {renderCustomTypeImg(
                                    DATA.categories[0].slug,
                                    false
                                  )}
                                  {DATA.categories[0].name}
                                </CategoryBtn>
                              ) : (
                                <CategoryBtn
                                  onClick={() => togglePopupChooseType()}
                                >
                                  Выбрать тип
                                </CategoryBtn>
                              )}

                              <Label>Адрес </Label>
                              <AddressBtn
                                onClick={() => togglePopupGoogleMap()}
                              >
                                {getAddress()}
                              </AddressBtn>

                              <Label>Город</Label>
                              <AutocompleteWrapperMobile>
                                <Autocomplete
                                  options={citiesList}
                                  sx={{ width: "100%" }}
                                  defaultValue={getCityNameById(DATA?.city_id)}
                                  filterOptions={filterOptions}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  onChange={(e, value) =>
                                    setCityByName(value?.label)
                                  }
                                  noOptionsText={"Ничего не найдено"}
                                />
                              </AutocompleteWrapperMobile>
                              <Label>Описание </Label>
                              <DescriptionBtn
                                onClick={() => togglePopupDescription()}
                              >
                                {descOfCompany}
                              </DescriptionBtn>
                            </div>
                          </div>
                        </SideBar>
                      </div>
                    </MenuBlockWrapp>
                    {/* ______________________________________ График работы */}
                    <MenuBlockWrapp>
                      <MenuBlock onClick={(e) => setWorkScheduleOpen(true)}>
                        График работы
                        <RotateArrow />
                      </MenuBlock>
                      <div className="drDownWrapPaddingOff">
                        <SideBar
                          isOpen={workScheduleOpen}
                          right
                          pageWrapId={"page-wrap"}
                          outerContainerId={"App"}
                          width={"100%"}
                        >
                          <div style={{ position: "relative", top: "-46px" }}>
                            <div>
                              <AdminHeader
                                close={closeAllSidebar}
                                save={closeAllSidebar}
                              />

                              <AdminMenuTitleM>График работы</AdminMenuTitleM>
                              <div>
                                <table style={{ marginTop: 0 }}>
                                  <tbody>
                                    {DATA.schedules &&
                                      EN_SHORT_DAY_OF_WEEK.map((el, i) => {
                                        const oneDay = SetNewTimeObject(
                                          DATA.schedules
                                        )[el.day];
                                        return (
                                          <GraphTr key={i} borderTop={i === 0}>
                                            <DayOfWeek
                                              isCurrent={
                                                currentSeansWarkDay === i
                                              }
                                            >
                                              {getDayNum(el, oneDay, i)}:
                                            </DayOfWeek>
                                            <GraphTime
                                              bold={currentSeansWarkDay === i}
                                              onClick={() => {
                                                setEnumWeekName(el.day);
                                                if (oneDay && oneDay.id) {
                                                  setIsEmptyTime(false); //не пустое время
                                                  setStartRealTimeInPicker(
                                                    oneDay.start_time
                                                  );
                                                  setEndRealTimeInPicker(
                                                    oneDay.end_time
                                                  );
                                                  togglePopupDatePicker(
                                                    "График работы"
                                                  );
                                                  setClickedTime(oneDay); //объект для отправки запроса
                                                  setIsSetWorkTimeDPick(true);
                                                } else {
                                                  setIsEmptyTime(true);
                                                  setStartRealTimeInPicker(
                                                    "00:00"
                                                  );
                                                  setEndRealTimeInPicker(
                                                    "00:00"
                                                  );
                                                  togglePopupDatePicker(
                                                    "График работы"
                                                  );
                                                  setIsSetWorkTimeDPick(true);
                                                }
                                              }}
                                            >
                                              {isWorkTimeOrDayOff(oneDay)}
                                            </GraphTime>
                                          </GraphTr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </SideBar>
                      </div>
                    </MenuBlockWrapp>
                    {/* _____________________________________ График трансляций */}
                    <MenuBlockWrapp>
                      <MenuBlock onClick={(e) => setStreamScheduleOpen(true)}>
                        График трансляций
                        <RotateArrow />
                      </MenuBlock>
                      <div className="drDownWrapPaddingOff">
                        <SideBar
                          isOpen={streamScheduleOpen}
                          right
                          pageWrapId={"page-wrap"}
                          outerContainerId={"App"}
                          width={"100%"}
                        >
                          <div style={{ position: "relative", top: "-46px" }}>
                            <AdminHeader
                              close={closeAllSidebar}
                              save={closeAllSidebar}
                            />

                            <div>
                              <AdminMenuTitleM>
                                График трансляций
                              </AdminMenuTitleM>
                              <table style={{ marginTop: 0 }}>
                                <tbody>
                                  {DATA.streams &&
                                    EN_SHORT_DAY_OF_WEEK.map((el, i) => {
                                      const oneDay = SetNewTimeObject(
                                        DATA.streams[0]
                                          ? DATA.streams[0].schedules
                                          : []
                                      )[el.day];

                                      return (
                                        <GraphTr borderTop={i === 0} key={i}>
                                          <DayOfWeek
                                            isCurrent={
                                              currentSeansStreamDay === i
                                            }
                                          >
                                            {getDayNum(el, oneDay, i)}:
                                          </DayOfWeek>
                                          <GraphTime
                                            opacity={
                                              currentSeansStreamDay === i &&
                                              switchChecked
                                            }
                                            bold={currentSeansStreamDay === i}
                                            onClick={() =>
                                              clickTranslationTimeBlockMob(
                                                el,
                                                oneDay,
                                                i
                                              )
                                            }
                                          >
                                            {isWorkTimeOrDayOff(oneDay)}
                                            {currentSeansStreamDay === i &&
                                              isWorkTimeOrDayOff(oneDay) !==
                                                "Круглосуточно" &&
                                              isWorkTimeOrDayOff(oneDay) !==
                                                "Выходной" &&
                                              isWorkTimeOrDayOff(
                                                oneDay,
                                                true
                                              ) && (
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    width: "10px",
                                                    height: "10px",
                                                    top: "-24px",
                                                    left: "-66px",
                                                    background: defaultColor,
                                                    borderRadius: "10px",
                                                  }}
                                                >
                                                  {isWorkTimeOrDayOff(
                                                    oneDay,
                                                    true
                                                  )}
                                                </div>
                                              )}
                                          </GraphTime>
                                        </GraphTr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {DATA.streams && DATA.streams[0] && (
                            <DisableStreamD>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <DisableStreamToNexDayD>
                                  Вкл/выкл стрима
                                </DisableStreamToNexDayD>
                                <Switch
                                  onChange={(data) => {
                                    setSwitchChecked(data);
                                    disableStream(data);
                                  }}
                                  checked={switchChecked}
                                  onColor={defaultColor}
                                  offColor="#cccccc"
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                />
                              </div>
                            </DisableStreamD>
                          )}
                        </SideBar>
                      </div>
                    </MenuBlockWrapp>
                  </div>
                </div>
              </AdminWrapper>
            )}
          </AdminStyle>

          <SlideSideMenu isShowMenu={isShowMenu} />

          {showPopupDatePicker && (
            <DatePickerPopup
              togglePopupDatePicker={togglePopupDatePicker}
              windowWidth={windowWidth}
              titleInPicker={titleInPicker}
              setAsDayOf={setAsDayOf}
              startRealTimeInPicker={startRealTimeInPicker}
              isSetWorkTimeDPick={isSetWorkTimeDPick}
              isEmptyTime={isEmptyTime}
              isStream={isStream}
              props={props}
              enumWeekName={enumWeekName}
              endRealTimeInPicker={endRealTimeInPicker}
              refreshData={refreshData}
              clickedTime={clickedTime}
              DATA={DATA}
              setDATA={setDATA}
              tomorrowFromDay={tomorrowFromDay}
              cityId={selectedCityId}
            />
          )}

          {showPopupChooseType && (
            <ChooseTypePopup
              togglePopupChooseType={togglePopupChooseType}
              DATA={DATA}
              setTypeOfCompany={setTypeOfCompany}
              setTypeOfCompanyId={setTypeOfCompanyId}
              props={props}
              typeOfCompanyId={typeOfCompanyId}
              refreshData={refreshData}
              uniqueCompanyType={uniqueCompanyType}
              typeOfCompany={typeOfCompany}
              setHoveredBtn={setHoveredBtn}
              renderCustomTypeImg={renderCustomTypeImg}
              hoveredBtn={hoveredBtn}
            />
          )}

          {showPopupDescription && (
            <DescriptionPopup
              togglePopupDescription={togglePopupDescription}
              DATA={DATA}
              setDescOfCompany={setDescOfCompany}
              props={props}
              descOfCompany={descOfCompany}
              refreshData={refreshData}
              descOfCompanyLimit={descOfCompanyLimit}
            />
          )}

          {showPopupGoogleMap && (
            <MapPopup
              togglePopupGoogleMap={togglePopupGoogleMap}
              city={getCityById(selectedCityId)}
              DATA={DATA}
              props={props}
              refreshData={refreshData}
            />
          )}

          {showPopupUploadFile && (
            <UploadFilePopup
              togglePopupUploadFile={togglePopupUploadFile}
              setImgSrc={setImgSrc}
              titleInPicker={titleInPicker}
              imgSrc={imgSrc}
              uploadImageTranscode={uploadImageTranscode}
              acceptedFileTypes={acceptedFileTypes}
              imageMaxSize={imageMaxSize}
              handleOnDrop={handleOnDrop}
            />
          )}
        </div>

        <animated.div
          className="successSaved"
          style={{
            bottom: animateSavedProps.bottom,
            background: defaultColor,
          }}
        >
          <div>
            <p>Сохранено</p>
          </div>
        </animated.div>
      </div>
    );
  }
};

export default Admin;
