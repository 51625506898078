import React, { useState, useEffect } from "react";
import styled from "styled-components";

import QUERY from "../../query";
import Popup from "../../components/Popup";
import TimePicker from "./TimePicker";

import {
  defaultColor,
  EN_SHORT_TO_NUMBER,
  EN_SHORT_TO_RU_SHORT,
  RU_SHORT_TO_EN_SHORT,
  PLACE_QUERY,
  SCHEDULE_PLACE_QUERY,
  SHORT_DAY_OF_WEEK,
} from "../../constants";
import AdminHeader from "../../components/AdminHeader";

const TimePickerContainer = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 760px) {
    width: 100vw;
    margin-top: 0px;
  }
`;

const MakeAsDayOffMobile = styled.p`
  display: none;
  @media (max-width: 760px) {
    display: block;
    font-size: 18px;
    width: 275px;
    margin: 0 auto;
    color: #f8104d;
    padding: 15px 0 15px 0;
    text-align: left;
    cursor: pointer;
    font-weight: normal;
    &:hover {
      color: #000;
    }
  }
`;

const PopupPickerBtnsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const PopupPickerBtn = styled.p`
  border-radius: 5px;
  width: 240px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 760px) {
    display: none;
  }
`;

const Save = styled(PopupPickerBtn)`
  background: #f8104d;
  color: #fff;
  margin-right: 20px;
`;

const Cancel = styled(PopupPickerBtn)`
  background: #fff;
  border: 2px solid ${defaultColor};
  color: ${defaultColor};
`;

const CheckboxWrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 760px) {
    display: none;
  }
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: ${(props) =>
    props.clicked ? `2px solid ${defaultColor}` : `2px solid #D3D3D3`};
  margin-right: 7px;
  &:after {
    border-radius: 50%;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: ${(props) => (props.clicked ? defaultColor : "#fff")};
    position: relative;
    top: 3.3px;
    left: 3.3px;
  }
`;

const DayText = styled.div`
  font-size: ${(props) => (props.clicked ? "15px" : "16px")};
  font-weight: 600;
  color: ${(props) => (props.clicked ? "grey" : "#000")};
  text-align: center;
  &:before {
    border-radius: 50%;
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: ${(props) => (props.clicked ? "#fff" : "#00c55a")};
    position: relative;
    right: 5px;
  }
`;

const NotValid = styled(PopupPickerBtn)`
  color: ${defaultColor};
  margin-right: 20px;
  text-align: center;
`;

const CheckboxText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => (props.clicked ? defaultColor : "#4F4F4F")};
`;

const DatePickerPopup = ({
  togglePopupDatePicker,
  windowWidth,
  isStream,
  setAsDayOf,
  startRealTimeInPicker,
  isSetWorkTimeDPick,
  isEmptyTime,
  enumWeekName,
  endRealTimeInPicker,
  refreshData,
  clickedTime,
  DATA,
  tomorrowFromDay,
  setDATA,
  cityId,
}) => {
  const [startTimePicker, setStartTimePicker] = useState("00:00");
  const [endTimePicker, setEndTimePicker] = useState("00:00");
  const [start, setStart] = useState(false);
  const [finish, setFinish] = useState(false);
  const [valid, setValid] = useState(false);
  const [isClick1, setIsClick1] = useState(true);
  const [isClick2, setIsClick2] = useState(false);
  const [isClick3, setIsClick3] = useState(false);

  const startTime = EN_SHORT_TO_RU_SHORT[enumWeekName];
  const finishTime = SHORT_DAY_OF_WEEK[tomorrowFromDay(EN_SHORT_TO_NUMBER[enumWeekName])];

  useEffect(() => {
    const isCurrentDay = (clickedTime?.start_time?.split(":")[0] * 3600 + clickedTime?.start_time?.split(":")[1] * 60 <=
      clickedTime?.end_time?.split(":")[0] * 3600 + clickedTime?.end_time?.split(":")[1] * 60);

    if(clickedTime.day !== enumWeekName){
      setStart(true);
      setFinish(true);
    } else {
      setStart(false);
      setFinish(!isCurrentDay);
    }
  }, []);

  useEffect(() => {
    setValid(true);
  }, [startTimePicker, endTimePicker]);

  const disableAllClick = () => {
    setIsClick1(false);
    setIsClick2(false);
    setIsClick3(false);
  };

  const setWorkTimeOfOneDay = (allDay) => {
    if (localStorage.getItem("token")) {
      let startTime = isClick1 ? startTimePicker : "00:00";
      let endTime = isClick1 ? endTimePicker : "00:00";
      let dayData =
        isClick2 || isClick3 || !start
          ? enumWeekName
          : RU_SHORT_TO_EN_SHORT[finishTime];

      if (allDay) {
        startTime = "00:00";
        endTime = "00:00";
      }

      if (isEmptyTime) {
        //СОЗДАТЬ время работы заведения
        QUERY(
          {
            query: `
              mutation {
                updatePlace(
                  input:{
                    id:"${DATA.id}"
                    city_id:${cityId}
                    schedules:{
                      create:{section:${enumWeekName} day:${dayData} start_time:"${startTime}" end_time:"${endTime}"}
                    }
                  }
                ){id}
              }
            `,
          },
          localStorage.getItem("token")
        )
          .then((res) => res.json())
          .then((data) => {
            !data.errors ? refreshData() : console.log(data.errors, " ERRORS");
          })
          .catch((err) => console.log(err, "  ERR"));
      }

      if (!isEmptyTime) {
        //ИЗМЕНИТЬ время работы заведения
        QUERY(
          {
            query: `
              mutation {
                updateSchedule(
                  input:{
                    id:"${clickedTime.id}"
                    section:${enumWeekName} day:${dayData} start_time:"${startTime}" end_time:"${endTime}" 
                  }
                ){id ${SCHEDULE_PLACE_QUERY} }
              }
            `,
          },
          localStorage.getItem("token")
        )
          .then((res) => res.json())
          .then((data) => {
            !data.errors
              ? setDATA(data.data.updateSchedule.schedulable)
              : console.log(data.errors, " ERRORS");
          })
          .catch((err) => console.log(err, " ERR"));
      }
    }
  };

  const setStreamTimeOfOneDay = (allDay) => {
    if (localStorage.getItem("token")) {
      let startTime = isClick1 ? startTimePicker : "00:00";
      let endTime = isClick1 ? endTimePicker : "00:00";
      let dayData =
        isClick2 || isClick3 || !start
          ? enumWeekName
          : RU_SHORT_TO_EN_SHORT[finishTime];

      if (allDay) {
        startTime = "00:00";
        endTime = "00:00";
      }

      if (DATA.streams[0] && !isEmptyTime) {
        // не пустое время стрима и стрим уже существет
        const QUERY_DATA = {
          query: `
            mutation {
              updateStream (
                input:{
                  id:"${DATA.streams[0].id}"
                  schedules:{
                    update:[{ id:${clickedTime.id} section:${enumWeekName} day:${dayData} start_time:"${startTime}" end_time:"${endTime}" }]
                  }
                }
              ) { id name url ${PLACE_QUERY} }
            }
          `,
        };

        QUERY(QUERY_DATA, localStorage.getItem("token"))
          .then((res) => res.json())
          .then((data) => {
            !data.errors
              ? setDATA(data.data.updateStream.place)
              : console.log(data.errors, " ERRORS");
          })
          .catch((err) => console.log(err, " ERR"));
      }

      if (DATA.streams[0] && isEmptyTime) {
        // пустое время стрима и стрим уже существет
        const QUERY_DATA = {
          query: `
            mutation {
              updateStream (
                input:{
                  id:"${DATA.streams[0].id}"
                  schedules:{
                    create:[{ section:${enumWeekName} day:${dayData} start_time:"${startTime}" end_time:"${endTime}" }]
                  }
                }
              ) { id name url ${PLACE_QUERY} }
            }
          `,
        };

        QUERY(QUERY_DATA, localStorage.getItem("token"))
          .then((res) => res.json())
          .then((data) => {
            !data.errors
              ? setDATA(data.data.updateStream.place)
              : console.log(data.errors, " ERRORS");
          })
          .catch((err) => console.log(err, " ERR"));
      }
    }
  };

  const setStartTime = (h, m) => setStartTimePicker("" + h + ":" + m);

  const setEndTime = (h, m) => setEndTimePicker("" + h + ":" + m);

  const ready = (allDay) => {
    togglePopupDatePicker();
    isSetWorkTimeDPick && setWorkTimeOfOneDay(allDay);
    !isSetWorkTimeDPick && setStreamTimeOfOneDay(allDay);
  };

  const save = () => {
    isSetWorkTimeDPick && setWorkTimeOfOneDay();
    !isSetWorkTimeDPick && setStreamTimeOfOneDay();
  };

  const setAsDayOfClick = () => {
    setAsDayOf();
    togglePopupDatePicker();
  };

  const saveTime = () => {
    const startTime =
      startTimePicker.split(":")[0] * 3600 + startTimePicker.split(":")[1] * 60;
    const finishTime =
      endTimePicker.split(":")[0] * 3600 + endTimePicker.split(":")[1] * 60;

    if (
      ((startTime > finishTime && !finish) ||
        (start && !finish) ||
        (start && finish && startTime > finishTime)) &&
      isClick1
    ) {
      setValid(false);
    } else {
     
      (isClick1 || isClick3) && save();
      isClick2 && setAsDayOf();
      togglePopupDatePicker();
    }
  };

  return (
    <Popup
      togglePopup={togglePopupDatePicker}
      wrpaStyle={windowWidth <= 760 ? { alignItems: "flex-end" } : {}}
      style={
        windowWidth <= 760
          ? {
              height: "100%",
              padding: 0,
            }
          : {
              borderRadius: "5px",
              padding: "30px",
              padding: "50px",
            }
      }
    >
      <div>
        <CheckboxWrap
          style={{ marginBottom: "20px" }}
          onClick={() => {
            disableAllClick();
            setIsClick1((prev) => !prev);
          }}
        >
          <Checkbox clicked={isClick1}></Checkbox>
          <CheckboxText clicked={isClick1}>Работа по графику</CheckboxText>
        </CheckboxWrap>

        <AdminHeader close={togglePopupDatePicker} save={saveTime} title ={valid ? 'Готово': 'Время начала позже времени окончания'}/>

        {isClick1 && (
          <TimePickerContainer>
            <div>
              <DayText
                onClick={() => {
                  setStart(false);
                  setValid(true);
                }}
                clicked={start}
              >
                {startTime}
              </DayText>
              <DayText
                onClick={() => {
                  setStart(true);
                  setValid(true);
                }}
                clicked={!start}
              >
                {finishTime}
              </DayText>
              <TimePicker
                start
                isClick1={isClick1}
                realTimeInPicker={startRealTimeInPicker}
                setTime={setStartTime}
              />
            </div>
            <span className="space"></span>
            <div>
              <DayText
                onClick={() => {
                  setFinish(false);
                  setValid(true);
                }}
                clicked={finish}
              >
                {startTime}
              </DayText>
              <DayText
                onClick={() => {
                  setFinish(true);
                  setValid(true);
                }}
                clicked={!finish}
              >
                {finishTime}
              </DayText>
              <TimePicker
                isClick1={isClick1}
                realTimeInPicker={endRealTimeInPicker}
                setTime={setEndTime}
              />
            </div>
          </TimePickerContainer>
        )}

        <CheckboxWrap
          style={{ marginTop: "30px" }}
          onClick={() => {
            disableAllClick();
            setIsClick2((prev) => !prev);
          }}
        >
          <Checkbox clicked={isClick2}></Checkbox>
          <CheckboxText clicked={isClick2}>Сделать выходным</CheckboxText>
        </CheckboxWrap>
        <CheckboxWrap
          style={{ marginBottom: "30px" }}
          onClick={() => {
            disableAllClick();
            setIsClick3((prev) => !prev);
          }}
        >
          <Checkbox clicked={isClick3}></Checkbox>
          <CheckboxText clicked={isClick3}>Круглосуточно</CheckboxText>
        </CheckboxWrap>

        <MakeAsDayOffMobile onClick={() => setAsDayOfClick()}>
          Сделать выходным
        </MakeAsDayOffMobile>
        <MakeAsDayOffMobile
          onClick={() => ready(true)}
          style={{ paddingTop: "0px" }}
        >
          Круглосуточно
        </MakeAsDayOffMobile>

        <PopupPickerBtnsWrap>
          {valid ? (
            <Save onClick={() => saveTime()}>Сохранить</Save>
          ) : (
            <NotValid>
              Время начала позже времени окончания {isStream && "трансляции"}
            </NotValid>
          )}
          <Cancel onClick={() => togglePopupDatePicker()}>Отмена</Cancel>
        </PopupPickerBtnsWrap>
      </div>
    </Popup>
  );
};

export default DatePickerPopup;
