import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

import QUERY from "../query";
import { defaultColor } from "../constants";

const SideMenuWrap = styled(Link)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 2;
    display: ${({ shomMenu }) => (shomMenu ? "block" : "none")};
  `,
  SideMenu = styled(Link)`
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    padding: 10px;
    background-color: #eef1f6;
    overflow: hidden;
    padding-top: 50px;
    z-index: 3;
  `,
  SideMenuList = styled.ul`
    line-height: 30px;
    padding-left: 20px;
    list-style-type: none;
  `,
  ListLink = styled(Link)`
    display: block;
    width: 100%;
    transition: 0.3s ease all;
    &:hover {
      color: rgb(227, 42, 108);
    }
  `;

const SlideSideMenu = ({ isShowMenu }) => {
  const [shomMenu, setShowMenu] = useState(false);

  const pathname = window.location.pathname;

  useEffect(() => {
    if (!isShowMenu) {
      setTimeout(() => {
        setShowMenu(false);
      }, 500);
    } else {
      setShowMenu(true);
    }
  }, [isShowMenu]);

  const logout = () => {
    QUERY(
      {
        query: `mutation {logout{status message}}`,
      },
      localStorage.getItem("token")
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.errors) {
          localStorage.removeItem("userId");
          localStorage.removeItem("token");
        } else {
          console.log(data.errors, "ERRORS LOGOUT");
        }
      })
      .catch((err) => console.log(err, "err LOGOUT"));
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
  };

  const SwipeMenuSpring = useSpring({
    right: isShowMenu ? 0 : -200,
    config: { duration: 200 },
  });

  const clicked = {
    color: defaultColor,
  };

  return (
    <SideMenuWrap shomMenu={shomMenu}>
      <SideMenu as={animated.div} style={SwipeMenuSpring}>
        <SideMenuList>
          <li>
            <ListLink to="/" style={pathname === "/" ? clicked : {}}>
              Главная
            </ListLink>
          </li>
          <li>
            <ListLink to="/map" style={pathname === "/map" ? clicked : {}}>
              Карта
            </ListLink>
          </li>
          <li>
            {!!localStorage.getItem("userId") && (
              <ListLink
                to="/editCompany"
                style={pathname === "/editCompany" ? clicked : {}}
              >
                Личный кабинет
              </ListLink>
            )}
          </li>
          <li>
            {!localStorage.getItem("userId") && (
              <ListLink
                to="/login"
                style={pathname === "/login" ? clicked : {}}
              >
                Вход
              </ListLink>
            )}
            {!!localStorage.getItem("userId") && (
              <ListLink onClick={logout} to="/login">
                Выход
              </ListLink>
            )}
          </li>
          <li>
            {!localStorage.getItem("userId") && (
              <ListLink
                to="/registration"
                style={pathname === "/registration" ? clicked : {}}
              >
                Регистрация
              </ListLink>
            )}
          </li>
        </SideMenuList>
      </SideMenu>
    </SideMenuWrap>
  );
};

export default SlideSideMenu;
